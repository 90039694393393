import { Button, Col, Divider, Input, Row, Select } from 'antd'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FieldBuilder from '../../form-components/field-builder'
import AddressContext from '../../../contexts/address/context'
import FilterCard from '../../general/filter-card'
import Form from '../../form-components/from'
import styles from './style.module.scss'
import { INITIAL_PAGE } from '../../../utils/helpers/constants'
import { Controller } from 'react-hook-form'
import { IClient } from '../../../models/client/response'
import EndPoints from '../../../services/end-points'
import { debounce } from 'lodash'

interface IProps {}
const { Option } = Select

const AddressFilters: React.FC<IProps> = () => {
  const { t } = useTranslation()
  const { actions, loading, query } = useContext(AddressContext)

  const [clients, setClients] = useState<IClient[]>([])
  const [clientsLoading, setClientsLoading] = useState(false)
  const [clientSearch, setClientSearch] = useState('')
  const [page, setPage] = useState(INITIAL_PAGE)

  const getData = debounce(async () => {
    setClientsLoading(true)
    const data = await EndPoints.client.getAllClients({
      page: page,
      perPage: 5,
      keyword: clientSearch,
    })
    if (page === INITIAL_PAGE) {
      setClients(data.data.data)
    } else {
      setClients([...clients, ...data.data.data])
    }
    setClientsLoading(false)
  }, 700)

  useEffect(() => {
    getData()
  }, [page])

  useEffect(() => {
    if (page === INITIAL_PAGE) {
      getData()
    } else {
      setPage(INITIAL_PAGE)
    }
  }, [clientSearch])

  return (
    <div className={styles.formContainer}>
      <Form
        formId='addresses-filter'
        onSubmit={(data) => {
          const filter: any = {
            page: INITIAL_PAGE,
            perPage: query?.perPage,
            keyword: data?.keyword,
            staticFilters: [
              data?.clients?.length > 0
                ? {
                    name: 'createdById',
                    value: [...(data?.clients ?? [])],
                    // == != > < >= <= in
                    operation: 'in',
                  }
                : undefined,
            ].filter((f) => f),
          }
          actions.setQuery(filter)
        }}
      >
        <FilterCard
          formId='addresses-filter'
          applyLoading={loading.includes('list')}
          resetLoading={loading.includes('list')}
          onReset={() => {
            actions.setQuery({
              page: query?.page,
              perPage: query?.perPage,
            })
          }}
        >
          <Row justify={'start'} gutter={[10, 0]} className={styles.filters}>
            <Col span={8}>
              <FieldBuilder
                name='keyword'
                label={t('search')}
                input={{ type: 'text' }}
              />
            </Col>
            <Col span={8}>
              <div style={{ marginTop: '10px' }}>
                {t('clients')}
                <Controller
                  name='clients'
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <Select
                        {...field}
                        loading={clientsLoading}
                        showSearch={true}
                        placeholder={t('clients')}
                        filterOption={false}
                        mode='multiple'
                        onSearch={(value) => setClientSearch(value)}
                        autoClearSearchValue={false}
                        searchValue={clientSearch}
                        allowClear
                        dropdownRender={(menu) => {
                          return (
                            <div>
                              {menu}
                              <Divider style={{ margin: '4px 0' }} />
                              <Button
                                style={{ display: 'block', margin: 'auto' }}
                                type='link'
                                onClick={() => {
                                  setPage((page) => page + 1)
                                }}
                                loading={clientsLoading}
                              >
                                {t('show_more')}
                              </Button>
                            </div>
                          )
                        }}
                        style={{ width: '100%' }}
                      >
                        {clients.map((client) => {
                          return (
                            <Option key={client?.id} value={client?.id}>
                              {`${client?.user?.firstName} ${client?.user?.lastName}`}
                            </Option>
                          )
                        })}
                      </Select>
                    )
                  }}
                />
              </div>
            </Col>
          </Row>
        </FilterCard>
      </Form>
    </div>
  )
}

export default AddressFilters
