import { createContext } from 'react'
import { IConfigQuery } from '../../models/config/query'
import { IUpdateConfig } from '../../models/config/request'
import { IConfig } from '../../models/config/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type ConfigLoading = 'list' | 'details' | 'create' | 'update' | 'delete'

export interface IInternalState {
  loading: ConfigLoading[]

  list?: IBaseListingResponse<IConfig>
  query: IConfigQuery

  details?: IConfig
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (key: string) => void
    setDetails: (data?: IConfig) => void

    updateConfig: (id: string, request: IUpdateConfig) => void
    deleteConfig: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IConfigQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    updateConfig: DEFAULT_FUNCTION,
    deleteConfig: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const ConfigContext = createContext(externalState)

export default ConfigContext
