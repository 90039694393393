import { useTranslation } from 'react-i18next'
import { IRejectOrder } from '../../../models/order/request'
import FormModal from '../../form-components/form-modal'
import OrderRejectForm from '../order-reject-form'

interface IProps {
  loading: boolean
  visable: boolean
  hideModal: () => void
  onSubmit: (data: IRejectOrder) => void
}

const RejectOrderFormModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  return (
    <FormModal
      title={t('reject_order')}
      formId='reject-order'
      onSubmit={props.onSubmit}
      hideModal={props.hideModal}
      confirmLoading={props.loading}
      visible={props.visable}
    >
      <OrderRejectForm />
    </FormModal>
  )
}

export default RejectOrderFormModal
