export enum ClientStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum ClientGender {
  MALE = 1,
  FEMALE = 2,
  OTHER = 3,
}

export enum ClientAgeGroup {
  TEN = 1,
  TWENTY = 2,
  THIRTY = 3,
  FOURTY = 4,
  FIFTY = 5,
  SIXTY = 6,
  SEVENTY = 7,
}

// Client Age Group
export const getClientAgeGroup = (clientAgeGroup: ClientAgeGroup) => {
  switch (clientAgeGroup) {
    case ClientAgeGroup.TEN:
      return '(10-20)'
    case ClientAgeGroup.TWENTY:
      return '(20-30)'
    case ClientAgeGroup.THIRTY:
      return '(30-40)'
    case ClientAgeGroup.FOURTY:
      return '(40-50)'
    case ClientAgeGroup.FIFTY:
      return '(50-60)'
    case ClientAgeGroup.SIXTY:
      return '(60-70)'
    case ClientAgeGroup.SEVENTY:
      return '(70-80)'
    default:
      return ''
  }
}
export const getClientGender = (clientGender: ClientGender) => {
  switch (clientGender) {
    case ClientGender.MALE:
      return 'male'
    case ClientGender.FEMALE:
      return 'female'
    case ClientGender.OTHER:
      return 'other'
    default:
      return ''
  }
}
