import { IConfigQuery } from '../../models/config/query'
import { IUpdateConfig } from '../../models/config/request'
import { IConfig } from '../../models/config/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class ConfigService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllConfigs = (query: IConfigQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IConfig>>({
      path: `/dashboard/configs/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  updateConfig = (id: string, data: IUpdateConfig, params?: RequestParams) =>
    this.http.request<IConfig>({
      path: `/dashboard/configs/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteConfig = (id: number, params?: RequestParams) =>
    this.http.request<IConfig>({
      path: `/dashboard/configs/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default ConfigService
