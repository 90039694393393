import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import Space from '../../general/antd/space'
import EditBtn from '../../table-components/actions/edit-btn'
import ViewBtn from '../../table-components/actions/view-btn'
import Table from '../../table-components/table'
import { ISort, IStaticFilter } from '../../../models/base/base-query'
import { tableOnChange } from '../../../utils/helpers/table-sorts-filters'
import ClientSubscriptionContext from '../../../contexts/client-subscription/context'
import { IClientSubscription } from '../../../models/client-subscription/response'
import { Tag } from 'antd'
import {
  getSubcriptionStatusColor,
  SubscriptionStatus,
} from '../../../models/subscription/enum'

interface Props {
  showDetailsDrawer: () => void
}

const ClientSubscriptionsTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { list, actions, loading, query } = useContext(
    ClientSubscriptionContext
  )

  const columns: ColumnProps<IClientSubscription>[] = [
    {
      key: 'id',
      title: 'id',
      dataIndex: 'id',
    },
    {
      key: 'client',
      title: t('client'),
      dataIndex: 'clientId',
      sorter: {
        multiple: 1,
      },
      render: (_, record) => {
        return `${record?.client?.user?.firstName} ${record?.client?.user?.lastName}`
      },
    },

    {
      key: 'subcription',
      title: t('subscription'),
      dataIndex: 'subscriptionId',
      sorter: {
        multiple: 1,
      },
      render: (_, record) => {
        return (
          record.subscription &&
          `${record?.subscription?.mainName} ${record?.subscription?.subName}`
        )
      },
    },

    {
      key: 'isActive',
      title: t('status'),
      dataIndex: 'isActive',

      render: (isActive: SubscriptionStatus) => {
        return (
          <Tag
            color={getSubcriptionStatusColor(isActive)}
            style={{ textAlign: 'center' }}
          >
            {t(`${SubscriptionStatus[isActive]}`)}
          </Tag>
        )
      },
    },
    {
      key: 'actions',
      title: t('actions'),
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Space>
          <ViewBtn
            onClick={() => {
              actions.setDetails(record)
              props.showDetailsDrawer()
            }}
          />
        </Space>
      ),
    },
  ]

  // Filters && Sorts
  let tableFiltersProps = {}
  const [sorts, setSorts] = useState<ISort[]>([])
  const [, setStaticFilters] = useState<IStaticFilter[]>(
    query?.staticFilters ?? []
  )

  useEffect(() => {
    sorts &&
      actions.setQuery({
        ...query,
        sorts: sorts,
      })
  }, [sorts])

  return (
    <Table
      rowKey='key'
      columns={columns}
      dataSource={list?.data}
      loading={loading.includes('list')}
      showPagination
      total={list?.meta?.total}
      page={query.page}
      pageSize={query.perPage}
      scroll={{ x: 200 }}
      onPaginationChange={(page, pageSize) => {
        actions.setQuery({
          ...query,
          page,
          perPage: pageSize,
        })
      }}
      onChange={(_, tableFilters, tabelSorters) => {
        tableOnChange(
          tableFilters,
          tabelSorters,
          tableFiltersProps,
          setSorts,
          setStaticFilters
        )
      }}
    />
  )
}

export default ClientSubscriptionsTable
