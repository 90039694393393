export enum StaffStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum CommissionType {
  PERCENTAGE = 1,
  COMMISSION = 2,
}
// Status Orders Colors
export const getStaffStatusColor = (status: StaffStatus) => {
  switch (status) {
    case StaffStatus.ACTIVE:
      return 'green'
    case StaffStatus.INACTIVE:
      return 'red'
    default:
      return 'gray'
  }
}
