import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import FormDrawer from '../../form-components/form-drawer/index'
import CityForm from '../city-form'
import { ICity } from '../../../models/city/response'
import CountrySelectOptionsContextProvider from '../../../contexts/select-options/country-select-options/provider'
import ServiceSelectOptionsContextProvider from '../../../contexts/select-options/service-select-options/provider'

interface Props<DataType> {
  formId: string
  loading: boolean
  visable: boolean
  hideDrawer: () => void
  defaultValues?: DataType
  details?: ICity
  onSubmit: (data: DataType) => void
}

const CityFormDrawer = function <DataType>(
  props: PropsWithChildren<Props<DataType>>
) {
  const { t } = useTranslation()

  return (
    <ServiceSelectOptionsContextProvider>
      <CountrySelectOptionsContextProvider>
        <FormDrawer<DataType>
          defaultValues={props.defaultValues}
          title={props.details ? t('update_city') : t('create_city')}
          onSubmit={props.onSubmit}
          hideDrawer={props.hideDrawer}
          visible={props.visable}
          formId={props.formId}
          confirmLoading={props.loading} // Todo: Loading
          children={<CityForm city={props?.details} />}
        />
      </CountrySelectOptionsContextProvider>
    </ServiceSelectOptionsContextProvider>
  )
}

export default CityFormDrawer
