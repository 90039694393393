export enum PromoCodeStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

export const getPromoCodeStatusColor = (status: PromoCodeStatus) => {
  switch (status) {
    case PromoCodeStatus.ACTIVE:
      return 'green'
    case PromoCodeStatus.INACTIVE:
      return 'red'
    default:
      return 'gray'
  }
}
