import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import FormDrawer from '../../form-components/form-drawer/index'
import ServiceForm from '../service-form'
import { IService } from '../../../models/service/response'
import TagSelectOptionsContextProvider from '../../../contexts/select-options/tag-select-options/provider'
import CategorySelectOptionsContextProvider from '../../../contexts/select-options/category-select-options/provider'
import CitySelectOptionsContextProvider from '../../../contexts/select-options/city-select-options/provider'
import PermanentLocationSelectOptionsContextProvider from '../../../contexts/select-options/permanent-location-select-options/provider'

interface Props<DataType> {
  formId: string
  loading: boolean
  visable: boolean
  hideDrawer: () => void
  defaultValues?: DataType
  details?: IService
  onSubmit: (data: DataType) => void
}

const ServiceFormDrawer = function <DataType>(
  props: PropsWithChildren<Props<DataType>>
) {
  const { t } = useTranslation()

  return (
    <PermanentLocationSelectOptionsContextProvider>
      <CitySelectOptionsContextProvider>
        <TagSelectOptionsContextProvider>
          <CategorySelectOptionsContextProvider>
            <FormDrawer<DataType>
              defaultValues={props.defaultValues}
              title={props.details ? t('update_service') : t('create_service')}
              onSubmit={props.onSubmit}
              hideDrawer={props.hideDrawer}
              visible={props.visable}
              formId={props.formId}
              confirmLoading={props.loading}
              children={<ServiceForm service={props?.details} />}
            />
          </CategorySelectOptionsContextProvider>
        </TagSelectOptionsContextProvider>
      </CitySelectOptionsContextProvider>
    </PermanentLocationSelectOptionsContextProvider>
  )
}

export default ServiceFormDrawer
