import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import { Descriptions as AntdDescriptions, Input, List, Tabs, Tag } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getServiceStatusColor,
  ServiceStatus,
} from '../../../models/service/enum'
import { IService } from '../../../models/service/response'
import Descriptions from '../../general/antd/descriptions'
import Image from '../../general/antd/image/index'
const { TabPane } = Tabs

interface Props {
  service?: IService
}

const ServiceDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [cities, setCities] = useState(props.service?.cities)
  const [permanentLocations, setPermanentLocations] = useState(
    props.service?.permanentLocations
  )

  useEffect(() => {
    props.service?.cities && setCities(props.service?.cities)
    props.service?.permanentLocations &&
      setPermanentLocations(props.service?.permanentLocations)
  }, [props.service?.cities])

  return (
    <>
      <Descriptions size='middle' column={1} bordered>
        <AntdDescriptions.Item label={t('id')}>
          {props.service?.id}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('name')}>
          {props.service?.name}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('additional_information')}>
          {props.service?.additionalInformation}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('status')}>
          {props?.service?.status && (
            <Tag color={getServiceStatusColor(props?.service?.status)}>
              {t(`${ServiceStatus[props?.service?.status]}`)}
            </Tag>
          )}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('category')}>
          {props.service?.category?.name}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('tag')}>
          {props.service?.tag?.name}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('active_image')}>
          <Image src={props.service?.activeImage?.url} />
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('inactive_image')}>
          <Image src={props.service?.inactiveImage?.url} />
        </AntdDescriptions.Item>
      </Descriptions>

      <Tabs defaultActiveKey='1' animated type='line'>
        <TabPane tab={t('cities')} key='1'>
          <List
            header={
              <Input
                placeholder={t('search_hint')}
                suffix={<SearchOutlined />}
                allowClear
                onChange={(event) => {
                  let searchKey = event.target.value

                  let filteredCities = props.service?.cities?.filter((city) =>
                    city?.name?.includes(searchKey)
                  )

                  setCities(filteredCities)

                  if (searchKey == '') {
                    setCities(props.service?.cities)
                  }
                }}
              />
            }
            bordered
            dataSource={cities ?? []}
            renderItem={(item) => (
              <List.Item>{`${item?.name} (${item?.price} ${t(
                'aed'
              )})`}</List.Item>
            )}
          />
        </TabPane>
        <TabPane tab={t('permanent_locations')} key='2'>
          <List
            header={
              <Input
                placeholder={t('search_hint')}
                suffix={<SearchOutlined />}
                allowClear
                onChange={(event) => {
                  let searchKey = event.target.value

                  let filteredPermanentLocations =
                    props.service?.permanentLocations?.filter(
                      (permanentLocation) =>
                        permanentLocation?.name?.includes(searchKey)
                    )

                  setPermanentLocations(filteredPermanentLocations)

                  if (searchKey == '') {
                    setPermanentLocations(props.service?.permanentLocations)
                  }
                }}
              />
            }
            bordered
            dataSource={permanentLocations ?? []}
            renderItem={(item) => (
              <List.Item>{`${item?.name} (${item?.price} ${t(
                'aed'
              )})`}</List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </>
  )
}

export default ServiceDetails
