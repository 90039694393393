import { httpclient } from './http-client'
import AppService from './app'
import AuthService from './auth'
import UserService from './user'
import CountryService from './country'
import CityService from './city'
import AddressService from './address'
import BannerService from './banner'
import TagService from './tag'
import BrandService from './brand/index'
import CategoryService from './category'
import ConfigService from './config/index'
import CarColorService from './car-color'
import ClientService from './client'
import FAQService from './faq/index'
import ProductService from './product'
import PromoCodeService from './promo-code'
import PermanentLocationService from './permanent-location'
import ServiceService from './service'
import StaffTypeService from './staff-type'
import CarService from './car'
import StaffService from './staff/index'
import OrderService from './order'
import AttendanceService from './attendance'
import RequestService from './request'
import SubscriptionService from './subscription'
import TransactionAdminToStaffService from './transaction-admin-staff'
import TransactionStaffToAdminService from './transaction-staff-to-admin'
import NotificationService from './notifications'
import ClientSubscriptionService from './client-subscription/intdex'
import PlateCityService from './plate-cities'

export default class EndPoints {
  public static app = new AppService(httpclient)
  public static auth = new AuthService(httpclient)
  public static user = new UserService(httpclient)
  public static country = new CountryService(httpclient)
  public static city = new CityService(httpclient)
  public static address = new AddressService(httpclient)
  public static banner = new BannerService(httpclient)
  public static tag = new TagService(httpclient)
  public static brand = new BrandService(httpclient)
  public static category = new CategoryService(httpclient)
  public static config = new ConfigService(httpclient)
  public static carColor = new CarColorService(httpclient)
  public static client = new ClientService(httpclient)
  public static faq = new FAQService(httpclient)
  public static product = new ProductService(httpclient)
  public static promoCode = new PromoCodeService(httpclient)
  public static staffType = new StaffTypeService(httpclient)
  public static permanentLocation = new PermanentLocationService(httpclient)
  public static service = new ServiceService(httpclient)
  public static car = new CarService(httpclient)
  public static staff = new StaffService(httpclient)
  public static order = new OrderService(httpclient)
  public static attendance = new AttendanceService(httpclient)
  public static request = new RequestService(httpclient)
  public static notification = new NotificationService(httpclient)
  public static subscription = new SubscriptionService(httpclient)
  public static clientSubscription = new ClientSubscriptionService(httpclient)
  public static plateCity = new PlateCityService(httpclient)
  public static transactionAdminToStaff = new TransactionAdminToStaffService(
    httpclient
  )

  public static transactionStaffToAdmin = new TransactionStaffToAdminService(
    httpclient
  )
}
