import { Col, Row } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FieldBuilder from '../../form-components/field-builder'
import CityContext from '../../../contexts/city/context'
import FilterCard from '../../general/filter-card'
import Form from '../../form-components/from'
import { INITIAL_PAGE } from '../../../utils/helpers/constants'

import styles from './style.module.scss'
import CountrySelectOptionsContext from '../../../contexts/select-options/country-select-options/context'

interface IProps {}

const CityFilters: React.FC<IProps> = () => {
  const { t } = useTranslation()
  const { actions, loading, query } = useContext(CityContext)
  const {
    list,
    actions: countryActions,
    loading: countryLoading,
  } = useContext(CountrySelectOptionsContext)

  useEffect(() => {
    countryActions.getData()
  }, [])
  return (
    <div className={styles.formContainer}>
      <Form
        formId='cities-filter'
        onSubmit={(data) => {
          const filter: any = {
            page: INITIAL_PAGE,
            perPage: query?.perPage,
            keyword: data?.keyword,
            staticFilters: [
              data?.countries?.length > 0
                ? {
                    name: 'countryId',
                    value: [...(data?.countries ?? [])],
                    // == != > < >= <= in
                    operation: 'in',
                  }
                : undefined,
            ].filter((f) => f),
          }
          actions.setQuery(filter)
        }}
      >
        <FilterCard
          formId='cities-filter'
          applyLoading={loading.includes('list')}
          resetLoading={loading.includes('list')}
          onReset={() => {
            actions.setQuery({
              page: query?.page,
              perPage: query?.perPage,
            })
          }}
        >
          <Row justify={'start'} gutter={[10, 0]} className={styles.filters}>
            <Col span={8}>
              <FieldBuilder
                name='keyword'
                label={t('search')}
                input={{ type: 'text' }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name='countries'
                label={t('countries')}
                input={{
                  type: 'multiSelect',
                  loading: countryLoading.includes('list'),
                  allowSearch: true,

                  options: list?.data.map((country) => {
                    return { value: country.id, label: country.name }
                  }),
                }}
              />
            </Col>
          </Row>
        </FilterCard>
      </Form>
    </div>
  )
}

export default CityFilters
