import { ICarColorQuery } from '../../models/car-color/query'
import {
  ICreateCarColor,
  IUpdateCarColor,
} from '../../models/car-color/request'
import { ICarColor } from '../../models/car-color/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class CarColorService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllCarColors = (query: ICarColorQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICarColor>>({
      path: `/dashboard/car-colors/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createCarColor = (data: ICreateCarColor, params?: RequestParams) =>
    this.http.request<ICarColor>({
      path: '/dashboard/car-colors',
      method: 'POST',
      body: data,
      ...params,
    })

  updateCarColor = (
    id: number,
    data: IUpdateCarColor,
    params?: RequestParams
  ) =>
    this.http.request<ICarColor>({
      path: `/dashboard/car-colors/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteCarColor = (id: number, params?: RequestParams) =>
    this.http.request<ICarColor>({
      path: `/dashboard/car-colors/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default CarColorService
