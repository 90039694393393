import {
  CheckboxOptionType,
  Col,
  DatePicker,
  Form,
  Input as AntdInput,
  Radio,
  Row,
  Select,
  TimePicker,
} from 'antd'
import { useTranslation } from 'react-i18next'
import Controller from '../../form-components/controller'
import Input from '../input'
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons'
import Switch from '../../general/antd/switch'
import styles from './styles.module.scss'
import { RegisterOptions } from 'react-hook-form'
import { a2e } from '../../../utils/helpers/functions'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { SketchPicker } from 'react-color'
import { HexColorPicker } from 'react-colorful'

const { RangePicker } = DatePicker
const { TextArea, Search } = AntdInput

interface Rules {
  required?: boolean
  minLength?: number
  maxLength?: number
  min?: number
  max?: number
  pattern?: { value: RegExp; message?: string }
}

type Field =
  | { type: 'email' }
  | { type: 'username' }
  | { type: 'gsm' }
  | { type: 'pin' }
  | { type: 'text' }
  | { type: 'text-area'; rows?: number }
  | { type: 'number' }
  | { type: 'search' }
  | { type: 'password' }
  | { type: 'switch' }
  | { type: 'time-picker'; format?: string }
  | { type: 'time-range-picker'; format?: string }
  | { type: 'date-picker'; format?: string }
  | { type: 'range-picker'; format?: string }
  | { type: 'radio'; options?: CheckboxOptionType[] }
  | {
      type: 'select'
      options?: { label: string; value: any }[]
      loading?: boolean
      allowSearch?: boolean
    }
  | {
      type: 'multiSelect'
      options?: { label: string; value: any }[]
      loading?: boolean
      allowSearch?: boolean
    }
  | { type: 'color-picker' }

interface Props {
  name: string
  label?: string
  labelCol?: number
  input: Field
  rules?: Rules
  disabled?: boolean
}

const FieldBuilder: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const rules = (
    rules?: Rules
  ): Omit<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  > => {
    return {
      required: rules?.required
        ? {
            value: rules.required,
            message: t('requiredField', { input: t(props.name) }),
          }
        : undefined,
      minLength: rules?.minLength
        ? {
            value: rules.minLength,
            message: t('minLength', {
              input: t(props.name),
              value: rules.minLength,
            }),
          }
        : undefined,
      maxLength: rules?.maxLength
        ? {
            value: rules.maxLength,
            message: t('maxLength', {
              input: t(props.name),
              value: rules.maxLength,
            }),
          }
        : undefined,
      max: rules?.max
        ? {
            value: rules.max,
            message: t('max', {
              input: t(props.name),
              value: rules.max,
            }),
          }
        : undefined,
      min: rules?.min
        ? {
            value: rules.min,
            message: t('min', {
              input: t(props.name),
              value: rules.min,
            }),
          }
        : undefined,
      pattern: rules?.pattern
        ? {
            value: rules.pattern.value,
            message:
              rules.pattern.message ??
              t('invalidPattern', { input: t(props.name) }),
          }
        : undefined,
    }
  }

  return (
    <Form.Item
      label={props.label}
      labelCol={props.labelCol ? { span: props.labelCol } : undefined}
      required={props.rules?.required}
    >
      <Controller
        name={props.name}
        rules={rules(props.rules)}
        render={({ field: { ref, ...field } }) => {
          const input = props.input
          switch (input.type) {
            case 'text-area':
              return (
                <TextArea
                  {...field}
                  rows={input?.rows ?? 3}
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  disabled={props?.disabled}
                />
              )
            case 'email':
              return (
                <Input
                  {...field}
                  prefix={<MailOutlined />}
                  placeholder={t('email')}
                  disabled={props?.disabled}
                />
              )
            case 'username':
              return (
                <Input
                  {...field}
                  prefix={<UserOutlined />}
                  placeholder={t('username')}
                  disabled={props?.disabled}
                />
              )
            case 'gsm':
              return (
                <Input
                  {...field}
                  onChange={(e) => {
                    field.onChange(a2e(e.currentTarget.value))
                  }}
                  prefix='+963'
                  placeholder={t('gsm')}
                  style={{ direction: 'ltr' }}
                  disabled={props?.disabled}
                />
              )
            case 'pin':
              return (
                <AntdInput.Password
                  {...field}
                  onChange={(e) => {
                    field.onChange(a2e(e.currentTarget.value))
                  }}
                  size='middle'
                  className='shadow'
                  prefix={<LockOutlined />}
                  placeholder={props.label}
                  disabled={props?.disabled}
                />
              )
            case 'password':
              return (
                <AntdInput.Password
                  {...field}
                  size='middle'
                  className='shadow'
                  prefix={<LockOutlined />}
                  placeholder={props.label}
                />
              )
            case 'search':
              return (
                <Search
                  {...field}
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  disabled={props?.disabled}
                />
              )
            case 'number':
              return (
                <Input
                  {...field}
                  type='number'
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  disabled={props?.disabled}
                />
              )
            case 'switch':
              return (
                <Switch
                  {...field}
                  checked={field.value}
                  disabled={props?.disabled}
                />
              )
            case 'time-picker':
              return (
                <TimePicker
                  {...field}
                  style={{ width: '100%' }}
                  allowClear={!props.rules?.required}
                  value={
                    field.value ? moment(field.value, input.format) : undefined
                  }
                  onChange={(_, dateString) => {
                    field.onChange(dateString)
                  }}
                  format={input.format}
                  disabled={props?.disabled}
                />
              )
            case 'time-range-picker':
              const timePickerValue: [any, any] = [null, null]

              if (field.value) {
                timePickerValue[0] = !isEmpty(field.value[0])
                  ? moment(field.value[0], input.format)
                  : null

                timePickerValue[1] = !isEmpty(field.value[1])
                  ? moment(field.value[1], input.format)
                  : null
              }
              return (
                <TimePicker.RangePicker
                  {...field}
                  style={{ width: '100%' }}
                  allowClear={!props.rules?.required}
                  value={timePickerValue}
                  onChange={(_, dateString) => {
                    field.onChange(dateString)
                  }}
                  format={input.format}
                  disabled={props?.disabled}
                />
              )
            case 'color-picker':
              return (
                <HexColorPicker
                  {...field}
                  color={field.value ? field.value : '8A4545'}
                  onChange={(color) => {
                    field.onChange(color)
                  }}
                />
              )
            case 'date-picker':
              return (
                <DatePicker
                  {...field}
                  className='shadow'
                  style={{ width: '100%' }}
                  allowClear={!props.rules?.required}
                  value={field.value ? moment(field.value) : undefined}
                  onChange={(date, dateString) => {
                    field.onChange(dateString)
                  }}
                  format={input.format}
                  disabled={props?.disabled}
                />
              )
            case 'range-picker':
              const rangePickerValue: [any, any] = [null, null]

              if (field.value) {
                rangePickerValue[0] = !isEmpty(field.value[0])
                  ? moment(field.value[0])
                  : null

                rangePickerValue[1] = !isEmpty(field.value[1])
                  ? moment(field.value[1])
                  : null
              }

              return (
                <RangePicker
                  {...field}
                  className='shadow'
                  style={{ width: '100%' }}
                  allowEmpty={[true, true]}
                  allowClear={!props.rules?.required}
                  value={rangePickerValue}
                  onChange={(dates, datesString) => {
                    field.onChange([datesString[0], datesString[1]])
                  }}
                  format={input.format}
                  disabled={props?.disabled}
                />
              )
            case 'radio':
              return (
                <Radio.Group {...field} optionType='default'>
                  <Row gutter={[20, 20]} justify='center'>
                    {input.options?.map((option, key) => (
                      <Col key={key}>
                        <Radio
                          key={key}
                          className={styles['radio-option']}
                          {...option}
                        >
                          {option.label}
                        </Radio>
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              )

            case 'select':
              return (
                <Select
                  {...field}
                  className='shadow'
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  options={input.options}
                  loading={input?.loading}
                  showSearch={input?.allowSearch}
                  optionLabelProp='label'
                  optionFilterProp='label'
                  disabled={props?.disabled}
                />
              )
            case 'multiSelect':
              return (
                <Select
                  {...field}
                  className='shadow'
                  allowClear={!props.rules?.required}
                  mode='multiple'
                  placeholder={props.label}
                  options={input.options}
                  loading={input?.loading}
                  showSearch={input?.allowSearch}
                  optionLabelProp='label'
                  optionFilterProp='label'
                  disabled={props?.disabled}
                />
              )
            default:
              return (
                <Input
                  {...field}
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  disabled={props?.disabled}
                />
              )
          }
        }}
      />
    </Form.Item>
  )
}

export default FieldBuilder
