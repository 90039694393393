import { Avatar, Badge, Button, Col, Divider, Layout, Menu, Row } from 'antd'
import styles from './styles.module.scss'
import { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../contexts/auth/context'
import AuthUserCard from '../../auth/auth-user-card'
import classNames from 'classnames'
import Image from '../antd/image'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import AppContext from '../../../contexts/app/context'
import cn from 'classnames'
import { FaBell } from 'react-icons/fa'
import { FiBell } from 'react-icons/fi'
import EndPoints from '../../../services/end-points'
import { IUser } from '../../../models/user/response'
import { INotifications } from '../../../models/notifications/response'
import { useHistory } from 'react-router-dom'
import { httpclient } from '../../../services/http-client'
import { INITIAL_PAGE } from '../../../utils/helpers/constants'
import NotificationsPage from '../../../pages/notifications'

const { Header: AntdHeader } = Layout
const { SubMenu } = Menu

interface Props {}

const Header: React.FC<Props> = (props) => {
  const { direction } = useContext(AppContext)
  const { isAuthenticated } = useContext(AuthContext)
  let history = useHistory()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { authUser } = useContext(AuthContext)
  const [userData, setUserData] = useState<IUser>()
  const [notificationsData, setNotificationsData] = useState<INotifications[]>(
    []
  )
  const [notficationsPage, setNotificationsPage] = useState(INITIAL_PAGE)
  const [buttonLoading, setButtonLoading] = useState(false)
  const getUserData = async () => {
    setButtonLoading(true)

    const data = await EndPoints.auth.me()

    setUserData(data.data)
    const notificationsDataEndpoint =
      await EndPoints.notification.getUserNotifications({
        perPage: 5,
        page: notficationsPage,
      })

    setNotificationsData([
      ...notificationsData,
      ...notificationsDataEndpoint.data.data,
    ])
    setButtonLoading(false)
  }

  const getUserDataLanguage = async () => {
    setButtonLoading(true)
    setNotificationsData([])

    const data = await EndPoints.auth.me()

    setUserData(data.data)
    const notificationsDataEndpoint =
      await EndPoints.notification.getUserNotifications({
        perPage: 5,
        page: notficationsPage,
      })

    setNotificationsData(notificationsDataEndpoint.data.data)
    setButtonLoading(false)
  }

  useEffect(() => {
    if (isAuthenticated === true) {
      getUserData()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated === true) {
      getUserDataLanguage()
    }
  }, [language])

  return (
    <>
      <AntdHeader className={classNames(styles.header, 'shadow')}>
        <Image
          wrapperClassName={classNames(styles.logo)}
          src={`/assets/logos/text-logo.png`}
          style={{ width: 'auto' }}
        />
        <Row justify='end'>
          <Col span={24} style={{ marginInlineEnd: '-3.5rem' }}>
            <Menu
              direction={direction}
              selectable={false}
              multiple
              theme='dark'
              mode='horizontal'
              className={styles.menu}
            >
              <SubMenu
                icon={
                  <span className={styles.subMenuIcon}>
                    <Badge
                      size='small'
                      style={{
                        backgroundColor: '#ea5455',
                      }}
                      count={userData?.unreadNotifications}
                    >
                      <FiBell className={styles.billIcon} />
                    </Badge>
                  </span>
                }
                className={styles.billSubMenu}
              >
                <div style={{ height: '300px' }} dir={direction}>
                  {notificationsData?.map((notification) => {
                    return (
                      <Menu.Item className={styles.notifications}>
                        <div
                          className={styles.notificationHover}
                          onClick={() => {
                            if (notification.status === 2) {
                              EndPoints.notification.readNotification(
                                notification.id
                              )
                              notification.status = 3
                              getUserData()
                            }
                          }}
                        >
                          <Badge dot={notification.status === 2 && true}>
                            <Avatar
                              style={{
                                backgroundColor: '#CCCCCC',
                              }}
                              icon={
                                <FaBell style={{ margin: 0, padding: 0 }} />
                              }
                              size='large'
                              shape='circle'
                            />
                          </Badge>

                          <span className={styles.headerNotification}>
                            {notification.title}
                          </span>
                          <span style={{ flexBasis: '100%' }}></span>
                          <span
                            style={{
                              marginTop: '-45px',
                              marginInlineStart: '50px',
                              color: 'gray',
                            }}
                          >
                            {notification.body}
                          </span>
                        </div>
                      </Menu.Item>
                    )
                  })}
                  <Divider style={{ margin: '4px 0' }} />
                  <Button
                    style={{
                      display: 'block',
                      margin: 'auto',
                      paddingBottom: '5px',
                    }}
                    type='link'
                    loading={buttonLoading}
                    onClick={() => {
                      setNotificationsPage((page) => page + 1)
                      getUserData()
                    }}
                    // loading={clientsLoading}
                  >
                    {t('show_more')}
                  </Button>
                </div>
              </SubMenu>
              <SubMenu
                key='langauge'
                className={styles.languageSubMenu}
                icon={
                  <Image
                    src={`${process.env.PUBLIC_URL}/assets/images/flags/${language}-flag.png`}
                    className={styles.subMenuIcon}
                    style={{ width: 'auto' }}
                  />
                }
                title={
                  <span
                    className={cn(styles.textBlack, styles.languageContainer)}
                  >
                    {t(language)}
                  </span>
                }
              >
                <Menu.Item
                  key='langauge:ar'
                  className={styles.languageHover}
                  onClick={() => i18next.changeLanguage('ar')}
                >
                  <span className={styles.languageChoiceContainer}>
                    <Image
                      src={`${process.env.PUBLIC_URL}/assets/images/flags/ar-flag.png`}
                      className={styles.flagIcon}
                      style={{ width: 'auto' }}
                    />
                    <span>{t('ar')}</span>
                  </span>
                </Menu.Item>
                <Menu.Item
                  key='langauge:en'
                  className={styles.languageHover}
                  onClick={() => i18next.changeLanguage('en')}
                >
                  <span className={styles.languageChoiceContainer}>
                    <Image
                      src={`${process.env.PUBLIC_URL}/assets/images/flags/en-flag.png`}
                      className={styles.flagIcon}
                      style={{ width: 'auto' }}
                    />
                    <span>{t('en')}</span>
                  </span>
                </Menu.Item>
                <Menu.Item
                  key='langauge:fr'
                  className={styles.languageHover}
                  onClick={() => i18next.changeLanguage('fr')}
                >
                  <span className={styles.languageChoiceContainer}>
                    <Image
                      src={`${process.env.PUBLIC_URL}/assets/images/flags/fr-flag.png`}
                      className={styles.flagIcon}
                      style={{ width: 'auto' }}
                    />
                    <span>{t('fr')}</span>
                  </span>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key='authUser'
                title={
                  <>
                    <span className={cn(styles.textBlack, styles.userName)}>
                      {authUser?.username}
                    </span>
                    <Avatar
                      size='large'
                      style={{ marginBottom: '5px' }}
                      src={`${process.env.PUBLIC_URL}/assets/images/placeholders/user3.png`}
                    />
                  </>
                }
              >
                <Menu.Item
                  key='authUser:settings'
                  className={styles['auth-user-settings']}
                >
                  <AuthUserCard />
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Col>
        </Row>
      </AntdHeader>
    </>
  )
}

export default Header
