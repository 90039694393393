import React, {useContext} from 'react'
import {Col, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import FieldBuilder from '../../form-components/field-builder'
import PromoCodeContext from '../../../contexts/promo-code/context'
import FilterCard from '../../general/filter-card'
import Form from '../../form-components/from'
import {INITIAL_PAGE} from '../../../utils/helpers/constants'

import styles from './style.module.scss'
import {PromoCodeStatus} from "../../../models/promo-code/enum";

interface IProps {
}

const PromoCodeFilters: React.FC<IProps> = () => {
    const {t} = useTranslation()
    const {actions, loading, query} = useContext(PromoCodeContext)

    return (
        <div className={styles.formContainer}>
            <Form
                formId='promo-codes-filter'
                onSubmit={(data) => {
                    const filter: any = {
                        page: INITIAL_PAGE,
                        perPage: query?.perPage,
                        keyword: data?.keyword,
                        staticFilters: [
                            data?.status?.length > 0
                                ? {
                                    name: 'status',
                                    value: [...(data?.status ?? [])],
                                    // == != > < >= <= in
                                    operation: 'in',
                                }
                                : undefined,].filter(f => f)
                    }
                    actions.setQuery(filter)
                }}
            >
                <FilterCard
                    formId='promo-codes-filter'
                    applyLoading={loading.includes('list')}
                    resetLoading={loading.includes('list')}
                    onReset={() => {
                        actions.setQuery({
                            page: query?.page,
                            perPage: query?.perPage,

                        })
                    }}
                >
                    <Row justify={'start'} gutter={[10, 0]} className={styles.filters}>
                        <Col span={8}>
                            <FieldBuilder
                                name='keyword'
                                label={t('search')}
                                input={{type: 'text'}}
                            />
                        </Col>
                        <Col span={8}>
                            <FieldBuilder
                                name='status'
                                label={t('status')}
                                input={{
                                    type: 'multiSelect',
                                    options: [
                                        {
                                            value: PromoCodeStatus.ACTIVE,
                                            label: t(
                                                `${
                                                    PromoCodeStatus[
                                                        PromoCodeStatus.ACTIVE
                                                        ]
                                                }`
                                            ),
                                        },
                                        {
                                            value: PromoCodeStatus.INACTIVE,
                                            label: t(
                                                `${
                                                    PromoCodeStatus[PromoCodeStatus.INACTIVE]
                                                }`
                                            ),
                                        },

                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                </FilterCard>
            </Form>
        </div>
    )
}

export default PromoCodeFilters
