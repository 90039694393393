import { createContext } from 'react'
import { IPromoCodeQuery } from '../../models/promo-code/query'
import {
  ICreatePromoCode,
  IUpdatePromoCode,
} from '../../models/promo-code/request'
import { IPromoCode } from '../../models/promo-code/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type PromoCodeLoading =
  | 'list'
  | 'details'
  | 'create'
  | 'update'
  | 'delete'

export interface IInternalState {
  loading: PromoCodeLoading[]

  list?: IBaseListingResponse<IPromoCode>
  query: IPromoCodeQuery

  details?: IPromoCode
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IPromoCode) => void

    createPromoCode: (request: ICreatePromoCode) => void
    updatePromoCode: (id: number, request: IUpdatePromoCode) => void
    deletePromoCode: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IPromoCodeQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createPromoCode: DEFAULT_FUNCTION,
    updatePromoCode: DEFAULT_FUNCTION,
    deletePromoCode: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const PromoCodeContext = createContext(externalState)

export default PromoCodeContext
