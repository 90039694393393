import { IFAQQuery } from '../../models/faq/query'
import { ICreateFAQ, IUpdateFAQ } from '../../models/faq/request'
import { IFAQ } from '../../models/faq/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class FAQService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllFAQs = (query: IFAQQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IFAQ>>({
      path: `/dashboard/faqs/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createFAQ = (data: ICreateFAQ, params?: RequestParams) =>
    this.http.request<IFAQ>({
      path: '/dashboard/faqs',
      method: 'POST',
      body: data,
      ...params,
    })

  updateFAQ = (id: number, data: IUpdateFAQ, params?: RequestParams) =>
    this.http.request<IFAQ>({
      path: `/dashboard/faqs/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteFAQ = (id: number, params?: RequestParams) =>
    this.http.request<IFAQ>({
      path: `/dashboard/faqs/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default FAQService
