import React from 'react'
import { Badge, Card } from 'antd'
import { useTranslation } from 'react-i18next'

const TranslationsCard: React.FC = ({ children }) => {
  const { t } = useTranslation()

  return (
    <Badge.Ribbon
      text={<span>{t('all_translation_required')}</span>}
      color='red'
    >
      <Card>{children}</Card>
    </Badge.Ribbon>
  )
}

export default TranslationsCard
