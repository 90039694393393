import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import FormDrawer from '../../form-components/form-drawer/index'
import StaffForm from '../staff-form'
import { IStaff } from '../../../models/staff/response'
import StaffTypeSelectOptionsContextProvider from '../../../contexts/select-options/staff-type-select-options/provider'
import PermanentLocationSelectOptionsContextProvider from '../../../contexts/select-options/permanent-location-select-options/provider'

interface Props<DataType> {
  formId: string
  loading: boolean
  visable: boolean
  hideDrawer: () => void
  defaultValues?: DataType
  details?: IStaff
  onSubmit: (data: DataType) => void
}

const StaffFormDrawer = function <DataType>(
  props: PropsWithChildren<Props<DataType>>
) {
  const { t } = useTranslation()

  return (
    <StaffTypeSelectOptionsContextProvider>
      <PermanentLocationSelectOptionsContextProvider>
        <FormDrawer<DataType>
          defaultValues={props.defaultValues}
          title={props.details ? t('update_staff') : t('create_staff')}
          onSubmit={props.onSubmit}
          hideDrawer={props.hideDrawer}
          visible={props.visable}
          formId={props.formId}
          confirmLoading={props.loading}
          children={<StaffForm staff={props?.details} />}
        />
      </PermanentLocationSelectOptionsContextProvider>
    </StaffTypeSelectOptionsContextProvider>
  )
}

export default StaffFormDrawer
