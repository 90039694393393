import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import eventManager, { EVENT_ERORR, EVENT_SUCCESS } from '../../utils/events'
import FAQContextProvider from '../../contexts/faq/provider'
import PageHeader from '../../components/general/antd/page-header'
import FAQContext from '../../contexts/faq/context'
import CreateBtn from '../../components/table-components/actions/create-btn'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import FAQTable from '../../components/faq/faq-table'
import FAQDetails from '../../components/faq/faq-details'
import FAQFormDrawer from '../../components/faq/faq-form-drawer'
import InfoDrawer from '../../components/general/info-drawer'
import { getTranslations } from '../../utils/helpers/translations'
import FAQFilters from '../../components/faq/faq-filters'
import { ICreateFAQ } from '../../models/faq/request'
import { checkTranslations } from '../../utils/helpers/constants'

interface Props {
  route: IRoute
}

const FAQs: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(FAQContext)

  const onSubmit = async (data: any) => {
    if (
      checkTranslations(data.question) === true &&
      checkTranslations(data.answer) === true
    ) {
      details
        ? await actions.updateFAQ(details.id, data)
        : await actions.createFAQ(data)
      eventManager.emit(EVENT_SUCCESS)
    } else {
      eventManager.emit(EVENT_ERORR, t('translations_required_message'))
      throw 'tranlations required'
    }
  }

  const [formDrawerVisable, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <CreateBtn
              onClick={() => {
                actions.setDetails(undefined)
                setFormDrawerVisable(true)
              }}
            />
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />

      <FAQFilters />

      <FAQTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <FAQFormDrawer
        formId={'faq-form'}
        visable={formDrawerVisable}
        defaultValues={
          details && {
            id: details?.id,
            question: getTranslations(details?.translations?.question),
            answer: getTranslations(details?.translations?.answer),
          }
        }
        details={details}
        loading={loading.includes('create') || loading.includes('update')}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      />

      <InfoDrawer
        title={t('faq_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <FAQDetails faq={details} />
      </InfoDrawer>
    </>
  )
}

const FAQsPage: React.FC<Props> = (props) => {
  return (
    <FAQContextProvider>
      <FAQs {...props} />
    </FAQContextProvider>
  )
}
export default FAQsPage
