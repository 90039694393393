import { IInternalState, AppLoading, ScreenType } from './context'
import { DirectionType } from 'antd/lib/config-provider'
import { toggleLoading } from '../../utils/helpers/functions'

type Action =
  | { type: 'SET_DIRECTION'; payload: { direction: DirectionType } }
  | { type: 'SET_LOCALE'; payload: { locale: any } }
  | {
      type: 'SET_SCREEN_TYPE'
      payload: { screenType: ScreenType }
    }
  | { type: 'LOADING'; payload: { loading: AppLoading[] | AppLoading } }

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case 'SET_DIRECTION':
      return { ...state, direction: action.payload.direction }
    case 'SET_LOCALE':
      return { ...state, locale: action.payload.locale }
    case 'SET_SCREEN_TYPE':
      return { ...state, screenType: action.payload.screenType }
    case 'LOADING':
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      }
    default:
      return state
  }
}

export default reducer
