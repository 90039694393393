import React, { useEffect, useState } from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import TranslationsCard from '../../form-components/translations-card'
import { Divider, Form, Tabs } from 'antd'
import { languages } from '../../../utils/helpers/constants'
import { Controller } from 'react-hook-form'
import ImageUploader from '../../form-components/image-uploader'
import { IPlateCity } from '../../../models/plate-cities/response'
import { MediumFor } from '../../../models/medium/enum'
import OldImage from '../../form-components/old-image'

interface IProps {
  plateCity?: IPlateCity
}
const { TabPane } = Tabs

const PlateCityForm: React.FC<IProps> = ({ plateCity }) => {
  const { t } = useTranslation()

  // Old Image
  const [image, setImage] = useState(plateCity?.image?.url)

  useEffect(() => {
    plateCity?.image?.url && setImage(plateCity?.image?.url)
  }, [plateCity?.image?.url])

  return (
    <div>
      {/* Plate City Name */}
      <TranslationsCard>
        <Tabs type='line'>
          {languages?.map((lang, index) => {
            return (
              <TabPane tab={lang.name} key={index}>
                <FieldBuilder
                  key={index}
                  name={`name[${lang?.code}]`}
                  label={`${t('name')} (${lang?.name})`}
                  input={{ type: 'text' }}
                  rules={{ required: true }}
                />
              </TabPane>
            )
          })}
        </Tabs>
      </TranslationsCard>

      <Divider />

      {/* Upload Plate Image */}
      <div>
        <Form.Item label={t('plate_city_image')}>
          <Controller
            name='imageId'
            rules={{
              required: false,
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <ImageUploader
                  {...field}
                  onChange={(response) => {
                    setImage(undefined)
                    field.onChange(response)
                  }}
                  imageFor={MediumFor.PLATE_CITY_IMAGE}
                />
              )
            }}
          />
        </Form.Item>
      </div>

      {/* Old  Image */}
      {image && <OldImage src={image} />}
    </div>
  )
}

export default PlateCityForm
