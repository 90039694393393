import React, { useState } from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'

import { PromoCodeStatus } from '../../../models/promo-code/enum'
import { Select } from 'antd'
import FormItem from 'antd/lib/form/FormItem'

interface IProps {}
const { Option } = Select
const PromoCodeForm: React.FC<IProps> = () => {
  const { t } = useTranslation()
  const types = ['discount_percentage', 'amount']
  const [typesValue, setTypesValue] = useState('discount_percentage')

  return (
    <div>
      <FieldBuilder
        name='code'
        label={t('code')}
        input={{ type: 'text' }}
        rules={{ required: true }}
      />

      <FormItem style={{ display: 'block' }} label={t('type_of_promo')}>
        <Select
          style={{ width: '100%' }}
          defaultValue={typesValue}
          onChange={(value) => {
            setTypesValue(value)
          }}
        >
          {types.map((type, index) => (
            <Option key={index} value={type}>
              {t(type)}
            </Option>
          ))}
        </Select>
      </FormItem>
      {(typesValue === 'discount_percentage' && (
        <FieldBuilder
          name='discountPercentage'
          label={t('discount_percentage')}
          input={{ type: 'number' }}
          rules={{ required: true, max: 100 }}
        />
      )) ||
        (typesValue === 'amount' && (
          <FieldBuilder
            name='amount'
            label={t('amount')}
            input={{ type: 'number' }}
            rules={{ required: true }}
          />
        ))}

      {/* Status */}
      <FieldBuilder
        name='status'
        label={t('status')}
        rules={{ required: true }}
        input={{
          type: 'select',
          options: [
            {
              value: PromoCodeStatus.ACTIVE,
              label: t(`${PromoCodeStatus[PromoCodeStatus.ACTIVE]}`),
            },
            {
              value: PromoCodeStatus.INACTIVE,
              label: t(`${PromoCodeStatus[PromoCodeStatus.INACTIVE]}`),
            },
          ],
        }}
      />
    </div>
  )
}

export default PromoCodeForm
