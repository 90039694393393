import { ICityQuery } from '../../models/city/query'
import { ICity } from '../../models/city/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { toggleLoading } from '../../utils/helpers/functions'
import paramsReducer, { QueryAction } from './../base/base-reducer'
import { IInternalState, CityLoading } from './context'

type Action =
  | { type: 'LOADING'; payload: { loading: CityLoading | CityLoading[] } }
  | { type: 'SET_LIST'; payload: { list: IBaseListingResponse<ICity> } }
  | { type: 'SET_DETAILS'; payload: { details?: ICity } }
  | QueryAction<ICityQuery>

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      }
    case 'SET_LIST':
      return {
        ...state,
        list: action.payload.list,
      }

    case 'SET_DETAILS':
      return {
        ...state,
        details: action.payload.details,
      }

    default:
      return paramsReducer(state, action)
  }
}

export default reducer
