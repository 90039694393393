import { IPlateCityQuery } from '../../models/plate-cities/query'
import { IPlateCity } from '../../models/plate-cities/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { toggleLoading } from '../../utils/helpers/functions'
import paramsReducer, { QueryAction } from './../base/base-reducer'
import { IInternalState, PlateCityLoading } from './context'

type Action =
  | {
      type: 'LOADING'
      payload: { loading: PlateCityLoading | PlateCityLoading[] }
    }
  | { type: 'SET_LIST'; payload: { list: IBaseListingResponse<IPlateCity> } }
  | { type: 'SET_DETAILS'; payload: { details?: IPlateCity } }
  | QueryAction<IPlateCityQuery>

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      }
    case 'SET_LIST':
      return {
        ...state,
        list: action.payload.list,
      }

    case 'SET_DETAILS':
      return {
        ...state,
        details: action.payload.details,
      }

    default:
      return paramsReducer(state, action)
  }
}

export default reducer
