import {
  Descriptions as AntdDescriptions,
  Descriptions,
  Input,
  List,
  Tabs,
  Tag,
} from 'antd'
import { useTranslation } from 'react-i18next'
import {
  getSubcriptionStatusColor,
  SubscriptionStatus,
} from '../../../models/subscription/enum'
import { IClientSubscription } from '../../../models/client-subscription/response'
import Image from '../../general/antd/image'
import { useEffect, useState } from 'react'
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import { ServiceStatus } from '../../../models/service/enum'

const { TabPane } = Tabs

interface Props {
  clientSubscription?: IClientSubscription
}

const ClientSubscriptionDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [clientRemainingServices, setClientRemainingServices] = useState(
    props.clientSubscription?.clientRemainingServices
  )

  useEffect(() => {
    props.clientSubscription?.clientRemainingServices &&
      setClientRemainingServices(
        props.clientSubscription?.clientRemainingServices
      )
  }, [props.clientSubscription?.clientRemainingServices])

  return (
    <>
      <Descriptions size='middle' column={1} bordered>
        <AntdDescriptions.Item label={t('client')}>
          {`${props.clientSubscription?.client?.user?.firstName} ${props.clientSubscription?.client?.user?.lastName}`}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('subscription')}>
          {`${props?.clientSubscription?.subscription?.mainName} ${props?.clientSubscription?.subscription?.subName}`}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('subcription_price')}>
          {props.clientSubscription?.subscription?.price}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('start_date')}>
          {props.clientSubscription?.startDate}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('discount_percentage')}>
          {`${props.clientSubscription?.subscription?.discountPercentage}%`}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('image')}>
          <Image
            preview
            src={props.clientSubscription?.subscription?.image?.url}
          />
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('status')}>
          <Tag
            color={getSubcriptionStatusColor(
              props.clientSubscription?.isActive ?? 0
            )}
            style={{ textAlign: 'center' }}
          >
            {t(
              `${SubscriptionStatus[props.clientSubscription?.isActive ?? 0]}`
            )}
          </Tag>
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('renew')}>
          {props.clientSubscription?.renew}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('period')}>
          {props.clientSubscription?.subscription?.period}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('cars_count')}>
          {props.clientSubscription?.subscription?.carsCount}
        </AntdDescriptions.Item>
      </Descriptions>

      <Tabs defaultActiveKey='1' animated>
        <TabPane tab={t('client_remaining_services')} key='1'>
          <List
            header={
              <Input
                placeholder={t('search_hint')}
                suffix={<SearchOutlined />}
                allowClear
                onChange={(event) => {
                  let searchKey = event.target.value

                  let filteredModels =
                    props.clientSubscription?.clientRemainingServices?.filter(
                      (remainingService) =>
                        remainingService?.service?.name?.includes(searchKey)
                    )

                  setClientRemainingServices(filteredModels)

                  if (searchKey == '') {
                    setClientRemainingServices(
                      props.clientSubscription?.clientRemainingServices
                    )
                  }
                }}
              />
            }
            bordered
            dataSource={clientRemainingServices ?? []}
            renderItem={(item) => (
              <List.Item
                style={
                  {
                    // display: 'flex',
                  }
                }
              >
                {item.service.status === ServiceStatus.ACTIVE ? (
                  <Image preview src={item?.service?.activeImage?.url} />
                ) : (
                  <Image preview src={item.service.inactiveImage.url} />
                )}
                <span style={{ position: 'absolute', left: '15%' }}>
                  {item?.service?.name}
                </span>
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </>
  )
}

export default ClientSubscriptionDetails
