import { IPlateCityQuery } from '../../models/plate-cities/query'
import {
  ICreatePlateCity,
  IUpdatePlateCity,
} from '../../models/plate-cities/request'
import { IPlateCity } from '../../models/plate-cities/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class PlateCityService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllPlateCities = (query: IPlateCityQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IPlateCity>>({
      path: `/dashboard/plate-cities/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createPlateCity = (data: ICreatePlateCity, params?: RequestParams) =>
    this.http.request<IPlateCity>({
      path: '/dashboard/plate-cities',
      method: 'POST',
      body: data,
      ...params,
    })

  updatePlateCity = (
    id: number,
    data: IUpdatePlateCity,
    params?: RequestParams
  ) =>
    this.http.request<IPlateCity>({
      path: `/dashboard/plate-cities/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deletePlateCity = (id: number, params?: RequestParams) =>
    this.http.request<IPlateCity>({
      path: `/dashboard/plate-cities/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default PlateCityService
