import { IRequestQuery } from '../../models/request/query'
import { IApproveRequest, IRejectRequest } from '../../models/request/request'
import { IRequest } from '../../models/request/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class RequestService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllRequests = (query: IRequestQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IRequest>>({
      path: `/dashboard/requests/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  approveRequest = (
    id: number,
    data: IApproveRequest,
    params?: RequestParams
  ) =>
    this.http.request<IRequest>({
      path: `/dashboard/requests/${id}/approve`,
      method: 'PUT',
      body: data,
      ...params,
    })

  rejectRequest = (id: number, data: IRejectRequest, params?: RequestParams) =>
    this.http.request<IRequest>({
      path: `/dashboard/requests/${id}/reject`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteRequest = (id: number, params?: RequestParams) =>
    this.http.request<IRequest>({
      path: `/dashboard/requests/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default RequestService
