import { Descriptions as AntdDescriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { IConfig } from '../../../models/config/response'
import Descriptions from '../../general/antd/descriptions'

interface Props {
  config?: IConfig
}

const ConfigDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('key')}>
        {props.config?.key}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('value')}>
        {props.config?.value}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('update_date')}>
        {props.config?.updatedAt}
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default ConfigDetails
