import { createContext } from 'react'
import { ICarColorQuery } from '../../models/car-color/query'
import {
  ICreateCarColor,
  IUpdateCarColor,
} from '../../models/car-color/request'
import { ICarColor } from '../../models/car-color/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type CarColorLoading =
  | 'list'
  | 'details'
  | 'create'
  | 'update'
  | 'delete'

export interface IInternalState {
  loading: CarColorLoading[]

  list?: IBaseListingResponse<ICarColor>
  query: ICarColorQuery

  details?: ICarColor
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: ICarColor) => void

    createCarColor: (request: ICreateCarColor) => void
    updateCarColor: (id: number, request: IUpdateCarColor) => void
    deleteCarColor: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: ICarColorQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createCarColor: DEFAULT_FUNCTION,
    updateCarColor: DEFAULT_FUNCTION,
    deleteCarColor: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const CarColorContext = createContext(externalState)

export default CarColorContext
