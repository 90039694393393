import { IPromoCodeQuery } from '../../models/promo-code/query'
import {
  ICreatePromoCode,
  IUpdatePromoCode,
} from '../../models/promo-code/request'
import { IPromoCode } from '../../models/promo-code/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class PromoCodeService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllPromoCodes = (query: IPromoCodeQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IPromoCode>>({
      path: `/dashboard/promo-codes/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createPromoCode = (data: ICreatePromoCode, params?: RequestParams) =>
    this.http.request<IPromoCode>({
      path: '/dashboard/promo-codes',
      method: 'POST',
      body: data,
      ...params,
    })

  updatePromoCode = (
    id: number,
    data: IUpdatePromoCode,
    params?: RequestParams
  ) =>
    this.http.request<IPromoCode>({
      path: `/dashboard/promo-codes/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deletePromoCode = (id: number, params?: RequestParams) =>
    this.http.request<IPromoCode>({
      path: `/dashboard/promo-codes/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default PromoCodeService
