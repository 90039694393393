import React, { useContext, useEffect, useState } from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { languages } from '../../../utils/helpers/constants'
import { Tabs, Divider, Row, Col, List, Tag } from 'antd'
import TranslationsCard from '../../form-components/translations-card'
import {
  IPermanentLocation,
  IPermanentLocationService,
} from '../../../models/permanent-location/response'
import { PermanentLocationStatus } from '../../../models/permanent-location/enum'
import CitySelectOptionsContext from '../../../contexts/select-options/city-select-options/context'
import RemoveBtn from '../../table-components/actions/remove-btn'
import AddBtn from '../../table-components/actions/add-btn/index'
import MapBoxPicker from '../../form-components/mapbox-picker'
import { useFormContext } from 'react-hook-form'
import Input from '../../form-components/input'
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import { ServiceStatus } from '../../../models/service/enum'
import Image from '../../general/antd/image'
import DeleteBtn from '../../table-components/actions/delete-btn/index'
import ServiceSelectOptionsContext from '../../../contexts/select-options/service-select-options/context'
import FormModal from '../../form-components/form-modal'
import AddServiceForm from '../permanent-location-service-form'

const { TabPane } = Tabs

interface IProps {
  permanentLocation?: IPermanentLocation
}

const PermanentLocationForm: React.FC<IProps> = ({ permanentLocation }) => {
  const { t } = useTranslation()
  const { setValue } = useFormContext()

  // Select Options
  const {
    list: cities,
    actions: citiesAction,
    loading: citiesLoading,
  } = useContext(CitySelectOptionsContext)

  const {
    list: serviceOptions,
    actions: serviceOptionsAction,
    loading: serviceOptionsLoading,
  } = useContext(ServiceSelectOptionsContext)

  useEffect(() => {
    citiesAction.getData()
    serviceOptionsAction.getData()
  }, [])

  // Services
  const [services, setServices] = useState(permanentLocation?.services)

  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    permanentLocation?.services && setServices(permanentLocation?.services)
  }, [permanentLocation?.services])

  // Services Management
  const [addServiceModalVisible, setAddServiceModalVisible] = useState(false)
  const addService = (serviceId: number, price: number, note: string) => {
    let selectedService = serviceOptions?.data.find((s) => s.id == serviceId)
    let newService: IPermanentLocationService
    if (selectedService) {
      newService = {
        ...selectedService,
        permanentLocationPrice: price,
        permanentLocationNote: note,
      }
      if (services) {
        setServices([...services, newService])
      } else {
        setServices([newService])
      }
    }
  }

  const deleteService = (serviceId: number) => {
    setServices(services?.filter((s) => s.id != serviceId))
  }

  useEffect(() => {
    setValue('services', undefined)
    services &&
      services.forEach((service, index) => {
        setValue(`services[${index}].serviceId`, service?.id)
        setValue(`services[${index}].price`, service?.permanentLocationPrice)
        setValue(`services[${index}].note`, service?.permanentLocationNote)
      })
  }, [services])

  return (
    <div>
      <TranslationsCard>
        <Tabs type='line'>
          {languages?.map((lang, index) => {
            return (
              <TabPane tab={lang.name} key={index}>
                <FieldBuilder
                  key={index}
                  name={`name[${lang?.code}]`}
                  label={`${t('name')} (${lang?.name})`}
                  input={{ type: 'text' }}
                  rules={{ required: true }}
                />

                <FieldBuilder
                  key={index}
                  name={`additionalInformation[${lang?.code}]`}
                  label={`${t('additional_information')} (${lang?.name})`}
                  input={{ type: 'text-area' }}
                  rules={{ required: false }}
                />
              </TabPane>
            )
          })}
        </Tabs>
      </TranslationsCard>

      <Divider />

      {/* City */}
      <FieldBuilder
        name='cityId'
        label={t('city')}
        rules={{ required: true }}
        input={{
          type: 'select',
          allowSearch: true,
          loading: citiesLoading.includes('list'),
          options: cities?.data?.map((city) => {
            return { value: city.id, label: city.name }
          }),
        }}
      />

      {/* Status */}
      <FieldBuilder
        name='status'
        label={t('status')}
        rules={{ required: true }}
        input={{
          type: 'select',
          options: [
            {
              value: PermanentLocationStatus.ACTIVE,
              label: t(
                `${PermanentLocationStatus[PermanentLocationStatus.ACTIVE]}`
              ),
            },
            {
              value: PermanentLocationStatus.BUSY,
              label: t(
                `${PermanentLocationStatus[PermanentLocationStatus.BUSY]}`
              ),
            },
          ],
        }}
      />

      {/* Start Time */}
      <FieldBuilder
        name='time'
        label={t('time')}
        input={{ type: 'time-range-picker', format: 'HH:mm' }}
        rules={{ required: true }}
      />

      {/* Close Time
      <FieldBuilder
        name='closeTime'
        label={t('close_time')}
        input={{ type: 'time-picker', format: 'HH:mm' }}
        rules={{ required: true }}
      /> */}

      {/* Radius */}
      <FieldBuilder
        name='radius'
        label={t('radius')}
        input={{ type: 'number' }}
        rules={{ required: true }}
      />

      <Divider />
      {/* Services & Location*/}
      <Tabs defaultActiveKey='1' animated type='line'>
        <TabPane tab={t('services')} key='1'>
          <List
            bordered={false}
            header={
              <Row justify='space-between'>
                <Col>
                  <AddBtn
                    title={t('add_service')}
                    onClick={() => {
                      // Show Modal
                      setAddServiceModalVisible(true)
                    }}
                  />
                </Col>
                <Col>
                  <Input
                    placeholder={t('search_hint')}
                    suffix={<SearchOutlined />}
                    allowClear
                    onChange={(event) => {
                      setSearchTerm(event.target.value)
                    }}
                  />
                </Col>
              </Row>
            }
            dataSource={
              services?.filter((service) =>
                service.name.toLowerCase().includes(searchTerm.toLowerCase())
              ) ?? []
            }
            renderItem={(item) => (
              <List.Item
                actions={[
                  <DeleteBtn
                    onConfirm={() => {
                      deleteService(item?.id)
                    }}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Image
                      src={
                        item?.status == ServiceStatus.ACTIVE
                          ? item?.activeImage?.url
                          : item?.inactiveImage?.url
                      }
                    />
                  }
                  title={`${item?.name} (${item?.permanentLocationPrice} ${t(
                    'aed'
                  )})`}
                  description={item?.permanentLocationNote}
                />
                <div>
                  {item?.status && (
                    <Tag
                      color={
                        item?.status == ServiceStatus.ACTIVE ? 'green' : 'red'
                      }
                    >
                      {t(`${ServiceStatus[item?.status]}`)}
                    </Tag>
                  )}
                </div>
              </List.Item>
            )}
          />
        </TabPane>
        {/* Map */}
        <TabPane tab={t('pick_location')} key='2'>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <FieldBuilder
                name='lat'
                label={t('lat')}
                input={{ type: 'number' }}
                rules={{ required: true }}
                disabled
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name='lng'
                label={t('lng')}
                input={{ type: 'number' }}
                rules={{ required: true }}
                disabled
              />
            </Col>
            <Col span={24} style={{ height: '30rem' }}>
              <MapBoxPicker
                onChange={(center) => {
                  setValue('lng', center.lng)
                  setValue('lat', center.lat)
                }}
                pickerCoordinates={
                  permanentLocation && [
                    permanentLocation?.lng,
                    permanentLocation?.lat,
                  ]
                }
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>

      {/* Add Service Modal */}
      <FormModal
        title={t('add_server_to_permanent_location')}
        formId='add-permanent-server'
        hideModal={() => {
          setAddServiceModalVisible(false)
        }}
        visible={addServiceModalVisible}
        onSubmit={(data: {
          serviceId: number
          price: number
          note: string
        }) => {
          addService(data.serviceId, data.price, data.note)
        }}
        confirmLoading={false}
      >
        <AddServiceForm
          servicesIdsAlreadySelected={services ? services.map((s) => s.id) : []}
        />
      </FormModal>
    </div>
  )
}

export default PermanentLocationForm
