import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { execute } from '../../../utils/api/api-execute'
import Form from '../from'
import SubmitBtn from '../submit-btn'
import Button from '../../general/antd/button'
import Drawer, { DrawerProps } from '../../general/antd/drawer'

interface Props<DataType> extends DrawerProps {
  formId: string
  hideDrawer: () => void
  defaultValues?: DataType
  onSubmit: (data: DataType) => void
  confirmLoading: boolean
}

const FormDrawer = function <DataType>({
  formId,
  hideDrawer,
  onSubmit,
  defaultValues,
  ...props
}: PropsWithChildren<Props<DataType>>) {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formSubmit = async (data: DataType) => {
    await execute({
      callback: async () => {
        await onSubmit(data)
        hideDrawer()
      },
      fallback: (error) => {},
      finallyCallback: () => {},
      throwException: false,
    })
  }

  return (
    <Drawer
      width='40vw'
      maskClosable={false}
      closable
      destroyOnClose
      onClose={hideDrawer}
      placement={language === 'ar' ? 'left' : 'right'}
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            key='cancle'
            onClick={() => hideDrawer()}
            style={{ marginInlineEnd: '1rem' }}
          >
            {t('cancel')}
          </Button>
          <SubmitBtn form={formId} loading={props.confirmLoading}>
            {t('save')}
          </SubmitBtn>
        </div>
      }
      {...props}
    >
      <Form<DataType>
        formId={formId}
        onSubmit={formSubmit}
        defaultValues={defaultValues}
        children={props.children}
      />
    </Drawer>
  )
}

export default FormDrawer
