import { Layout } from 'antd'
import Menu from '../menu'
import { SiderProps as AntdSiderProps } from 'antd/lib/layout'
import styles from './styles.module.scss'

const { Sider: AntdSider } = Layout

export interface SiderProps extends AntdSiderProps {}

const Sider: React.FC<SiderProps> = (props) => {
  return (
    <AntdSider className={styles.sider} theme='light' collapsible {...props}>
      <Menu />
    </AntdSider>
  )
}

export default Sider
