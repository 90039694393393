import { Button, Col, Divider, Row, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FieldBuilder from "../../form-components/field-builder";
import OrderContext from "../../../contexts/order/context";
import FilterCard from "../../general/filter-card";
import Form from "../../form-components/from";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";

import styles from "./style.module.scss";
import Controller from "../../form-components/controller";
import { OrderStatus, OrderUserType } from "../../../models/order/enum";
import { IClient } from "../../../models/client/response";
import { debounce } from "lodash";
import EndPoints from "../../../services/end-points";
import StaffSelectOptionsContext from "../../../contexts/select-options/staff-select-options/context";
import { RequestStatus } from "../../../models/request/enum";

interface IProps {}

const { Option } = Select;

const OrderFilters: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, loading, query } = useContext(OrderContext);

  const [userType, setUserType] = useState<OrderUserType>();

  //Staffs
  const {
    list: staffs,
    actions: staffActions,
    loading: staffLoading,
  } = useContext(StaffSelectOptionsContext);

  useEffect(() => {
    staffActions.getData();
  }, []);

  //Clients
  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(false);
  const [clientSearch, setClientSearch] = useState("");
  const [page, setPage] = useState(INITIAL_PAGE);

  const getData = debounce(async () => {
    setClientsLoading(true);
    const data = await EndPoints.client.getAllClients({
      page: page,
      perPage: 5,
      keyword: clientSearch,
    });
    if (page === INITIAL_PAGE) {
      setClients(data.data.data);
    } else {
      setClients([...clients, ...data.data.data]);
    }
    setClientsLoading(false);
  }, 700);

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    if (page === INITIAL_PAGE) {
      getData();
    } else {
      setPage(INITIAL_PAGE);
    }
  }, [clientSearch]);

  return (
    <div className={styles.formContainer}>
      <Form
        formId="orders-filter"
        onSubmit={(data) => {
          const filter: any = {
            page: INITIAL_PAGE,
            perPage: query?.perPage,
            keyword: data?.keyword,
            staticFilters: [
              data?.clients?.length > 0
                ? {
                    name: "createdBy",
                    value: [...(data?.clients ?? [])],
                    // == != > < >= <= in
                    operation: "in",
                  }
                : undefined,
              data?.status?.length > 0
                ? {
                    name: "status",
                    value: [...(data?.status ?? [])],
                    // == != > < >= <= in
                    operation: "in",
                  }
                : undefined,
              data?.staffs?.length > 0
                ? {
                    name: "staffId",
                    value: [...(data?.staffs ?? [])],
                    // == != > < >= <= in
                    operation: "in",
                  }
                : undefined,
              data?.userType
                ? {
                    name: "userType",
                    value: data?.userType,
                    // == != > < >= <= in
                    operation: "in",
                  }
                : undefined,
              data.requiredDateBetween !== undefined &&
              data.requiredDateBetween[0] !== "" &&
              data.requiredDateBetween[1] !== ""
                ? {
                    name: "requiredDateBetween",
                    operation: "between",
                    value: data?.requiredDateBetween,
                  }
                : undefined,
            ].filter((f) => f),
          };
          actions.setQuery(filter);
        }}
      >
        <FilterCard
          formId="orders-filter"
          applyLoading={loading.includes("list")}
          resetLoading={loading.includes("list")}
          onReset={() => {
            actions.setQuery({
              page: query?.page,
              perPage: query?.perPage,
            });
          }}
        >
          <Row justify={"start"} gutter={[10, 0]} className={styles.filters}>
            <Col span={8}>
              <FieldBuilder
                name="keyword"
                label={t("search")}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <div style={{ marginTop: "10px" }}>
                {t("user_type")}
                <Controller
                  name="userType"
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <Select
                        {...field}
                        style={{ width: "100%" }}
                        allowClear={true}
                        placeholder={t("user_type")}
                        onChange={(value) => {
                          setUserType(value);
                          field.onChange(value);
                        }}
                      >
                        <Option value={OrderUserType.CLIENT}>
                          {OrderUserType[OrderUserType.CLIENT]}
                        </Option>
                        <Option value={OrderUserType.STAFF}>
                          {OrderUserType[OrderUserType.STAFF]}
                        </Option>
                      </Select>
                    );
                  }}
                />
              </div>
            </Col>
            {(userType === 1 && (
              <Col span={8}>
                <div style={{ marginTop: "10px" }}>
                  {t("clients")}
                  <Controller
                    name="clients"
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <Select
                          {...field}
                          loading={clientsLoading}
                          showSearch={true}
                          filterOption={false}
                          placeholder={t("clients")}
                          mode="multiple"
                          onSearch={(value) => setClientSearch(value)}
                          autoClearSearchValue={false}
                          searchValue={clientSearch}
                          allowClear
                          dropdownRender={(menu) => {
                            return (
                              <div>
                                {menu}
                                <Divider style={{ margin: "4px 0" }} />
                                <Button
                                  style={{ display: "block", margin: "auto" }}
                                  type="link"
                                  onClick={() => {
                                    setPage((page) => page + 1);
                                  }}
                                  loading={clientsLoading}
                                >
                                  {t("show_more")}
                                </Button>
                              </div>
                            );
                          }}
                          style={{ width: "100%" }}
                        >
                          {clients.map((client) => {
                            return (
                              <Option key={client?.id} value={client?.id}>
                                {`${client?.user?.firstName} ${client?.user?.lastName}`}
                              </Option>
                            );
                          })}
                        </Select>
                      );
                    }}
                  />
                </div>
              </Col>
            )) ||
              (userType === 2 && (
                <Col span={8}>
                  <FieldBuilder
                    name="staffs"
                    label={t("staffs")}
                    input={{
                      type: "multiSelect",
                      loading: staffLoading.includes("list"),
                      allowSearch: true,

                      options: staffs?.data.map((staff) => {
                        return {
                          value: staff.user.id,
                          label: `${staff.user.firstName} ${staff.user.lastName}`,
                        };
                      }),
                    }}
                  />
                </Col>
              ))}
            <Col span={8}>
              <FieldBuilder
                name="status"
                label={t("status")}
                input={{
                  type: "multiSelect",
                  options: [
                    {
                      value: OrderStatus.APPROVED,
                      label: t(`${RequestStatus[RequestStatus.APPROVED]}`),
                    },
                    {
                      value: OrderStatus.PENDING,
                      label: t(`${OrderStatus[OrderStatus.PENDING]}`),
                    },
                    {
                      value: OrderStatus.CARD_DECLINED,
                      label: t(`${OrderStatus[OrderStatus.CARD_DECLINED]}`),
                    },
                    {
                      value: OrderStatus.REJECTED,
                      label: t(`${OrderStatus[OrderStatus.REJECTED]}`),
                    },
                    {
                      value: OrderStatus.COMPLETED,
                      label: t(`${OrderStatus[OrderStatus.COMPLETED]}`),
                    },
                    {
                      value: OrderStatus.ON_THE_WAY,
                      label: t(`${OrderStatus[OrderStatus.ON_THE_WAY]}`),
                    },
                    {
                      value: OrderStatus.CANCELED,
                      label: t(`${OrderStatus[OrderStatus.CANCELED]}`),
                    },
                    {
                      value: OrderStatus.PAID,
                      label: t(`${OrderStatus[OrderStatus.PAID]}`),
                    },
                    {
                      value: OrderStatus.STARTED,
                      label: t(`${OrderStatus[OrderStatus.STARTED]}`),
                    },
                  ],
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="requiredDateBetween"
                label={`${t("createdAt")}`}
                input={{ type: "range-picker" }}
                rules={{ required: false }}
              />
            </Col>
          </Row>
        </FilterCard>
      </Form>
    </div>
  );
};

export default OrderFilters;
