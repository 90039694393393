import { DirectionType } from 'antd/lib/config-provider'
import { createContext } from 'react'
import en from '../../utils/localization/locales/en/en-locale'

export type AppLoading = 'general-information'

export type ScreenType = 'Laptop' | 'Mobile'
export interface IInternalState {
  loading: AppLoading[]
  screenType: ScreenType

  direction: DirectionType
  locale: any
}

export const internalState: IInternalState = {
  loading: [],
  screenType: 'Laptop',

  direction: 'ltr',
  locale: en,
}

export interface IExternalState extends IInternalState {
  actions: {}
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {},
}

const AppContext = createContext(externalState)

export default AppContext
