/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useReducer } from 'react'
import reducer from './reducer'
import BrandContext, { internalState } from './context'
import { execute } from '../../utils/api/api-execute'
import EndPoints from '../../services/end-points'
import { IBrand } from '../../models/brand/response'
import { IBrandQuery } from '../../models/brand/query'
import AuthContext from '../auth/context'
import { ICreateBrand, IUpdateBrand } from '../../models/brand/request'

const BrandContextProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState)

  const { isAuthenticated } = useContext(AuthContext)
  /**
   * Get data when query changed
   */
  useEffect(() => {
    isAuthenticated && getData()
  }, [state.query, isAuthenticated])

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'list' } })

        const { data } = await EndPoints.brand.getAllBrands(state.query)

        dispatch({ type: 'SET_LIST', payload: { list: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'list' } })
      },
      throwException: false,
    })
  }

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })

        const data = state.list?.data?.find((u) => u?.id === id)

        dispatch({ type: 'SET_DETAILS', payload: { details: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })
      },
      throwException: false,
    })
  }

  const setDetails = async (data?: IBrand) => {
    dispatch({ type: 'SET_DETAILS', payload: { details: data } })
  }

  const createBrand = async (request: ICreateBrand) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'create' },
        })

        await EndPoints.brand.createBrand(request)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'create' },
        })
      },
      throwException: true,
    })
  }

  const updateBrand = async (id: number, request: IUpdateBrand) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'update' },
        })
        await EndPoints.brand.updateBrand(id, request)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'update' },
        })
      },
      throwException: true,
    })
  }

  const deleteBrand = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete' },
        })

        await EndPoints.brand.deleteBrand(id)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete' },
        })
      },
      throwException: true,
    })
  }

  const setSearch = (search?: string) => {
    dispatch({ type: 'SET_SEARCH', payload: { search } })
  }

  const setQuery = (query: IBrandQuery) => {
    dispatch({ type: 'SET_QUERY', payload: { query } })
  }

  return (
    <BrandContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createBrand,
          updateBrand,
          deleteBrand,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </BrandContext.Provider>
  )
}

export default BrandContextProvider
