import React from "react";
import { useContext } from "react";
import { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import AttendanceContext from "../../../contexts/attendance/context";
import { IAttendance } from "../../../models/attendance/response";
import Space from "../../general/antd/space";
import EditBtn from "../../table-components/actions/edit-btn";
import ViewBtn from "../../table-components/actions/view-btn";
import Table from "../../table-components/table";
import DeleteBtn from "../../table-components/actions/delete-btn";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { IPermanentLocation } from "../../../models/permanent-location/response";
import { IUser } from "../../../models/user/response";
import moment from "moment";
import { floor } from "lodash";
import { durationBetweenDates } from "../../../utils/helpers/functions";
import { Tag } from "antd";
import { AttendanceStatus } from "../../../models/attendance/enum";
import { useState } from "react";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { useEffect } from "react";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";

interface Props {
  showFormDrawer: () => void;
  showDetailsDrawer: () => void;
}

const AttendanceTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { list, actions, loading, query } = useContext(AttendanceContext);

  const columns: ColumnProps<IAttendance>[] = [
    {
      key: "id",
      title: t("id"),
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
    },
    {
      key: "createdBy",
      title: t("staff"),
      dataIndex: "createdBy",
      render: (createdBy: IUser) => {
        return `${createdBy?.firstName} ${createdBy?.lastName}`;
      },
      sorter: {
        multiple: 2,
      },
    },

    {
      key: "attendanceDate",
      title: t("date"),
      dataIndex: "attendanceDate",
      sorter: {
        multiple: 3,
      },
    },

    {
      key: "checkInTime",
      title: t("check_in_time"),
      dataIndex: "checkInTime",
      render: (checkInTime) => {
        return (
          <span style={{ display: "flex", justifyContent: "center" }}>
            {checkInTime.substring(0, 5) ?? ""}
          </span>
        );
      },
      sorter: {
        multiple: 4,
      },
    },
    {
      key: "checkInStatus",
      title: t("check_in_status"),
      dataIndex: "checkInTime",
      render: (_, record) => {
        return (
          record?.checkInStatus && (
            <span style={{ display: "flex", justifyContent: "center" }}>
              <Tag color={"grey"}>{AttendanceStatus[record.checkInStatus]}</Tag>
            </span>
          )
        );
      },
    },
    {
      key: "checkOutTime",
      title: t("check_out_time"),
      dataIndex: "checkOutTime",
      render: (checkOutTime) => {
        let result = checkOutTime?.substring(0, 5);
        return (
          <span style={{ display: "flex", justifyContent: "center" }}>
            {result}
          </span>
        );
      },
      sorter: { multiple: 5 },
    },
    {
      key: "checkOutStatus",
      title: t("check_out_status"),
      dataIndex: "checkOutTime",
      render: (_, record) => {
        return (
          record.checkInStatus && (
            <span style={{ display: "flex", justifyContent: "center" }}>
              <Tag color={"grey"}>
                {AttendanceStatus[record.checkOutStatus]}
              </Tag>
            </span>
          )
        );
      },
    },

    {
      key: "actions",
      title: t("actions"),
      align: "center",
      width: 200,
      render: (_, record) => (
        <Space>
          <ViewBtn
            onClick={() => {
              actions.setDetails(record);
              props.showDetailsDrawer();
            }}
          />
          {/* <EditBtn
            onClick={() => {
              actions.setDetails(record)
              props.showFormDrawer()
            }}
          /> */}
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteAttendance(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];
  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(
    query?.staticFilters ?? []
  );

  useEffect(() => {
    sorts &&
      actions.setQuery({
        ...query,
        sorts: sorts,
      });
  }, [sorts]);

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={list?.data}
      loading={loading.includes("list")}
      showPagination
      total={list?.meta?.total}
      page={query.page}
      pageSize={query.perPage}
      scroll={{ x: 200 }}
      onPaginationChange={(page, pageSize) => {
        actions.setQuery({
          ...query,
          page,
          perPage: pageSize,
        });
      }}
      onChange={(_, tableFilters, tabelSorters) => {
        tableOnChange(
          tableFilters,
          tabelSorters,
          tableFiltersProps,
          setSorts,
          setStaticFilters
        );
      }}
    />
  );
};

export default AttendanceTable;
