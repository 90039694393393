import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Button, { ButtonProps } from '../../../general/antd/button'

interface Props extends ButtonProps {
  title?: string
}

const AddBtn: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Button icon={<PlusOutlined />} type='primary' title={t('add')} {...props}>
      {props?.title ?? t('add')}
    </Button>
  )
}

export default AddBtn
