export enum AddressStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

// Status Colors
export const getAddressStatusColor = (status: AddressStatus) => {
  switch (status) {
    case AddressStatus.ACTIVE:
      return 'green'
    case AddressStatus.INACTIVE:
      return 'red'
    default:
      return 'gray'
  }
}
