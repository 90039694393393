import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import { ICreateUser } from '../../models/user/request'
import eventManager, { EVENT_SUCCESS } from '../../utils/events'
import UserContextProvider from '../../contexts/user/provider'
import PageHeader from '../../components/general/antd/page-header'
import UserContext from '../../contexts/user/context'
import CreateBtn from '../../components/table-components/actions/create-btn'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import UserTable from '../../components/user/user-table'
import UserFormModal from '../../components/user/user-form-modal'
import UserDetails from '../../components/user/user-details'
import DetailsModal from '../../components/form-components/details-modal'

interface Props {
  route: IRoute
}

const Users: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(UserContext)

  const onSubmit = async (data: ICreateUser) => {
    details
      ? await actions.updateUser(details.id, data)
      : await actions.createUser(data)
    eventManager.emit(EVENT_SUCCESS)
  }

  const [formModalVisable, setFormModalVisable] = useState(false)

  const [detailsModalVisable, setDetailsModalVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <CreateBtn
              onClick={() => {
                actions.setDetails(undefined)
                setFormModalVisable(true)
              }}
            />
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />
      <UserTable
        showFormModal={() => setFormModalVisable(true)}
        showDetailsModal={() => setDetailsModalVisable(true)}
      />

      <UserFormModal
        visable={formModalVisable}
        defaultValues={details}
        loading={loading.includes('create') || loading.includes('update')}
        hideModal={() => setFormModalVisable(false)}
        onSubmit={onSubmit}
      />

      <DetailsModal
        visible={detailsModalVisable}
        hideModal={() => setDetailsModalVisable(false)}
      >
        <UserDetails user={details} />
      </DetailsModal>
    </>
  )
}

const UsersPage: React.FC<Props> = (props) => {
  return (
    <UserContextProvider>
      <Users {...props} />
    </UserContextProvider>
  )
}
export default UsersPage
