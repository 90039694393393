import { Descriptions as AntdDescriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { IStaffType } from '../../../models/staff-type/response'
import Descriptions from '../../general/antd/descriptions'

interface Props {
  staffType?: IStaffType
}

const StaffTypeDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.staffType?.id}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('name')}>
        {props.staffType?.name}
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default StaffTypeDetails
