import { createContext } from 'react'
import { IBannerQuery } from '../../models/banner/query'
import { ICreateBanner, IUpdateBanner } from '../../models/banner/request'
import { IBanner } from '../../models/banner/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type BannerLoading = 'list' | 'details' | 'create' | 'update' | 'delete'

export interface IInternalState {
  loading: BannerLoading[]

  list?: IBaseListingResponse<IBanner>
  query: IBannerQuery

  details?: IBanner
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IBanner) => void

    createBanner: (request: ICreateBanner) => void
    updateBanner: (id: number, request: IUpdateBanner) => void
    deleteBanner: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IBannerQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createBanner: DEFAULT_FUNCTION,
    updateBanner: DEFAULT_FUNCTION,
    deleteBanner: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const BannerContext = createContext(externalState)

export default BannerContext
