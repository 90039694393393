import { Descriptions as AntdDescriptions, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  CommissionType,
  getStaffStatusColor,
  StaffStatus,
} from '../../../models/staff/enum'
import { IStaff } from '../../../models/staff/response'
import Descriptions from '../../general/antd/descriptions'
import Image from '../../general/antd/image/index'

interface Props {
  staff?: IStaff
}

const StaffDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.staff?.id}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('name')}>
        {`${props.staff?.user?.firstName} ${props.staff?.user?.lastName}`}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('permanent_location')}>
        {props.staff?.permanentLocation?.name}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('city')}>
        {props.staff?.permanentLocation?.city?.name}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('country')}>
        {props.staff?.permanentLocation?.city?.country?.name}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('staff_type')}>
        {props.staff?.staffType?.name}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('user_name')}>
        {props.staff?.userName}
      </AntdDescriptions.Item>

      {props.staff?.commissionType && (
        <AntdDescriptions.Item label={t('commission_type')}>
          {t(CommissionType[props.staff?.commissionType].toLowerCase())}
        </AntdDescriptions.Item>
      )}

      <AntdDescriptions.Item label={t('commission')}>
        {props.staff?.commissionType === CommissionType.PERCENTAGE
          ? `${props.staff?.commission}%`
          : `${props.staff?.commission} ${t('aed')}`}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('rating')}>
        {props.staff?.rating}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('status')}>
        {props?.staff?.status && (
          <Tag color={getStaffStatusColor(props.staff.status)}>
            {t(`${StaffStatus[props.staff.status]}`)}
          </Tag>
        )}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('deserved_commission')}>
        {`${props.staff?.deservedCommission} ${t('AED')}`}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('cash_in_hand')}>
        {`${props.staff?.funds} ${t('AED')}`}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('image')}>
        <Image preview src={props.staff?.image?.url} />
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('national_id_image')}>
        <Image preview src={props.staff?.nationalIdImage?.url} />
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('passport_image')}>
        <Image preview src={props.staff?.passportImage?.url} />
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default StaffDetails
