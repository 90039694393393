import { Layout } from 'antd'
import Header from '../../general/header'
import Sider from '../../general/sider'
import { useState } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import Footer from '../../general/footer'
import AuthContextProvider from '../../../contexts/auth/provider'
import NotificationContextProvider from '../../../contexts/notifications/provider'

const { Content } = Layout

interface Props {}

const DashboardLayout: React.FC<Props> = (props) => {
  /**
   * collapse state
   */
  const [collapsed, setCollapsed] = useState(false)

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <NotificationContextProvider>
        <Header />
      </NotificationContextProvider>
      <Layout style={{ marginTop: 66, width: 'auto' }}>
        <Sider
          onCollapse={(collapse) => {
            setCollapsed(collapse)
          }}
        />
        <Layout
          className={classNames(styles['content-layout'], {
            [styles.collapsed]: collapsed,
          })}
        >
          <Content style={{ margin: '16px' }}>{props.children}</Content>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  )
}

export default DashboardLayout
