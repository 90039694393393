import { createContext } from 'react'
import { ITransactionStaffToAdminQuery } from '../../models/transaction-staff-to-admin/query'
import {
  ITransactionStaffToAdmin,
  ITransactionStaffToAdminDetails,
} from '../../models/transaction-staff-to-admin/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type TransactionStaffToAdminLoading =
  | 'list'
  | 'details'
  | 'create'
  | 'update'
  | 'delete'

export interface IInternalState {
  loading: TransactionStaffToAdminLoading[]

  list?: IBaseListingResponse<ITransactionStaffToAdmin>
  query: ITransactionStaffToAdminQuery

  details?: ITransactionStaffToAdminDetails
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: ITransactionStaffToAdminDetails) => void

    deleteTransactionStaffToAdmin: (id: number) => void
    confirmTransactionStaffToAdmin: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: ITransactionStaffToAdminQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    deleteTransactionStaffToAdmin: DEFAULT_FUNCTION,
    confirmTransactionStaffToAdmin: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const TransactionStaffToAdminContext = createContext(externalState)

export default TransactionStaffToAdminContext
