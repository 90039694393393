import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import { Descriptions as AntdDescriptions, Input, List, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IBrand } from '../../../models/brand/response'
import Descriptions from '../../general/antd/descriptions'
import Image from '../../general/antd/image/index'

const { TabPane } = Tabs

interface Props {
  brand?: IBrand
}

const BrandDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [models, setModels] = useState(props.brand?.models)

  useEffect(() => {
    props.brand?.models && setModels(props.brand?.models)
  }, [props.brand?.models])

  return (
    <>
      <Descriptions size='middle' column={1} bordered>
        <AntdDescriptions.Item label={t('id')}>
          {props.brand?.id}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('name')}>
          {props.brand?.name}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('main_brand')}>
          {props.brand?.parentBrand?.name}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('image')}>
          <Image src={props.brand?.brandImage?.url} />
        </AntdDescriptions.Item>
      </Descriptions>

      {/* Models */}
      <Tabs defaultActiveKey='1' animated>
        <TabPane tab={t('models')} key='1'>
          <List
            header={
              <Input
                placeholder={t('search_hint')}
                suffix={<SearchOutlined />}
                allowClear
                onChange={(event) => {
                  let searchKey = event.target.value

                  let filteredModels = props.brand?.models?.filter((model) =>
                    model?.name?.includes(searchKey)
                  )

                  setModels(filteredModels)

                  if (searchKey == '') {
                    setModels(props.brand?.models)
                  }
                }}
              />
            }
            bordered
            dataSource={models ?? []}
            renderItem={(item) => <List.Item>{item.name}</List.Item>}
          />
        </TabPane>
      </Tabs>
    </>
  )
}

export default BrandDetails
