import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import eventManager, { EVENT_ERORR, EVENT_SUCCESS } from '../../utils/events'
import PageHeader from '../../components/general/antd/page-header'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import SubscriptionContext from '../../contexts/subscription/context'
import SubscriptionContextProvider from '../../contexts/subscription/provider'
import SubscriptionTable from '../../components/subscription/subcription-table'
import SubscriptionFilters from '../../components/subscription/subcription-filter'
import CreateBtn from '../../components/table-components/actions/create-btn'
import SubscriptionFormDrawer from '../../components/subscription/subscription-form-drawer'
import { checkTranslations } from '../../utils/helpers/constants'
import InfoDrawer from '../../components/general/info-drawer'
import SubscriptionDetails from '../../components/subscription/subcription-details'
import { getTranslations } from '../../utils/helpers/translations'

interface Props {
  route: IRoute
}

const Subscriptions: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(SubscriptionContext)

  //   const [approveFormVisable, setApproveFormVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)
  const [formDrawerVisable, setFormDrawerVisable] = useState(false)

  const onSubmit = async (data: any) => {
    if (
      checkTranslations(data.mainName) === true &&
      checkTranslations(data.subName) === true &&
      checkTranslations(data.additionalInformation) === true
    ) {
      details
        ? await actions.updateSubscription(details.id, data)
        : await actions.createSubscription(data)
      eventManager.emit(EVENT_SUCCESS)
    } else {
      eventManager.emit(EVENT_ERORR, t('translations_required_message'))
      throw 'tranlations required'
    }
  }

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <CreateBtn
              onClick={() => {
                actions.setDetails(undefined)
                setFormDrawerVisable(true)
              }}
            />
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />

      <SubscriptionFilters />

      <SubscriptionTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <SubscriptionFormDrawer
        formId={'subscription-form'}
        visable={formDrawerVisable}
        defaultValues={
          details && {
            id: details?.id,
            mainName: getTranslations(details?.translations?.mainName),
            subName: getTranslations(details?.translations?.subName),
            additionalInformation: getTranslations(
              details?.translations?.additionalInformation
            ),
            price: details?.price,
            period: details?.period,
            discountPercentage: details?.discountPercentage,
            carsCount: details?.carsCount,
            status: details?.status,
          }
        }
        details={details}
        loading={loading.includes('create') || loading.includes('update')}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      />
      {/* <ApproveFormModal
        loading={loading.includes('update')}
        visable={approveFormVisable}
        hideModal={() => setApproveFormVisable(false)}
        onSubmit={onApproveRequest}
      /> */}
      <InfoDrawer
        title={t('subscription_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <SubscriptionDetails subscription={details} />
      </InfoDrawer>
    </>
  )
}

const SubscriptionPage: React.FC<Props> = (props) => {
  return (
    <SubscriptionContextProvider>
      <Subscriptions {...props} />
    </SubscriptionContextProvider>
  )
}
export default SubscriptionPage
