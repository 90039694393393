import { createContext } from 'react'
import { INotificationQuery } from '../../models/notifications/query'
import { ICreatePublicNotification } from '../../models/notifications/request'
import { INotifications } from '../../models/notifications/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type NotificationsLoading =
  | 'list'
  | 'details'
  | 'create'
  | 'update'
  | 'delete'

export interface IInternalState {
  loading: NotificationsLoading[]

  list?: IBaseListingResponse<INotifications>
  query: INotificationQuery

  details?: INotifications
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: string) => void
    setDetails: (data?: INotifications) => void

    createPublicNotification: (request: ICreatePublicNotification) => void
    readNotification: (id: string) => void
    // deleteFAQ: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: INotificationQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createPublicNotification: DEFAULT_FUNCTION,
    readNotification: DEFAULT_FUNCTION,
    // deleteFAQ: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const NotificationContext = createContext(externalState)

export default NotificationContext
