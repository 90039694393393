import { ComponentType, lazy } from 'react'
import Permission from '../utils/rbac/permissions'
import { DashboardOutlined } from '@ant-design/icons'
import { Path } from '../pages/paths'
import { Route as DomRoute } from 'react-router-dom'
import Error403 from '../pages/403'
import { check } from '../utils/rbac/can'
import UsersPage from '../pages/users'
import {
  FiBell,
  FiBox,
  FiFlag,
  FiGrid,
  FiMap,
  FiMapPin,
  FiMenu,
  FiPaperclip,
  FiPercent,
  FiSettings,
  FiShoppingBag,
  FiTag,
  FiUser,
  FiUserCheck,
  FiUsers,
} from 'react-icons/fi'
import {
  FaAddressCard,
  FaBox,
  FaCar,
  FaChalkboard,
  FaClipboard,
  FaHome,
  FaInfoCircle,
  FaMapPin,
  FaMoneyBill,
  FaPalette,
  FaQuestionCircle,
} from 'react-icons/fa'
import CitiesPage from '../pages/cities'
import AddressesPage from '../pages/addresses'
import BannersPage from '../pages/banners'
import TagsPage from '../pages/tags'
import BrandsPage from '../pages/brands'
import CategoriesPage from '../pages/categories'
import ConfigsPage from '../pages/configs'
import CarColorssPage from '../pages/car-colors'
import ClientsPage from '../pages/clients'
import FAQsPage from '../pages/faqs/index'
import ProductsPage from '../pages/products'
import PromoCodesPage from '../pages/promo-codes'
import ServicesPage from '../pages/services'
import StaffTypesPage from '../pages/staff-types'
import CarsPage from '../pages/cars'
import StaffsPage from '../pages/staffs'
import OrdersPage from '../pages/orders'
import AttendancesPage from '../pages/attendances'
import RequestsPage from '../pages/requests/index'
import PermanentLocationsPage from '../pages/permanent-locations'
import TransactionAdminToStaffPage from '../pages/transaction-admin-staff'
import TransactionStaffToAdminPage from '../pages/transaction-staff-to-admin'
import SubscriptionPage from '../pages/subscription'
import NotificationsPage from '../pages/notifications'
import ClientSubscriptionsPage from '../pages/client-subscription'
import PlateCityPage from '../pages/plate-cities'

const HomePage = lazy(() => import('../pages/home'))
const LoginPage = lazy(() => import('../pages/login'))
const CountriesPage = lazy(() => import('../pages/countries'))

export interface ISubRoute {
  path: Path
  component: ComponentType<any>
  permissions?: Permission[]

  labelKey: string
  icon?: JSX.Element
  exact?: boolean
}
export interface IRoute {
  path: Path
  component: any
  permissions?: Permission[]

  labelKey: string
  icon?: JSX.Element
  exact?: boolean

  hasSubMenus: boolean
  subMenus?: ISubRoute[]
}

export const authRoutes: IRoute[] = [
  {
    path: '/auth/login',
    labelKey: 'login',
    component: LoginPage,
    hasSubMenus: false,
  },
]

export const dashboardRoutes: IRoute[] = [
  {
    path: '/',
    labelKey: 'home',
    component: HomePage,
    exact: true,
    icon: <FaHome />,
    hasSubMenus: false,
    permissions: [],
  },

  {
    path: '/locations',
    labelKey: 'locations',
    component: <></>,
    exact: true,
    icon: <FiMap />,
    hasSubMenus: true,
    subMenus: [
      {
        path: '/countries',
        labelKey: 'countries',
        component: CountriesPage,
        exact: true,
        icon: <FiFlag />,
        permissions: [],
      },
      {
        path: '/cities',
        labelKey: 'cities',
        component: CitiesPage,
        exact: true,
        icon: <FiMenu />,
        permissions: [],
      },
      {
        path: '/permanent-locations',
        labelKey: 'permanent_locations',
        component: PermanentLocationsPage,
        exact: true,
        icon: <FaMapPin />,
        permissions: [],
      },
      {
        path: '/addresses',
        labelKey: 'addresses',
        component: AddressesPage,
        exact: true,
        icon: <FiMapPin />,
        permissions: [],
      },
      {
        path: '/plate-cities',
        labelKey: 'plate_cities',
        component: PlateCityPage,
        exact: true,
        icon: <FaChalkboard />,
        permissions: [],
      },
    ],
  },

  {
    path: '/manage-staff',
    labelKey: 'staff_management',
    component: <></>,
    exact: true,
    icon: <FiUser />,
    hasSubMenus: true,
    subMenus: [
      {
        path: '/staff',
        labelKey: 'staff',
        component: StaffsPage,
        exact: true,
        icon: <FiUser />,
        permissions: [],
      },
      {
        path: '/attendances',
        labelKey: 'attendances',
        component: AttendancesPage,
        exact: true,
        icon: <FiPaperclip />,
        permissions: [],
      },
      {
        path: '/transactions-admin-to-staff',
        labelKey: 'transactions_admin_to_staff',
        component: TransactionAdminToStaffPage,
        exact: true,
        icon: <FaMoneyBill />,
        permissions: [],
      },
      {
        path: '/transactions-staff-to-admin',
        labelKey: 'transactions_staff_to_admin',
        component: TransactionStaffToAdminPage,
        exact: true,
        icon: <FaMoneyBill />,
        permissions: [],
      },
      {
        path: '/staff-types',
        labelKey: 'staff_types',
        component: StaffTypesPage,
        exact: true,
        icon: <FiUser />,
        permissions: [],
      },
    ],
  },

  {
    path: '/cars',
    labelKey: 'cars',
    component: <></>,
    exact: true,
    icon: <FaCar />,
    hasSubMenus: true,
    subMenus: [
      {
        path: '/brands',
        labelKey: 'brands',
        component: BrandsPage,
        exact: true,
        icon: <FiTag />,
        permissions: [],
      },

      {
        path: '/cars-colors',
        labelKey: 'cars_colors',
        component: CarColorssPage,
        exact: true,
        icon: <FaPalette />,
        permissions: [],
      },
    ],
  },

  {
    path: '/configs',
    labelKey: 'configs',
    component: ConfigsPage,
    exact: true,
    icon: <FiSettings />,
    hasSubMenus: false,
    permissions: [],
  },

  {
    path: '/notifications',
    labelKey: 'notifications',
    component: NotificationsPage,
    exact: true,
    icon: <FiBell />,
    hasSubMenus: false,
    permissions: [],
  },

  {
    path: '/users',
    labelKey: 'users',
    component: UsersPage,
    exact: true,
    icon: <DashboardOutlined />,
    hasSubMenus: false,
    permissions: [],
  },

  {
    path: '/products-manage',
    labelKey: 'products_manage',
    component: <></>,
    exact: true,
    icon: <FaBox />,
    hasSubMenus: true,
    subMenus: [
      {
        path: '/requests',
        labelKey: 'requests',
        component: RequestsPage,
        exact: true,
        icon: <FaClipboard />,
        permissions: [],
      },
      {
        path: '/products',
        labelKey: 'products',
        component: ProductsPage,
        exact: true,
        icon: <FiBox />,
        permissions: [],
      },
    ],
  },

  {
    path: '/general-info',
    labelKey: 'general_info',
    component: <></>,
    exact: true,
    icon: <FaInfoCircle />,
    hasSubMenus: true,
    subMenus: [
      {
        path: '/tags',
        labelKey: 'tags',
        component: TagsPage,
        exact: true,
        icon: <FiTag />,
        permissions: [],
      },
      {
        path: '/faqs',
        labelKey: 'faqs',
        component: FAQsPage,
        exact: true,
        icon: <FaQuestionCircle />,
        permissions: [],
      },
      {
        path: '/banners',
        labelKey: 'banners',
        component: BannersPage,
        exact: true,
        icon: <FiPaperclip />,
        permissions: [],
      },
    ],
  },
  {
    path: '/features',
    labelKey: 'features',
    component: <></>,
    exact: true,
    icon: <FiGrid />,
    hasSubMenus: true,
    subMenus: [
      {
        path: '/categories',
        labelKey: 'categories',
        component: CategoriesPage,
        exact: true,
        icon: <FiGrid />,
        permissions: [],
      },
      {
        path: '/services',
        labelKey: 'services',
        component: ServicesPage,
        exact: true,
        icon: <DashboardOutlined />,
        permissions: [],
      },
      {
        path: '/subscriptions',
        labelKey: 'subscriptions',
        component: SubscriptionPage,
        exact: true,
        icon: <FaAddressCard />,
        permissions: [],
      },

      {
        path: '/promo-codes',
        labelKey: 'promo-codes',
        component: PromoCodesPage,
        exact: true,
        icon: <FiPercent />,
        permissions: [],
      },
    ],
  },

  {
    path: '/clients_management',
    labelKey: 'clients_management',
    component: <></>,
    exact: true,
    icon: <FiUsers />,
    hasSubMenus: true,
    subMenus: [
      {
        path: '/clients',
        labelKey: 'clients',
        component: ClientsPage,
        exact: true,
        icon: <FiUsers />,
        permissions: [],
      },
      {
        path: '/orders',
        labelKey: 'orders',
        component: OrdersPage,
        exact: true,
        icon: <FiShoppingBag />,
        permissions: [],
      },
      {
        path: '/cars',
        labelKey: 'client_cars',
        component: CarsPage,
        exact: true,
        icon: <FaCar />,
        permissions: [],
      },
      {
        path: '/client-subscriptions',
        labelKey: 'client_subscriptions',
        component: ClientSubscriptionsPage,
        exact: true,
        icon: <FaAddressCard />,
        permissions: [],
      },
    ],
  },
]

export const renderRoute = (
  route: IRoute,
  userPermissions?: Permission[]
): any => {
  return (
    <DomRoute
      key={route.path}
      path={route.path}
      render={(props) => {
        if (
          !route.permissions ||
          check(userPermissions ?? [], route.permissions)
        ) {
          return <route.component {...props} route={route} />
        }

        return <Error403 />
      }}
      exact={route.exact}
    />
  )
}
