import { createContext } from "react"
import { ITransactionAdminToStaffQuery } from "../../models/transaction-admin-staff/query"
import {
  ICreateTransactionAdminToStaff,
  IUpdateTransactionAdminToStaff,
} from "../../models/transaction-admin-staff/request"
import {
  ITransactionAdminToStaff,
  ITransactionAdminToStaffDetails,
} from "../../models/transaction-admin-staff/response"
import IBaseListingResponse from "../../utils/api/base-listing-response"
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants"

export type TransactionAdminToStaffLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete"

export interface IInternalState {
  loading: TransactionAdminToStaffLoading[]

  list?: IBaseListingResponse<ITransactionAdminToStaff>
  query: ITransactionAdminToStaffQuery

  details?: ITransactionAdminToStaffDetails
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: ITransactionAdminToStaffDetails) => void

    createTransactionAdminToStaff: (
      request: ICreateTransactionAdminToStaff
    ) => void
    updateTransactionAdminToStaff: (
      id: number,
      request: IUpdateTransactionAdminToStaff
    ) => void
    deleteTransactionAdminToStaff: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: ITransactionAdminToStaffQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createTransactionAdminToStaff: DEFAULT_FUNCTION,
    updateTransactionAdminToStaff: DEFAULT_FUNCTION,
    deleteTransactionAdminToStaff: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const TransactionAdminToStaffContext = createContext(externalState)

export default TransactionAdminToStaffContext
