export enum MediumType {
  Image = 'image',
  PDF = 'pdf',
}

export enum MediumFor {
  BANNER_IMAGE = 'banner-image',
  COUNTRY_FLAG_IMAGE = 'country-flag-image',
  CITY_PLATE_IMAGE = 'city-plate-image',

  MAIN_PROJECT_IMAGE = 'main-project-image',

  CATEGORY_ACTIVE_IMAGE = 'category-active-image',
  CATEGORY_INACTIVE_IMAGE = 'category-inactive-image',
  SERVICE_INACTIVE_IMAGE = 'service-inactive-image',
  SERVICE_ACTIVE_IMAGE = 'service-active-image',

  STAFF_IMAGE = 'staff-image',

  BRAND_IMAGE = 'brand-image',
  CAR_IMAGE = 'car-image',

  SUBSCRIPTION_IMAGE = 'subscription-image',

  ORDER_BEFORE_IMAGE = 'order-before-image',
  ORDER_AFTER_IMAGE = 'order-after-image',

  PRODUCT_IMAGE = 'product-image',
  PLATE_CITY_IMAGE = 'city-plate-image',
}
