import { createContext } from 'react'
import { IClientSubscriptionQuery } from '../../models/client-subscription/query'
import { IClientSubscription } from '../../models/client-subscription/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type ClientSubscriptionLoading =
  | 'list'
  | 'details'
  | 'create'
  | 'update'
  | 'delete'

export interface IInternalState {
  loading: ClientSubscriptionLoading[]

  list?: IBaseListingResponse<IClientSubscription>
  query: IClientSubscriptionQuery

  details?: IClientSubscription
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IClientSubscription) => void

    // deleteFAQ: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IClientSubscriptionQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    // deleteFAQ: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const ClientSubscriptionContext = createContext(externalState)

export default ClientSubscriptionContext
