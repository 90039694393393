import { HttpClient, RequestParams } from '../../utils/api/http-client'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { INotificationQuery } from '../../models/notifications/query'
import { INotifications } from '../../models/notifications/response'
import { ICreatePublicNotification } from '../../models/notifications/request'

class NotificationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllNotifications = (query: INotificationQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INotifications>>({
      path: `/dashboard/notifications/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  getUserNotifications = (query: INotificationQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INotifications>>({
      path: `/dashboard/notifications/my-notifications`,
      method: 'POST',
      body: query,
      ...params,
    })

  readNotification = (id: string, params?: RequestParams) =>
    this.http.request<INotifications>({
      path: `/dashboard/notifications/${id}/read`,
      method: 'PUT',
      ...params,
    })

  createPublicNotification = (
    data: ICreatePublicNotification,
    params?: RequestParams
  ) =>
    this.http.request<INotifications>({
      path: '/dashboard/notifications/send-public-notification',
      method: 'POST',
      body: data,
      ...params,
    })
}

export default NotificationService
