import { Switch } from 'react-router-dom'
import { authRoutes, renderRoute } from './routes'
import AuthLayout from '../components/layouts/auth-layout'

interface Props {}
const AuthRoutes: React.FC<Props> = () => {
  return (
    <AuthLayout>
      <Switch>
        {authRoutes?.map((r) => {
          return renderRoute(r)
        })}
      </Switch>
    </AuthLayout>
  )
}

export default AuthRoutes
