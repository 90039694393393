import { useTranslation } from 'react-i18next'
import { IApproveRequest } from '../../../models/request/request'
import FormModal from '../../form-components/form-modal'
import RequestApproveForm from '../request-approve-form'

interface IProps {
  loading: boolean
  visable: boolean
  hideModal: () => void
  onSubmit: (data: IApproveRequest) => void
}

const ApproveRequestFormModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  return (
    <FormModal
      title={t('approve_request')}
      formId='approve-request'
      onSubmit={props.onSubmit}
      hideModal={props.hideModal}
      confirmLoading={props.loading}
      visible={props.visable}
    >
      <RequestApproveForm />
    </FormModal>
  )
}

export default ApproveRequestFormModal
