/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useReducer } from 'react'
import reducer from './reducer'
import ServiceContext, { internalState } from './context'
import { IServiceQuery } from '../../../models/service/query'
import EndPoints from '../../../services/end-points'
import { execute } from '../../../utils/api/api-execute'
import AuthContext from '../../auth/context'

const ServiceSelectOptionsContextProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState)

  const { isAuthenticated } = useContext(AuthContext)
  /**
   * Get data when query changed
   */
  useEffect(() => {
    isAuthenticated && getData()
  }, [state.query])

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'list' } })

        const { data } = await EndPoints.service.getAllServices(state.query)

        dispatch({ type: 'SET_LIST', payload: { list: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'list' } })
      },
      throwException: false,
    })
  }

  const setSearch = (search?: string) => {
    dispatch({ type: 'SET_SEARCH', payload: { search } })
  }

  const setQuery = (query: IServiceQuery) => {
    dispatch({ type: 'SET_QUERY', payload: { query } })
  }

  return (
    <ServiceContext.Provider
      value={{
        ...state,
        actions: {
          getData,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </ServiceContext.Provider>
  )
}

export default ServiceSelectOptionsContextProvider
