import { Descriptions as AntdDescriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { StaffStatus } from '../../../models/staff/enum'
import { ITransactionAdminToStaff } from '../../../models/transaction-admin-staff/response'
import Descriptions from '../../general/antd/descriptions'
import Image from '../../general/antd/image/index'

interface Props {
  transactionAdminToStaff?: ITransactionAdminToStaff
}

const TransactionAdminToStaffDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.transactionAdminToStaff?.id}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('name')}>
        {`${props.transactionAdminToStaff?.staff.user.firstName} ${props.transactionAdminToStaff?.staff.user.lastName}`}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('deposit_date')}>
        {props.transactionAdminToStaff?.depositDate}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('deposit_amount')}>
        {props.transactionAdminToStaff?.depositAmount}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('status')}>
        {props?.transactionAdminToStaff?.status &&
          t(`${StaffStatus[props?.transactionAdminToStaff?.status]}`)}
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default TransactionAdminToStaffDetails
