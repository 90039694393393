import { IAddressQuery } from '../../models/address/query'
import { ICreateAddress, IUpdateAddress } from '../../models/address/request'
import { IAddress } from '../../models/address/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class AddressService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllAddresses = (query: IAddressQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IAddress>>({
      path: `/dashboard/addresses/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createAddress = (data: ICreateAddress, params?: RequestParams) =>
    this.http.request<IAddress>({
      path: '/dashboard/addresses',
      method: 'POST',
      body: data,
      ...params,
    })

  updateAddress = (id: number, data: IUpdateAddress, params?: RequestParams) =>
    this.http.request<IAddress>({
      path: `/dashboard/addresses/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteAddress = (id: number, params?: RequestParams) =>
    this.http.request<IAddress>({
      path: `/dashboard/addresses/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default AddressService
