import { useTranslation } from "react-i18next";
import StaffSelectOptionsContextProvider from "../../../../contexts/select-options/staff-select-options/provider";
import FormModal from "../../../form-components/form-modal";
import TemplateExportExcel from "../../../general/expor-excel";
import {
  IExportOrderExcel,
  colomnsExportOrderExcel,
} from "../../../../models/order/request";
import {
  IExportAttedanceExcel,
  colomnsExportAttedanceExcel,
} from "../../../../models/attendance/request";

interface IProps {
  loading: boolean;
  visable: boolean;
  width?: string;
  hideModal: () => void;
  onSubmit: (data: any) => void;
}

const ExcelAttedenceFormModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <StaffSelectOptionsContextProvider>
      <FormModal
        defaultValues={{} as IExportAttedanceExcel}
        title={t("export_to_excel")}
        formId="excel_order"
        style={{ marginTop: "-6rem" }}
        width={props?.width ?? "70vw"}
        onSubmit={props.onSubmit}
        hideModal={props.hideModal}
        confirmLoading={props.loading}
        visible={props.visable}
      >
        <TemplateExportExcel names={colomnsExportAttedanceExcel} />
      </FormModal>
    </StaffSelectOptionsContextProvider>
  );
};

export default ExcelAttedenceFormModal;
