export enum NotificationStatus {
  UNPUBLISHED = 1,
  UN_READ = 2,
  READ = 3,
}

export enum NotificationType {
  NEW_ORDER = 1,
}

// Status Orders Colors
export const getNotificationStatusColor = (status: NotificationStatus) => {
  switch (status) {
    case NotificationStatus.READ:
      return 'green'
    case NotificationStatus.UN_READ:
      return 'red'
    case NotificationStatus.UNPUBLISHED:
      return 'cyan'
    default:
      return 'gray'
  }
}
