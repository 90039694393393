import { IBrandQuery } from '../../models/brand/query'
import { ICreateBrand, IUpdateBrand } from '../../models/brand/request'
import { IBrand } from '../../models/brand/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class BrandService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllBrands = (query: IBrandQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IBrand>>({
      path: `/dashboard/brands/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createBrand = (data: ICreateBrand, params?: RequestParams) =>
    this.http.request<IBrand>({
      path: '/dashboard/brands',
      method: 'POST',
      body: data,
      ...params,
    })

  updateBrand = (id: number, data: IUpdateBrand, params?: RequestParams) =>
    this.http.request<IBrand>({
      path: `/dashboard/brands/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteBrand = (id: number, params?: RequestParams) =>
    this.http.request<IBrand>({
      path: `/dashboard/brands/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default BrandService
