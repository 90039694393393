import React, { useEffect, useRef, useState } from 'react'
import ReactMapboxGl, { Popup, MapContext, Marker } from 'react-mapbox-gl'
import {
  GeolocateControl,
  NavigationControl,
  FullscreenControl,
  setRTLTextPlugin,
} from 'mapbox-gl'
import { DEFAULT_MAP_CENTER } from '../../../utils/helpers/constants'

// MapBox
const accessToken =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ??
  'pk.eyJ1Ijoia2luYW4tYWxtb2FsbSIsImEiOiJja3hnYnh0MGgyYXVlMm9rdGs2emloNzBuIn0.B0FJEO4-tN2o9QvU7rRmWg'

const Map = ReactMapboxGl({
  accessToken,
})

interface IProps {
  hideNavigation?: boolean
  hideGeolocate?: boolean
  hideFullScreen?: boolean
  pickerCoordinates?: [number, number]
  onChange?: (center: { lat: number; lng: number }) => void
}

const MapBox: React.FC<IProps> = (props) => {
  // Zoom
  const [zoom, setZoom] = useState<[number]>([12])
  // mapCenetr
  const [mapCenter, setMapCenter] = useState<[number, number]>(
    props?.pickerCoordinates || [DEFAULT_MAP_CENTER.lng, DEFAULT_MAP_CENTER.lat]
  )

  useEffect(() => {
    props?.pickerCoordinates && setMapCenter(props?.pickerCoordinates)
  }, [])

  const mapLoaded = (map: any) => {
    map.resize()

    const geolocate = new GeolocateControl()
    const navigation = new NavigationControl()
    const fullScreen = new FullscreenControl()

    // Display / Hide Geolocate
    !props.hideGeolocate && map.addControl(geolocate)

    // Display / Hide Navigation
    !props?.hideNavigation && map.addControl(navigation)

    // Display / Hide FullScreen
    !props?.hideFullScreen && map.addControl(fullScreen)
  }

  //! RTL
  // useEffect(() => {
  //   setRTLTextPlugin(
  //     'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
  //     () => {},
  //     true
  //   )
  // }, [])

  useEffect(() => {
    props?.onChange &&
      props.onChange({
        lng: mapCenter?.[0],
        lat: mapCenter?.[1],
      })
  }, [mapCenter])

  return (
    <Map
      style='mapbox://styles/kinan-almoalm/ckxm6phjlnck715nskzxb1wv4'
      containerStyle={{
        height: '100%',
      }}
      center={mapCenter}
      zoom={zoom}
      onZoomEnd={(map) => {
        setZoom([map?.getZoom()])
      }}
      onMove={(map) => {
        setMapCenter([map.getCenter().lng, map.getCenter().lat])
      }}
      onStyleLoad={(map) => {
        return mapLoaded(map)
      }}
      onRender={(map) => {}}
      onClick={(event) => {}}
    >
      <Marker onClick={(e) => {}} coordinates={mapCenter} anchor='bottom'>
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/map-pin.png`}
          alt='pin'
          height={36}
        />
      </Marker>
    </Map>
  )
}

export default MapBox
