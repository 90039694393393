import { Descriptions as AntdDescriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { IPlateCity } from '../../../models/plate-cities/response'
import Descriptions from '../../general/antd/descriptions'
import Image from '../../general/antd/image'

interface Props {
  plateCity?: IPlateCity
}

const PlateCityDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.plateCity?.id}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('name')}>
        {props.plateCity?.name}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('image')}>
        <Image preview src={props.plateCity?.image?.url} />
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default PlateCityDetails
