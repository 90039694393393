import { Col, Row } from "antd";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FieldBuilder from "../../form-components/field-builder";
import AttendanceContext from "../../../contexts/attendance/context";
import FilterCard from "../../general/filter-card";
import Form from "../../form-components/from";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";

import styles from "./style.module.scss";
import CitySelectOptionsContext from "../../../contexts/select-options/city-select-options/context";
import StaffSelectOptionsContext from "../../../contexts/select-options/staff-select-options/context";
import { AttendanceStatus } from "../../../models/attendance/enum";

interface IProps {}

const AttendanceFilters: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, loading, query } = useContext(AttendanceContext);

  const {
    list: staffs,
    actions: staffActions,
    loading: staffLoading,
  } = useContext(StaffSelectOptionsContext);

  useEffect(() => {
    staffActions.getData();
  }, []);

  return (
    <div className={styles.formContainer}>
      <Form
        formId="attendances-filter"
        onSubmit={(data) => {
          const filter: any = {
            page: INITIAL_PAGE,
            perPage: query?.perPage,
            keyword: data?.keyword,
            staticFilters: [
              data?.staffs?.length > 0
                ? {
                    name: "createdBy",
                    value: [...(data?.staffs ?? [])],
                    // == != > < >= <= in
                    operation: "in",
                  }
                : undefined,
              data?.checkInStatus?.length > 0
                ? {
                    name: "checkInStatus",
                    value: [...(data?.checkInStatus ?? [])],
                    // == != > < >= <= in
                    operation: "in",
                  }
                : undefined,
              data?.checkOutStatus?.length > 0
                ? {
                    name: "checkOutStatus",
                    value: [...(data?.checkOutStatus ?? [])],
                    // == != > < >= <= in
                    operation: "in",
                  }
                : undefined,
              data.attendanceDateBetween !== undefined &&
              data.attendanceDateBetween[0] !== "" &&
              data.attendanceDateBetween[1] !== ""
                ? {
                    name: "attendanceDateBetween",
                    operation: "between",
                    value: data?.attendanceDateBetween,
                  }
                : undefined,
            ].filter((f) => f),
          };
          actions.setQuery(filter);
        }}
      >
        <FilterCard
          formId="attendances-filter"
          applyLoading={loading.includes("list")}
          resetLoading={loading.includes("list")}
          onReset={() => {
            actions.setQuery({
              page: query?.page,
              perPage: query?.perPage,
            });
          }}
        >
          <Row justify={"start"} gutter={[10, 0]} className={styles.filters}>
            <Col span={8}>
              <FieldBuilder
                name="keyword"
                label={t("search")}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="staffs"
                label={t("staffs")}
                input={{
                  type: "multiSelect",
                  loading: staffLoading.includes("list"),
                  allowSearch: true,

                  options: staffs?.data.map((staff) => {
                    return {
                      value: staff.user.id,
                      label: `${staff.user.firstName} ${staff.user.lastName}`,
                    };
                  }),
                }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                name="checkInStatus"
                label={t("check_in_status")}
                input={{
                  type: "multiSelect",
                  options: [
                    {
                      value: AttendanceStatus.NORMAL,
                      label: t(`${AttendanceStatus[AttendanceStatus.NORMAL]}`),
                    },
                    {
                      value: AttendanceStatus.EARLY,
                      label: t(`${AttendanceStatus[AttendanceStatus.EARLY]}`),
                    },
                    {
                      value: AttendanceStatus.LATE,
                      label: t(`${AttendanceStatus[AttendanceStatus.LATE]}`),
                    },
                  ],
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="checkOutStatus"
                label={t("check_out_status")}
                input={{
                  type: "multiSelect",
                  options: [
                    {
                      value: AttendanceStatus.NORMAL,
                      label: t(`${AttendanceStatus[AttendanceStatus.NORMAL]}`),
                    },
                    {
                      value: AttendanceStatus.EARLY,
                      label: t(`${AttendanceStatus[AttendanceStatus.EARLY]}`),
                    },
                    {
                      value: AttendanceStatus.LATE,
                      label: t(`${AttendanceStatus[AttendanceStatus.LATE]}`),
                    },
                  ],
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="attendanceDateBetween"
                label={`${t("createdAt")}`}
                input={{ type: "range-picker" }}
                rules={{ required: false }}
              />
            </Col>
          </Row>
        </FilterCard>
      </Form>
    </div>
  );
};

export default AttendanceFilters;
