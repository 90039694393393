import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import eventManager, { EVENT_SUCCESS } from '../../utils/events'
import PageHeader from '../../components/general/antd/page-header'
import CreateBtn from '../../components/table-components/actions/create-btn'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import StaffTable from '../../components/staff/staff-table'
import InfoDrawer from '../../components/general/info-drawer'
import TransactionAdminToStaffContext from '../../contexts/transaction-admin-staff/context'
import TransactionAdminToStaffContextProvider from '../../contexts/transaction-admin-staff/provider'
import TransactionAdminToStaffTable from '../../components/transaction-admin-staff/transaction-admin-staff-table'
import TransactionAdminToStaffDetails from '../../components/transaction-admin-staff/transaction-admin-staff-details'
import TransactionAdminToStaffFilters from '../../components/transaction-admin-staff/transaction-admin-staff-filters'
import TransactionAdminToStaffForm from '../../components/transaction-admin-staff/transaction-admin-staff-form'
import TransactionAdminToStaffFormDrawer from '../../components/transaction-admin-staff/transaction-admin-staff-form-drawer'
import StaffSelectOptionsContextProvider from '../../contexts/select-options/staff-select-options/provider'

interface Props {
  route: IRoute
}

const TransactionsAdminToStaff: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(
    TransactionAdminToStaffContext
  )

  const onSubmit = async (data: any) => {
    details
      ? await actions.updateTransactionAdminToStaff(details.id, data)
      : await actions.createTransactionAdminToStaff(data)
    eventManager.emit(EVENT_SUCCESS)
  }

  const [formDrawerVisable, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <CreateBtn
              onClick={() => {
                actions.setDetails(undefined)
                setFormDrawerVisable(true)
              }}
            />
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />
      <StaffSelectOptionsContextProvider>
        <TransactionAdminToStaffFilters />
      </StaffSelectOptionsContextProvider>

      <TransactionAdminToStaffTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <TransactionAdminToStaffFormDrawer
        formId={'transaction-admin-to-staff-form'}
        visable={formDrawerVisable}
        defaultValues={
          details && {
            depositAmount: details?.depositAmount,
            staffId: details?.staff?.id,
          }
        }
        details={details}
        loading={loading.includes('create') || loading.includes('update')}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      />

      <InfoDrawer
        title={t('staff_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <TransactionAdminToStaffDetails transactionAdminToStaff={details} />
      </InfoDrawer>
    </>
  )
}

const TransactionAdminToStaffPage: React.FC<Props> = (props) => {
  return (
    <TransactionAdminToStaffContextProvider>
      <TransactionsAdminToStaff {...props} />
    </TransactionAdminToStaffContextProvider>
  )
}
export default TransactionAdminToStaffPage
