import React, { useContext, useEffect } from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'

import { PromoCodeStatus } from '../../../models/promo-code/enum'
import StaffSelectOptionsContext from '../../../contexts/select-options/staff-select-options/context'

interface IProps {}

const TransactionAdminToStaffForm: React.FC<IProps> = () => {
  const { t } = useTranslation()

  const { actions, loading, list } = useContext(StaffSelectOptionsContext)

  useEffect(() => {
    actions.getData()
  }, [])

  return (
    <div>
      <FieldBuilder
        name='staffId'
        label={t('staff')}
        input={{
          type: 'select',
          options: list?.data?.map((staff) => {
            return {
              value: staff.id,
              label: `${staff.user?.firstName} ${staff.user?.lastName}`,
            }
          }),
          allowSearch: true,
          loading: loading.includes('list'),
        }}
        rules={{ required: true }}
      />

      <FieldBuilder
        name='depositAmount'
        label={t('deposit_amount')}
        input={{ type: 'number' }}
        rules={{ required: true }}
      />
    </div>
  )
}

export default TransactionAdminToStaffForm
