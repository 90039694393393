/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useReducer } from "react";
import reducer from "./reducer";
import OrderContext, { internalState } from "./context";
import { execute } from "../../utils/api/api-execute";
import EndPoints from "../../services/end-points";
import { IOrder } from "../../models/order/response";
import { IOrderQuery } from "../../models/order/query";
import AuthContext from "../auth/context";
import { IApproveOrder, IRejectOrder } from "../../models/order/request";
import moment from "moment";

const OrderContextProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const { isAuthenticated } = useContext(AuthContext);
  /**
   * Get data when query changed
   */
  useEffect(() => {
    isAuthenticated && getData();
  }, [state.query, isAuthenticated]);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.order.getAllOrders(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const data = state.list?.data?.find((u) => u?.id === id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IOrder) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };
  const approveOrder = async (id: number, request: IApproveOrder) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.order.approveOrder(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };
  const rejectOrder = async (id: number, request: IRejectOrder) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.order.rejectOrder(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteOrder = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.order.deleteOrder(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.order.exportExcel(request, state.query);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Order-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IOrderQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <OrderContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          approveOrder,
          rejectOrder,
          deleteOrder,

          exportExcel,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

export default OrderContextProvider;
