import React, { useContext, useEffect } from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { BannerStatus } from '../../../models/banner/enum'
import SubscriptionSelectOptionsContext from '../../../contexts/select-options/subscription-select-options/context'

interface IProps {}

const BannerForm: React.FC<IProps> = () => {
  const { t } = useTranslation()
  const { list, actions, loading } = useContext(
    SubscriptionSelectOptionsContext
  )

  useEffect(() => {
    actions.getData()
  }, [])

  return (
    <div>
      {/* Subscription */}
      <FieldBuilder
        name='subscriptionId'
        label={t('subscription')}
        rules={{ required: true }}
        input={{
          type: 'select',
          allowSearch: true,
          loading: loading.includes('list'),
          options: list?.data?.map((subscription) => {
            return { value: subscription.id, label: subscription.mainName }
          }),
        }}
      />

      {/* Status */}
      <FieldBuilder
        name='status'
        label={t('status')}
        rules={{ required: true }}
        input={{
          type: 'select',
          options: [
            {
              value: BannerStatus.ACTIVE,
              label: t(`${BannerStatus[BannerStatus.ACTIVE]}`),
            },
            {
              value: BannerStatus.INACTIVE,
              label: t(`${BannerStatus[BannerStatus.INACTIVE]}`),
            },
          ],
        }}
      />
    </div>
  )
}

export default BannerForm
