import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import Space from '../../general/antd/space'
import EditBtn from '../../table-components/actions/edit-btn'
import ViewBtn from '../../table-components/actions/view-btn'
import Table from '../../table-components/table'
import DeleteBtn from '../../table-components/actions/delete-btn'
import eventManager, { EVENT_SUCCESS } from '../../../utils/events'
import { getStaffStatusColor, StaffStatus } from '../../../models/staff/enum'
import { Tag } from 'antd'
import { ITransactionAdminToStaff } from '../../../models/transaction-admin-staff/response'
import TransactionAdminToStaffContext from '../../../contexts/transaction-admin-staff/context'
import moment from 'moment'
import {
  getTransactionAdminToStaffStatusColor,
  TransactionAdminToStaffStatus,
} from '../../../models/transaction-admin-staff/enum'
import { ISort, IStaticFilter } from '../../../models/base/base-query'
import { tableOnChange } from '../../../utils/helpers/table-sorts-filters'

interface Props {
  showFormDrawer: () => void
  showDetailsDrawer: () => void
}

const TransactionAdminToStaffTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { list, actions, loading, query } = useContext(
    TransactionAdminToStaffContext
  )

  const columns: ColumnProps<ITransactionAdminToStaff>[] = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      sorter: {
        multiple: 1,
      },
    },
    {
      key: 'staff',
      title: t('staff'),
      dataIndex: 'staff',
      render: (_, record) => {
        return `${record?.staff?.user?.firstName} ${record?.staff?.user?.lastName}`
      },
      sorter: {
        multiple: 2,
      },
    },
    {
      key: 'depositDate',
      title: t('deposit_date'),
      dataIndex: 'depositDate',
      align: 'center',
      render: (_, record) => {
        return moment(record.depositDate).format('LLL')
      },
      sorter: {
        multiple: 3,
      },
    },
    {
      key: 'depositAmount',
      title: t('deposit_amount'),
      dataIndex: 'depositAmount',
      align: 'center',
      render: (_, record) => {
        return record.depositAmount
      },
      sorter: {
        multiple: 4,
      },
    },

    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      align: 'center',
      render: (status: TransactionAdminToStaffStatus) => {
        return (
          status && (
            <Tag
              color={getTransactionAdminToStaffStatusColor(status)}
              style={{ textAlign: 'center' }}
            >
              {t(`${TransactionAdminToStaffStatus[status]}`)}
            </Tag>
          )
        )
      },
    },

    {
      key: 'actions',
      title: t('actions'),
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Space>
          {/* <ViewBtn
            onClick={() => {
              actions.setDetails(record)
              props.showDetailsDrawer()
            }}
          /> */}
          <EditBtn
            disabled={record.status === TransactionAdminToStaffStatus.CONFIRMED}
            onClick={() => {
              actions.setDetails(record)
              props.showFormDrawer()
            }}
          />
          <DeleteBtn
            disabled={record.status === TransactionAdminToStaffStatus.CONFIRMED}
            onConfirm={async () => {
              await actions.deleteTransactionAdminToStaff(record?.id)
              eventManager.emit(EVENT_SUCCESS)
            }}
            loading={loading.includes('delete')}
          />
        </Space>
      ),
    },
  ]

  // Filters && Sorts
  let tableFiltersProps = {}
  const [sorts, setSorts] = useState<ISort[]>([])
  const [, setStaticFilters] = useState<IStaticFilter[]>(
    query?.staticFilters ?? []
  )

  useEffect(() => {
    sorts &&
      actions.setQuery({
        ...query,
        sorts: sorts,
      })
  }, [sorts])

  return (
    <Table
      rowKey='id'
      columns={columns}
      dataSource={list?.data}
      loading={loading.includes('list')}
      showPagination
      total={list?.meta?.total}
      page={query.page}
      pageSize={query.perPage}
      scroll={{ x: 200 }}
      onPaginationChange={(page, pageSize) => {
        actions.setQuery({
          ...query,
          page,
          perPage: pageSize,
        })
      }}
      onChange={(_, tableFilters, tabelSorters) => {
        tableOnChange(
          tableFilters,
          tabelSorters,
          tableFiltersProps,
          setSorts,
          setStaticFilters
        )
      }}
    />
  )
}

export default TransactionAdminToStaffTable
