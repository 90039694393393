import { Descriptions as AntdDescriptions, Descriptions, Tag } from 'antd'
import { useTranslation } from 'react-i18next'

import { INotifications } from '../../../models/notifications/response'
import {
  getNotificationStatusColor,
  NotificationStatus,
  NotificationType,
} from '../../../models/notifications/enum'

interface Props {
  notification?: INotifications
}

const NotificationDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <>
      <Descriptions size='middle' column={1} bordered>
        <AntdDescriptions.Item label={t('title')}>
          {props.notification?.title}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('body')}>
          {props.notification?.body}
        </AntdDescriptions.Item>

        {props.notification?.type && (
          <AntdDescriptions.Item label={t('type')}>
            {
              <Tag color={'grey'}>
                {t(NotificationType[props.notification?.type])}
              </Tag>
            }
          </AntdDescriptions.Item>
        )}

        {props.notification?.status && (
          <AntdDescriptions.Item label={t('status')}>
            <Tag
              color={getNotificationStatusColor(props.notification?.status)}
              style={{ textAlign: 'center' }}
            >
              {t(`${NotificationStatus[props.notification?.status]}`)}
            </Tag>
          </AntdDescriptions.Item>
        )}
      </Descriptions>
    </>
  )
}

export default NotificationDetails
