import React, { useEffect, useState } from 'react'
import ReactMapboxGl, { Marker } from 'react-mapbox-gl'
import {
  GeolocateControl,
  NavigationControl,
  FullscreenControl,
} from 'mapbox-gl'
import mapboxgl from 'mapbox-gl'

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('mapbox-gl/dist/mapbox-gl-csp-worker').default

// MapBox
const accessToken =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ??
  'pk.eyJ1Ijoia2luYW4tYWxtb2FsbSIsImEiOiJja3hnYnh0MGgyYXVlMm9rdGs2emloNzBuIn0.B0FJEO4-tN2o9QvU7rRmWg'

const Map = ReactMapboxGl({
  accessToken,
})

interface IProps {
  hideNavigation?: boolean
  hideGeolocate?: boolean
  hideFullScreen?: boolean
  coordinates: [number, number]
}

const MapBox: React.FC<IProps> = (props) => {
  // Zoom
  const [zoom] = useState<[number]>([15])
  // mapCenetr
  const [mapCenter, setMapCenter] = useState<[number, number]>(
    props.coordinates
  )

  const mapLoaded = (map: any) => {
    map.resize()

    const geolocate = new GeolocateControl()
    const navigation = new NavigationControl()
    const fullScreen = new FullscreenControl()

    // Display / Hide Geolocate
    !props.hideGeolocate && map.addControl(geolocate)

    // Display / Hide Navigation
    !props?.hideNavigation && map.addControl(navigation)

    // Display / Hide FullScreen
    !props?.hideFullScreen && map.addControl(fullScreen)
  }

  //! RTL
  // useEffect(() => {
  //   setRTLTextPlugin(
  //     'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
  //     () => {},
  //     true
  //   )
  // }, [])

  useEffect(() => {
    props.coordinates && setMapCenter(props.coordinates)
  }, [props.coordinates])
  return (
    <Map
      style='mapbox://styles/kinan-almoalm/ckxm6phjlnck715nskzxb1wv4'
      containerStyle={{
        height: '100%',
      }}
      center={mapCenter}
      zoom={zoom}
      onStyleLoad={(map) => {
        return mapLoaded(map)
      }}
      onRender={() => {}}
      onClick={() => {}}
    >
      <Marker coordinates={props.coordinates} anchor='bottom'>
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/map-pin.png`}
          alt='pin'
          height={36}
        />
      </Marker>
    </Map>
  )
}

export default MapBox
