import { ITransactionStaffToAdminQuery } from '../../models/transaction-staff-to-admin/query'
import { ITransactionStaffToAdmin } from '../../models/transaction-staff-to-admin/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class TransactionStaffToAdminService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllTransactionsStaffToAdmin = (
    query: ITransactionStaffToAdminQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<ITransactionStaffToAdmin>>({
      path: `dashboard/staff-to-admin-transaction/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  deleteTransactionStaffToAdmin = (id: number, params?: RequestParams) =>
    this.http.request<ITransactionStaffToAdmin>({
      path: `/dashboard/staff-to-admin-transaction/${id}`,
      method: 'DELETE',
      ...params,
    })

  confirmTransactionStaffToAdmin = (id: number, params?: RequestParams) =>
    this.http.request<ITransactionStaffToAdmin>({
      path: `/dashboard/staff-to-admin-transaction/${id}/confirm-transaction`,
      method: 'PUT',
      ...params,
    })
}

export default TransactionStaffToAdminService
