import { ISubscriptionQuery } from '../../models/subscription/query'
import {
  ICreateSubscription,
  IUpdateSubscription,
} from '../../models/subscription/request'
import { ISubscription } from '../../models/subscription/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class SubscriptionService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllSubscriptions = (query: ISubscriptionQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ISubscription>>({
      path: `/dashboard/subscriptions/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createSubscription = (data: ICreateSubscription, params?: RequestParams) =>
    this.http.request<ISubscription>({
      path: '/dashboard/subscriptions',
      method: 'POST',
      body: data,
      ...params,
    })

  updateSubscription = (
    id: number,
    data: IUpdateSubscription,
    params?: RequestParams
  ) =>
    this.http.request<ISubscription>({
      path: `/dashboard/subscriptions/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteSubscription = (id: number, params?: RequestParams) =>
    this.http.request<ISubscription>({
      path: `/dashboard/subscriptions/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default SubscriptionService
