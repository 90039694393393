import { SyncOutlined } from '@ant-design/icons'
import Button, { ButtonProps } from '../../../general/antd/button'

interface Props extends ButtonProps {}

const RefreshBtn: React.FC<Props> = (props) => {
  return (
    <Button icon={<SyncOutlined />} ghost type='primary' {...props}>
      {props.children}
    </Button>
  )
}

export default RefreshBtn
