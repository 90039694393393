import {
  Descriptions as AntdDescriptions,
  DescriptionsProps as AntdDescriptionsProps,
} from 'antd'

export interface DescriptionsProps extends AntdDescriptionsProps {}

const Descriptions: React.FC<DescriptionsProps> = (props) => {
  return (
    <AntdDescriptions
      column={2}
      labelStyle={{ fontWeight: 'bold' }}
      {...props}
    />
  )
}

export default Descriptions
