import React, { useContext } from 'react'
import AuthContext from '../../contexts/auth/context'
import { isArray } from 'lodash'
import { Spin } from 'antd'
import Permission from './permissions'

interface Props {
  perform: Permission | Permission[]
  cannot?: () => JSX.Element
}

const Can: React.FC<Props> = ({ perform, cannot, children }) => {
  const { userPermissions: permissions } = useContext(AuthContext)

  if (!permissions) return <Spin spinning />

  if (check([...permissions], perform)) {
    return <>{children}</>
  } else {
    return cannot ? cannot() : null
  }
}

export default Can

export const check = (
  permissions: Permission[],
  action: Permission | Permission[]
) => {
  if (isArray(action)) {
    if (action.length === 0) return true
    if (permissions.find((p) => action.includes(p))) return true
  } else {
    if (permissions.find((p) => p === action)) return true
  }
  return false
}
