import React from 'react'
import { useContext } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import CarContext from '../../../contexts/car/context'
import { ICar } from '../../../models/car/response'
import Space from '../../general/antd/space'
import ViewBtn from '../../table-components/actions/view-btn'
import Table from '../../table-components/table'
import DeleteBtn from '../../table-components/actions/delete-btn'
import eventManager, { EVENT_SUCCESS } from '../../../utils/events'
import { IBrand } from '../../../models/brand/response'
import { ICity } from '../../../models/city/response'
import { IUser } from '../../../models/user/response'
import { Image } from 'antd'
import ColorCircle from '../../general/color-circle'
import { useState } from 'react'
import { ISort, IStaticFilter } from '../../../models/base/base-query'
import { useEffect } from 'react'
import { tableOnChange } from '../../../utils/helpers/table-sorts-filters'

interface Props {
  showFormDrawer: () => void
  showDetailsDrawer: () => void
}

const CarTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { list, actions, loading, query } = useContext(CarContext)

  const columns: ColumnProps<ICar>[] = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      sorter: {
        multiple: 1,
      },
    },
    {
      key: 'client',
      title: t('client'),
      dataIndex: 'createdBy',
      render: (createdBy: IUser) => {
        return createdBy && `${createdBy?.firstName} ${createdBy?.lastName}`
      },
      sorter: {
        multiple: 2,
      },
    },
    {
      key: 'brand',
      title: t('brand'),
      dataIndex: 'brand',
      render: (brand: IBrand, record) => {
        return `${brand?.parentBrand?.name} - ${brand?.name} (${record?.year})`
      },
    },
    {
      key: 'plate',
      title: t('plate'),
      render: (record: ICar) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <span style={{ marginInlineEnd: '4px' }}>{record.plateCode}</span>

            <Image
              src={record?.city?.plateImage?.url}
              height={'16px'}
              style={{
                objectFit: 'contain',
              }}
              preview={false}
            />
            <span style={{ marginInlineStart: '4px' }}>
              {record?.plateNumber}
            </span>
          </div>
        )
      },
    },
    {
      key: 'color',
      title: t('color'),
      render: (record: ICar) => {
        return record.carColor && <ColorCircle color={record?.carColor?.hex} />
      },
    },

    {
      key: 'actions',
      title: t('actions'),
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Space>
          <ViewBtn
            onClick={() => {
              actions.setDetails(record)
              props.showDetailsDrawer()
            }}
          />
          {/* <EditBtn
            onClick={() => {
              actions.setDetails(record)
              props.showFormDrawer()
            }}
          /> */}
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteCar(record?.id)
              eventManager.emit(EVENT_SUCCESS)
            }}
            loading={loading.includes('delete')}
          />
        </Space>
      ),
    },
  ]
  // Filters && Sorts
  let tableFiltersProps = {}
  const [sorts, setSorts] = useState<ISort[]>([])
  const [, setStaticFilters] = useState<IStaticFilter[]>(
    query?.staticFilters ?? []
  )

  useEffect(() => {
    sorts &&
      actions.setQuery({
        ...query,
        sorts: sorts,
      })
  }, [sorts])
  return (
    <Table
      rowKey='id'
      columns={columns}
      dataSource={list?.data}
      loading={loading.includes('list')}
      showPagination
      total={list?.meta?.total}
      page={query.page}
      pageSize={query.perPage}
      scroll={{ x: 200 }}
      onPaginationChange={(page, pageSize) => {
        actions.setQuery({
          ...query,
          page,
          perPage: pageSize,
        })
      }}
      onChange={(_, tableFilters, tabelSorters) => {
        tableOnChange(
          tableFilters,
          tabelSorters,
          tableFiltersProps,
          setSorts,
          setStaticFilters
        )
      }}
    />
  )
}

export default CarTable
