import { IStaffQuery } from '../../../models/staff/query'
import { IStaff } from '../../../models/staff/response'
import IBaseListingResponse from '../../../utils/api/base-listing-response'
import { toggleLoading } from '../../../utils/helpers/functions'
import { IInternalState, StaffLoading } from './context'
import paramsReducer, { QueryAction } from '../../base/base-reducer'

type Action =
  | { type: 'LOADING'; payload: { loading: StaffLoading | StaffLoading[] } }
  | { type: 'SET_LIST'; payload: { list: IBaseListingResponse<IStaff> } }
  | QueryAction<IStaffQuery>

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      }
    case 'SET_LIST':
      return {
        ...state,
        list: action.payload.list,
      }

    default:
      return paramsReducer(state, action)
  }
}

export default reducer
