import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import Space from '../../general/antd/space'
import Table from '../../table-components/table'
import DeleteBtn from '../../table-components/actions/delete-btn'
import eventManager, { EVENT_SUCCESS } from '../../../utils/events'
import { Tag } from 'antd'
import TransactionStaffToAdminContext from '../../../contexts/transaction-staff-to-admin/context'
import { ITransactionStaffToAdmin } from '../../../models/transaction-staff-to-admin/response'
import ConfirmBtn from '../../table-components/actions/confirm-btn'
import {
  getTransactionStaffToAdminStatusColor,
  TransactionStaffToAdminStatus,
} from '../../../models/transaction-staff-to-admin/enum'
import moment from 'moment'
import { ISort, IStaticFilter } from '../../../models/base/base-query'
import { tableOnChange } from '../../../utils/helpers/table-sorts-filters'

interface Props {
  showDetailsDrawer: () => void
  showApproveFormModal: () => void
}

const TransactionStaffToAdminTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { list, actions, loading, query } = useContext(
    TransactionStaffToAdminContext
  )

  const columns: ColumnProps<ITransactionStaffToAdmin>[] = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      align: 'center',
      sorter: {
        multiple: 1,
      },
    },
    {
      key: 'staff',
      title: t('staff'),
      dataIndex: 'createdBy',
      align: 'center',
      render: (_, record) => {
        return `${record.createdBy.firstName} ${record.createdBy.lastName}`
      },
      sorter: {
        multiple: 2,
      },
    },
    {
      key: 'depositDate',
      title: t('deposit_date'),
      dataIndex: 'depositDate',
      align: 'center',
      render: (_, record) => {
        return moment(record.depositDate).format('LLL')
      },
      sorter: {
        multiple: 3,
      },
    },
    {
      key: 'depositAmount',
      title: t('deposit_amount'),
      dataIndex: 'depositAmount',
      align: 'center',
      render: (_, record) => {
        return record.depositAmount
      },
      sorter: {
        multiple: 4,
      },
    },

    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      align: 'center',
      render: (status: TransactionStaffToAdminStatus) => {
        return (
          status && (
            <Tag
              color={getTransactionStaffToAdminStatusColor(status)}
              style={{ textAlign: 'center' }}
            >
              {t(`${TransactionStaffToAdminStatus[status]}`)}
            </Tag>
          )
        )
      },
    },
    {
      key: 'actions',
      title: t('actions'),
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Space>
          <ConfirmBtn
            disabled={record.status === TransactionStaffToAdminStatus.CONFIRMED}
            onClick={() => {
              actions.setDetails(record)
              props.showApproveFormModal()
            }}
            loading={loading.includes('update')}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteTransactionStaffToAdmin(record?.id)
              eventManager.emit(EVENT_SUCCESS)
            }}
            loading={loading.includes('delete')}
          />
        </Space>
      ),
    },
  ]

  // Filters && Sorts
  let tableFiltersProps = {}
  const [sorts, setSorts] = useState<ISort[]>([])
  const [, setStaticFilters] = useState<IStaticFilter[]>(
    query?.staticFilters ?? []
  )

  useEffect(() => {
    sorts &&
      actions.setQuery({
        ...query,
        sorts: sorts,
      })
  }, [sorts])

  return (
    <Table
      rowKey='id'
      columns={columns}
      dataSource={list?.data}
      loading={loading.includes('list')}
      showPagination
      total={list?.meta?.total}
      page={query.page}
      scroll={{ x: 200 }}
      pageSize={query.perPage}
      onPaginationChange={(page, pageSize) => {
        actions.setQuery({
          ...query,
          page,
          perPage: pageSize,
        })
      }}
      onChange={(_, tableFilters, tabelSorters) => {
        tableOnChange(
          tableFilters,
          tabelSorters,
          tableFiltersProps,
          setSorts,
          setStaticFilters
        )
      }}
    />
  )
}

export default TransactionStaffToAdminTable
