import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import AddressContextProvider from '../../contexts/address/provider'
import PageHeader from '../../components/general/antd/page-header'
import AddressContext from '../../contexts/address/context'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import AddressTable from '../../components/address/address-table'
import AddressDetails from '../../components/address/address-details'
import InfoDrawer from '../../components/general/info-drawer'
import AddressFilters from '../../components/address/address-filters'

interface Props {
  route: IRoute
}

const Addresses: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(AddressContext)

  const [, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />

      <AddressFilters />

      <AddressTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <InfoDrawer
        title={t('address_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <AddressDetails address={details} />
      </InfoDrawer>
    </>
  )
}

const AddressesPage: React.FC<Props> = (props) => {
  return (
    <AddressContextProvider>
      <Addresses {...props} />
    </AddressContextProvider>
  )
}
export default AddressesPage
