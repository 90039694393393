import { createContext } from 'react'
import { IProductQuery } from '../../models/product/query'
import { ICreateProduct, IUpdateProduct } from '../../models/product/request'
import { IProduct } from '../../models/product/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type ProductLoading = 'list' | 'details' | 'create' | 'update' | 'delete'

export interface IInternalState {
  loading: ProductLoading[]

  list?: IBaseListingResponse<IProduct>
  query: IProductQuery

  details?: IProduct
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IProduct) => void

    createProduct: (request: ICreateProduct) => void
    updateProduct: (id: number, request: IUpdateProduct) => void
    deleteProduct: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IProductQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createProduct: DEFAULT_FUNCTION,
    updateProduct: DEFAULT_FUNCTION,
    deleteProduct: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const ProductContext = createContext(externalState)

export default ProductContext
