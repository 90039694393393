import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

interface IProps {
  label: string
  isOptional?: boolean
  required?: boolean
}

const FormItem: React.FC<IProps> = ({
  label,
  required,
  isOptional,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.formItem}>
      <label className={styles.formItemLabel}>
        {required && (
          <span
            style={{ padding: '0.3rem', fontWeight: 'bold', color: '#FB5D48' }}
          >
            *
          </span>
        )}
        {label} {isOptional && <span>({t('optional')})</span>}
      </label>
      {children}
    </div>
  )
}

export default FormItem
