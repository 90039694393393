import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import FieldBuilder from '../../form-components/field-builder'
import CitySelectOptionsContext from '../../../contexts/select-options/city-select-options/context'

interface IProps {
  citiesIdsAlreadySelected: number[]
}

const AddSubscriptionCityForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { list: cityOptions, loading: cityOptionsLoading } = useContext(
    CitySelectOptionsContext
  )

  return (
    <div>
      {/* City */}
      <FieldBuilder
        name='cityId'
        label={t('city')}
        rules={{ required: true }}
        input={{
          type: 'select',
          allowSearch: true,
          loading: cityOptionsLoading.includes('list'),
          options: cityOptions?.data
            ?.filter((s) => !props.citiesIdsAlreadySelected.includes(s.id))
            ?.map((service) => {
              return { value: service.id, label: service.name }
            }),
        }}
      />

      <FieldBuilder
        name={'price'}
        label={t('price')}
        input={{ type: 'number' }}
        rules={{ required: true }}
      />

      <FieldBuilder
        name={'note'}
        label={t('note')}
        input={{ type: 'text-area' }}
        rules={{ required: true }}
      />
    </div>
  )
}

export default AddSubscriptionCityForm
