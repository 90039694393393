import { Checkbox, Col, Divider, Row } from "antd";
import cn from "classnames";
import { isEmpty, snakeCase } from "lodash";
import { Key, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
const CheckboxGroup = Checkbox.Group;

// const names = namesOfColumnsTrademarkRequest;
// const defaultColumns = [];
interface IProps {
  names?: any;
  defaultColumns?: any;
}
const TemplateExportExcel: React.FC<IProps> = ({
  names,
  defaultColumns = [],
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();

  const [checkedList, setCheckedList] = useState([] as any);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list: any[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < names?.length);
    setCheckAll(list.length === names?.length);
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? names : defaultColumns);
    setIndeterminate(e.target.checked ? false : !isEmpty(defaultColumns));
    setCheckAll(e.target.checked);
  };

  // Default Columns
  const isDefault: (name: string) => boolean = (name) => {
    return defaultColumns.includes(name);
  };

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          {t("check_all")}
        </Checkbox>
      </Col>
      <Col span={24}>
        <Divider style={{ margin: "10px 0" }} />
      </Col>
      <Col span={24}>
        <CheckboxGroup
          defaultValue={defaultColumns}
          value={checkedList}
          onChange={onChange}
        >
          <Row gutter={[14, 14]}>
            {names?.map(
              (name: string | undefined, key: Key | null | undefined) => {
                return (
                  <Col span={8} style={{ minWidth: "10rem" }}>
                    <Checkbox
                      key={key}
                      value={name}
                      disabled={isDefault(name as any)}
                    >
                      {t(snakeCase(name))}
                    </Checkbox>
                  </Col>
                );
              }
            )}
          </Row>
        </CheckboxGroup>
      </Col>
      {checkedList?.map((column: any, index: string | number) => {
        setValue(checkedList[index], true);
        return (
          <Controller
            name={checkedList[index]}
            control={control}
            defaultValue={column}
            render={({ field: { ...field } }) => {
              return (
                <>
                  <Checkbox
                    {...field}
                    checked={field.value}
                    className={cn("shadow", styles.hiden)}
                  >
                    {t("aa")}
                  </Checkbox>
                </>
              );
            }}
          />
        );
      })}
    </Row>
  );
};

export default TemplateExportExcel;
