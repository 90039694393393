import { IUserQuery } from '../../models/user/query'
import { ICreateUser } from '../../models/user/request'
import { IUser } from '../../models/user/response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class UserService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllUsers = (query: IUserQuery, params?: RequestParams) =>
    this.http.request<IUser[]>({
      path: `/`,
      method: 'POST',
      query,
      ...params,
    })

  createUser = (data: ICreateUser, params?: RequestParams) =>
    this.http.request<IUser>({
      path: '',
      method: 'POST',
      body: data,
      ...params,
    })

  updateUser = (id: number, data: ICreateUser, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteUser = (id: number, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default UserService
