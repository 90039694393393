export type Path =
  | '/'
  | '/auth/login'
  | '/addresses'
  | '/attendances'
  | '/banners'
  | '/brands'
  | '/categories'
  | '/countries'
  | '/cities'
  | '/cars'
  | '/staff'
  | '/staff-types'
  | '/cars-colors'
  | '/configs'
  | '/clients'
  | '/orders'
  | '/products'
  | '/promo-codes'
  | '/requests'
  | '/subscriptions'
  | '/services'
  | '/tags'
  | '/faqs'
  | '/users'
  | '/permanent-locations'
  | '/transactions-admin-to-staff'
  | '/transactions-staff-to-admin'
  | '/locations'
  | '/manage-staff'
  | '/products-manage'
  | '/general-info'
  | '/features'
  | '/clients_management'
  | '/notifications'
  | '/client-subscriptions'
  | '/plate-cities'

export const guestPathes: Path[] = []

export const HOME_PAGE: Path = '/'
export const LOGIN_PAGE: Path = '/auth/login'
