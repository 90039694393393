import {Col, Row} from 'antd'
import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import FieldBuilder from '../../form-components/field-builder'
import FilterCard from '../../general/filter-card'
import Form from '../../form-components/from'
import {INITIAL_PAGE} from '../../../utils/helpers/constants'

import styles from './style.module.scss'
import CategoryContext from '../../../contexts/category/context'
import {CategoryStatus} from "../../../models/category/enum";

interface IProps {
}

const CategoryFilters: React.FC<IProps> = () => {
    const {t} = useTranslation()
    const {actions, loading, query} = useContext(CategoryContext)

    return (
        <div className={styles.formContainer}>
            <Form
                formId='categories-filter'
                onSubmit={(data) => {
                    const filter: any = {
                        page: INITIAL_PAGE,
                        perPage: query?.perPage,
                        keyword: data?.keyword,
                        staticFilters: [
                            data?.status?.length > 0
                                ? {
                                    name: 'status',
                                    value: [...(data?.status ?? [])],
                                    // == != > < >= <= in
                                    operation: 'in',
                                }
                                : undefined,].filter(f => f)
                    }
                    actions.setQuery(filter)
                }}
            >
                <FilterCard
                    formId='categories-filter'
                    applyLoading={loading.includes('list')}
                    resetLoading={loading.includes('list')}
                    onReset={() => {
                        actions.setQuery({
                            page: query?.page,
                            perPage: query?.perPage,
                        })
                    }}
                >
                    <Row justify={'start'} gutter={[10, 0]} className={styles.filters}>
                        <Col span={8}>
                            <FieldBuilder
                                name='keyword'
                                label={t('search')}
                                input={{type: 'text'}}
                            />
                        </Col>
                        <Col span={8}>
                            <FieldBuilder
                                name='status'
                                label={t('status')}
                                input={{
                                    type: 'multiSelect',
                                    options: [
                                        {
                                            value: CategoryStatus.ACTIVE,
                                            label: t(
                                                `${
                                                    CategoryStatus[
                                                        CategoryStatus.ACTIVE
                                                        ]
                                                }`
                                            ),
                                        },
                                        {
                                            value: CategoryStatus.INACTIVE,
                                            label: t(
                                                `${
                                                    CategoryStatus[CategoryStatus.INACTIVE]
                                                }`
                                            ),
                                        },

                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                </FilterCard>
            </Form>
        </div>
    )
}

export default CategoryFilters
