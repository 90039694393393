import React, { useContext, useEffect } from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { IOrder } from '../../../models/order/response'
import StaffSelectOptionsContext from '../../../contexts/select-options/staff-select-options/context'

interface IProps {
  order?: IOrder
}

const OrderApproveForm: React.FC<IProps> = ({ order }) => {
  const { t } = useTranslation()

  const { actions, loading, list } = useContext(StaffSelectOptionsContext)

  useEffect(() => {
    actions.getData()
  }, [])

  return (
    <div>
      <FieldBuilder
        name='assignedStaffId'
        label={t('assigned_staff')}
        input={{
          type: 'select',
          options: list?.data?.map((staff) => {
            return {
              value: staff.id,
              label: `${staff.user?.firstName} ${staff.user?.lastName}`,
            }
          }),
          allowSearch: true,
          loading: loading.includes('list'),
        }}
        rules={{ required: true }}
      />

      <FieldBuilder
        name='note'
        label={t('note')}
        input={{ type: 'text-area' }}
        rules={{ required: true }}
      />
    </div>
  )
}

export default OrderApproveForm
