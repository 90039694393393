import {
  Col,
  Descriptions as AntdDescriptions,
  Input,
  List,
  Row,
  Tabs,
  Tag,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { ICity } from '../../../models/city/response'
import Descriptions from '../../general/antd/descriptions'
import Image from '../../general/antd/image/index'
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import { useState } from 'react'
import { ServiceStatus } from '../../../models/service/enum'

interface Props {
  city?: ICity
}
const { TabPane } = Tabs

const CityDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <>
      <Descriptions size='middle' column={1} bordered>
        <AntdDescriptions.Item label={t('id')}>
          {props.city?.id}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('name')}>
          {props.city?.name}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('country')}>
          {props.city?.country?.name}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('start_working_hour')}>
          {props.city?.startWorkingHour}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('end_working_hour')}>
          {props.city?.endWorkingHour}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('plate')}>
          <Image src={props.city?.plateImage?.url} />
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('note')}>
          {props.city?.note}
        </AntdDescriptions.Item>
      </Descriptions>
      <Tabs defaultActiveKey='1' animated type='line'>
        <TabPane tab={t('services')} key='1'>
          <List
            bordered={false}
            header={
              <Row justify='space-between'>
                <Col></Col>
                <Col>
                  <Input
                    placeholder={t('search_hint')}
                    suffix={<SearchOutlined />}
                    allowClear
                    onChange={(event) => {
                      setSearchTerm(event.target.value)
                    }}
                  />
                </Col>
              </Row>
            }
            dataSource={
              props.city?.services?.filter((service) =>
                service.name.toLowerCase().includes(searchTerm.toLowerCase())
              ) ?? []
            }
            renderItem={(item) => (
              <List.Item actions={[]}>
                <List.Item.Meta
                  avatar={
                    <Image
                      src={
                        item?.status == ServiceStatus.ACTIVE
                          ? item?.activeImage?.url
                          : item?.inactiveImage?.url
                      }
                    />
                  }
                  title={`${item?.name} (${item?.cityPrice} ${t('aed')})`}
                  description={item?.cityNote}
                />
                <div>
                  {item?.status && (
                    <Tag
                      color={
                        item?.status == ServiceStatus.ACTIVE ? 'green' : 'red'
                      }
                    >
                      {t(`${ServiceStatus[item?.status]}`)}
                    </Tag>
                  )}
                </div>
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </>
  )
}

export default CityDetails
