import { useTranslation } from 'react-i18next'
import { IApproveOrder } from '../../../models/order/request'
import FormModal from '../../form-components/form-modal'
import OrderApproveForm from '../order-approve-form'
import StaffSelectOptionsContextProvider from '../../../contexts/select-options/staff-select-options/provider'

interface IProps {
  loading: boolean
  visable: boolean
  hideModal: () => void
  onSubmit: (data: IApproveOrder) => void
}

const ApproveOrderFormModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  return (
    <StaffSelectOptionsContextProvider>
      <FormModal
        title={t('approve_order')}
        formId='approve-order'
        onSubmit={props.onSubmit}
        hideModal={props.hideModal}
        confirmLoading={props.loading}
        visible={props.visable}
      >
        <OrderApproveForm />
      </FormModal>
    </StaffSelectOptionsContextProvider>
  )
}

export default ApproveOrderFormModal
