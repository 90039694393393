import { Layout } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import cn from 'classnames'

const { Footer: AntdFooter } = Layout
interface Props {}

const Footer: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <AntdFooter className={styles.footer}>
      <span>{`©2022 `}</span>
      <span className={styles.carbeeLogo}>{`${t('carbee')} `}</span>
      <span>{`${t('created_by')} `}</span>
      <a
        href='http://www.autozonegroup.com/'
        target='_blank'
        rel='noopener noreferrer'
        className={cn('text-primary', styles.autoZoneLogo)}
      >
        AutoZone
      </a>
    </AntdFooter>
  )
}

export default Footer
