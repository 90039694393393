import { I18nextProvider } from 'react-i18next'
import i18next from './utils/localization/i18n'
import { Route, Switch } from 'react-router-dom'
import AuthContextProvider from './contexts/auth/provider'
import AppContextProvider from './contexts/app/provider'
import DashboardRoutes from './routes/dashboard'
import './App.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import AuthRoutes from './routes/auth'

function App() {
  return (
    <I18nextProvider i18n={i18next}>
      <AppContextProvider>
        <AuthContextProvider>
          <Switch>
            <Route path={['/auth', '/']}>
              <Switch>
                <Route path={['/auth']}>
                  <AuthRoutes />
                </Route>
                <Route path={['/']}>
                  <DashboardRoutes />
                </Route>
              </Switch>
            </Route>
          </Switch>
        </AuthContextProvider>
      </AppContextProvider>
    </I18nextProvider>
  )
}

export default App
