export interface IApproveOrder {
  assignedStaffId: number;
  note: string;
}

export interface IRejectOrder {
  note: string;
}
export interface IExportOrderExcel {
  requiredDate: boolean;
  requiredTime: boolean;
  isFlexi: boolean;
  mainValue: boolean;
  totalValue: boolean;
  estimatedTime: boolean;
  clientPhone: boolean;
  clientName: boolean;
  city: boolean;
  promoCode: boolean;
  clientAddress: boolean;
  permanentLocation: boolean;
  staff: boolean;
  userType: boolean;
  paymentMethod: boolean;
  status: boolean;
  token: boolean;
  flexiPercentage: boolean;
  commission: boolean;
  tax: boolean;
}
export const colomnsExportOrderExcel = [
  "requiredDate",
  "requiredTime",
  "isFlexi",
  "mainValue",
  "totalValue",
  "estimatedTime",
  "clientPhone",
  "clientName",
  "city",
  "promoCode",
  "clientAddress",
  "permanentLocation",
  "staff",
  "userType",
  "paymentMethod",
  "status",
  "flexiPercentage",
  "commission",
  "tax",
];
