import { createContext } from 'react'
import { IServiceQuery } from '../../models/service/query'
import { ICreateService, IUpdateService } from '../../models/service/request'
import { IService } from '../../models/service/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type ServiceLoading = 'list' | 'details' | 'create' | 'update' | 'delete'

export interface IInternalState {
  loading: ServiceLoading[]

  list?: IBaseListingResponse<IService>
  query: IServiceQuery

  details?: IService
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IService) => void

    createService: (request: ICreateService) => void
    updateService: (id: number, request: IUpdateService) => void
    deleteService: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IServiceQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createService: DEFAULT_FUNCTION,
    updateService: DEFAULT_FUNCTION,
    deleteService: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const ServiceContext = createContext(externalState)

export default ServiceContext
