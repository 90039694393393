import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import FormDrawer from '../../form-components/form-drawer/index'
import { INotifications } from '../../../models/notifications/response'
import NotificationForm from '../notification-form'

interface Props<DataType> {
  formId: string
  loading: boolean
  visable: boolean
  hideDrawer: () => void
  defaultValues?: DataType
  details?: INotifications
  onSubmit: (data: DataType) => void
}

const NotificationFormDrawer = function <DataType>(
  props: PropsWithChildren<Props<DataType>>
) {
  const { t } = useTranslation()

  return (
    <FormDrawer<DataType>
      defaultValues={props.defaultValues}
      title={
        props.details
          ? t('update_notification')
          : t('create_public_notification')
      }
      onSubmit={props.onSubmit}
      hideDrawer={props.hideDrawer}
      visible={props.visable}
      formId={props.formId}
      confirmLoading={props.loading}
      children={<NotificationForm />}
    />
  )
}

export default NotificationFormDrawer
