import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import FormModal from '../../../components/form-components/form-modal'
import UserInputs from '../user-inputs'

interface Props<DataType> {
  loading: boolean
  visable: boolean
  hideModal: () => void
  defaultValues?: DataType
  onSubmit: (data: DataType) => void
}

const UserFormModal = function <DataType>(
  props: PropsWithChildren<Props<DataType>>
) {
  const { t } = useTranslation()

  return <></>
  // return (
  //   <FormModal<DataType>
  //     defaultValues={props.defaultValues}
  //     title={props.defaultValues ? t('updateUser') : t('createUser')}
  //     onSubmit={props.onSubmit}
  //     hideModal={props.hideModal}
  //     confirmLoading={props.loading}
  //     visible={props.visable}
  //   >
  //     <UserInputs type={props.defaultValues ? 'edit' : 'create'} />
  //   </FormModal>
  // )
}

export default UserFormModal
