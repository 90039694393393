import { IServiceQuery } from '../../models/service/query'
import { ICreateService, IUpdateService } from '../../models/service/request'
import { IService } from '../../models/service/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class ServiceService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllServices = (query: IServiceQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IService>>({
      path: `/dashboard/services/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createService = (data: ICreateService, params?: RequestParams) =>
    this.http.request<IService>({
      path: '/dashboard/services',
      method: 'POST',
      body: data,
      ...params,
    })

  updateService = (id: number, data: IUpdateService, params?: RequestParams) =>
    this.http.request<IService>({
      path: `/dashboard/services/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteService = (id: number, params?: RequestParams) =>
    this.http.request<IService>({
      path: `/dashboard/services/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default ServiceService
