import { Tooltip } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Button, { ButtonProps } from '../../../general/antd/button'

interface Props extends ButtonProps {}

const RejectBtn: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('reject')}>
      <Button
        type='primary'
        danger
        shape='circle'
        icon={<CloseOutlined />}
        {...props}
      />
    </Tooltip>
  )
}

export default RejectBtn
