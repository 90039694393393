import React, {useEffect, useState, useContext} from 'react'
import FieldBuilder from '../../form-components/field-builder'
import {useTranslation} from 'react-i18next'
import {languages} from '../../../utils/helpers/constants'
import {Tabs, Divider, Form, List, Row, Col, Tag} from 'antd'
import TranslationsCard from '../../form-components/translations-card'
import ImageUploader from '../../form-components/image-uploader'
import Controller from '../../form-components/controller'
import {MediumFor} from '../../../models/medium/enum'
import {ICity, ICityService} from '../../../models/city/response'
import OldImage from '../../form-components/old-image'
import CountrySelectOptionsContext from '../../../contexts/select-options/country-select-options/context'
import ServiceSelectOptionsContext from '../../../contexts/select-options/service-select-options/context'
import {useFormContext} from 'react-hook-form'
import AddCityServiceForm from '../city-service-form'
import FormModal from '../../form-components/form-modal'
import AddBtn from '../../table-components/actions/add-btn'
import Input from '../../form-components/input'
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import DeleteBtn from '../../table-components/actions/delete-btn'
import Image from '../../general/antd/image'
import {ServiceStatus} from '../../../models/service/enum'
import {cloneDeep} from 'lodash'

const {TabPane} = Tabs

interface IProps {
    city?: ICity
}

const CityForm: React.FC<IProps> = ({city}) => {
    const {t} = useTranslation()
    const {setValue} = useFormContext()

    const {list, actions, loading} = useContext(CountrySelectOptionsContext)

    // Services
    const [services, setServices] = useState(city?.services)

    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        city?.services && setServices(city?.services)
    }, [city?.services])

    const {
        list: serviceOptions,
        actions: serviceOptionsAction,
        loading: serviceOptionsLoading,
    } = useContext(ServiceSelectOptionsContext)

    // Services Management
    const [addServiceModalVisible, setAddServiceModalVisible] = useState(false)

    const addService = (serviceId: number, price: number, note: string) => {
        let selectedService = serviceOptions?.data.find((s) => s.id == serviceId)
        let newService: ICityService
        if (selectedService) {
            newService = {
                ...selectedService,
                cityPrice: price,
                cityNote: note,
            }
            if (services) {
                setServices([...services, newService])
            } else {
                setServices([newService])
            }
        }
    }

    const deleteService = (serviceId: number) => {
        setServices(services?.filter((s) => s.id != serviceId))
    }

    useEffect(() => {
        setValue('services', undefined)
        services &&
        services.forEach((service, index) => {
            setValue(`services[${index}].serviceId`, service?.id)
            setValue(`services[${index}].price`, service?.cityPrice)
            setValue(`services[${index}].note`, service?.cityNote)
        })
    }, [services])

    // Old Image
    const [image, setImage] = useState(city?.plateImage?.url)

    useEffect(() => {
        city?.plateImage?.url && setImage(city?.plateImage?.url)
    }, [city?.plateImage?.url])

    useEffect(() => {
        actions.getData()
        serviceOptionsAction.getData()
    }, [])

    return (
        <div>
            <TranslationsCard>
                <Tabs type='line'>
                    {languages?.map((lang, index) => {
                        return (
                            <TabPane tab={lang.name} key={index}>
                                <FieldBuilder
                                    key={index}
                                    name={`name[${lang?.code}]`}
                                    label={`${t('name')} (${lang?.name})`}
                                    input={{type: 'text'}}
                                    rules={{required: true}}
                                />

                                <FieldBuilder
                                    key={index}
                                    name={`note[${lang?.code}]`}
                                    label={`${t('note')} (${lang?.name})`}
                                    input={{type: 'text-area'}}
                                    rules={{required: false}}
                                />
                            </TabPane>
                        )
                    })}
                </Tabs>
            </TranslationsCard>

            <Divider/>

            {/* Country */}
            <FieldBuilder
                name='countryId'
                label={t('country')}
                rules={{required: true}}
                input={{
                    type: 'select',
                    allowSearch: true,
                    loading: loading.includes('list'),
                    options: list?.data?.map((country) => {
                        return {value: country.id, label: country.name}
                    }),
                }}
            />

            {/* startWorkingHour */}
            <FieldBuilder
                name='workingHour'
                label={t('working_hours')}
                input={{type: 'time-range-picker', format: 'HH:mm'}}
                rules={{required: true}}
            />

            {/* endWorkingHour */}
            {/* <FieldBuilder
        name='endWorkingHour'
        label={t('end_working_hour')}
        input={{ type: 'time-picker', format: 'HH:mm' }}
        rules={{ required: true }}
      /> */}

            {/* Upload Plate Image */}
            <div>
                <Form.Item label={t('plate_image')}>
                    <Controller
                        name='plateImageId'
                        rules={{
                            required: false,
                        }}
                        render={({field: {ref, ...field}}) => {
                            return (
                                <ImageUploader
                                    {...field}
                                    onChange={(response) => {
                                        setImage(undefined)
                                        field.onChange(response)
                                    }}
                                    imageFor={MediumFor.COUNTRY_FLAG_IMAGE}
                                />
                            )
                        }}
                    />
                </Form.Item>
            </div>

            {/* Old  Image */}
            {image && <OldImage src={image}/>}

            <Tabs defaultActiveKey='1' animated type='line'>
                <TabPane tab={t('services')} key='1'>
                    <List
                        bordered={false}
                        header={
                            <Row justify='space-between'>
                                <Col>
                                    <AddBtn
                                        title={t('add_service')}
                                        onClick={() => {
                                            // Show Modal
                                            setAddServiceModalVisible(true)
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        placeholder={t('search_hint')}
                                        suffix={<SearchOutlined/>}
                                        allowClear
                                        onChange={(event) => {
                                            setSearchTerm(event.target.value)
                                        }}
                                    />
                                </Col>
                            </Row>
                        }
                        dataSource={
                            services?.filter((service) =>
                                service.name.toLowerCase().includes(searchTerm.toLowerCase())
                            ) ?? []
                        }
                        renderItem={(item) => (
                            <List.Item
                                actions={[
                                    <DeleteBtn
                                        onConfirm={() => {
                                            deleteService(item?.id)
                                        }}
                                    />,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Image
                                            src={
                                                item?.status == ServiceStatus.ACTIVE
                                                    ? item?.activeImage?.url
                                                    : item?.inactiveImage?.url
                                            }
                                        />
                                    }
                                    title={`${item?.name} (${item?.cityPrice} ${t('aed')})`}
                                    description={item?.cityNote}
                                />
                                <div>
                                    {item?.status && (
                                        <Tag
                                            color={
                                                item?.status == ServiceStatus.ACTIVE ? 'green' : 'red'
                                            }
                                        >
                                            {t(`${ServiceStatus[item?.status]}`)}
                                        </Tag>
                                    )}
                                </div>
                            </List.Item>
                        )}
                    />
                </TabPane>
            </Tabs>

            {/* Add Service Modal */}
            <FormModal
                title={t('add_service_to_city')}
                formId='add-city'
                hideModal={() => {
                    setAddServiceModalVisible(false)
                }}
                visible={addServiceModalVisible}
                onSubmit={(data: {
                    serviceId: number
                    price: number
                    note: string
                }) => {
                    addService(data.serviceId, data.price, data.note)
                }}
                confirmLoading={false}
            >
                <AddCityServiceForm
                    servicesIdsAlreadySelected={services ? services.map((s) => s.id) : []}
                />
            </FormModal>
        </div>
    )
}

export default CityForm
