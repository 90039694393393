import { Descriptions as AntdDescriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { IProduct } from '../../../models/product/response'
import Descriptions from '../../general/antd/descriptions'
import Image from '../../general/antd/image/index'

interface Props {
  product?: IProduct
}

const ProductDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.product?.id}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('name')}>
        {props.product?.name}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('description')}>
        {props.product?.description}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('price')}>
        {`${props.product?.price} ${t('aed')}`}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('image')}>
        <Image src={props.product?.image?.url} />
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default ProductDetails
