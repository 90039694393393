import {
  Descriptions as AntdDescriptions,
  Descriptions,
  Input,
  List,
  Tabs,
  Tag,
} from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import Image from '../../general/antd/image'
import MapBox from '../../general/mapbox'
import { ISubscription } from '../../../models/subscription/response'
import { SubscriptionStatus } from '../../../models/subscription/enum'
import { ServiceStatus } from '../../../models/service/enum'
import Avatar from 'antd/lib/avatar/avatar'
import { FaAddressBook, FaCity } from 'react-icons/fa'
import { FiActivity } from 'react-icons/fi'
import styles from './style.module.scss'

const { TabPane } = Tabs

interface Props {
  subscription?: ISubscription
}

const SubscriptionDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const [cities, setCities] = useState(props.subscription?.cities)

  const [services, setServices] = useState(props?.subscription?.services)
  const [offerServices, setOfferServices] = useState(
    props?.subscription?.offerServices
  )

  useEffect(() => {
    props?.subscription?.services && setServices(props?.subscription?.services)
    props?.subscription?.offerServices &&
      setOfferServices(props?.subscription?.offerServices)
  }, [props?.subscription?.services, props?.subscription?.offerServices])

  useEffect(() => {
    props.subscription?.cities && setCities(props.subscription?.cities)
  }, [props.subscription?.cities])

  return (
    <>
      <Descriptions size='middle' column={1} bordered>
        <AntdDescriptions.Item label={t('id')}>
          {props.subscription?.id}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('main_name')}>
          {props.subscription?.mainName}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('sub_name')}>
          {props.subscription?.subName}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('status')}>
          {props?.subscription?.status &&
            t(`${SubscriptionStatus[props?.subscription?.status]}`)}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('period')}>
          {props.subscription?.period}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('price')}>
          {props.subscription?.period}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('discount_percentage')}>
          {props.subscription?.discountPercentage}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('cars_count')}>
          {props.subscription?.carsCount}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('image')}>
          <Image preview src={props.subscription?.image?.url} />
        </AntdDescriptions.Item>

        {/* Todo: Map with radius*/}
      </Descriptions>

      <Tabs defaultActiveKey='1' animated type='line'>
        <TabPane tab={t('services')} key='1'>
          <List
            header={
              <Input
                placeholder={t('search_hint')}
                suffix={<SearchOutlined />}
                allowClear
                onChange={(event) => {
                  let searchKey = event.target.value

                  let filteredServices = props.subscription?.services?.filter(
                    (service) => service?.name?.includes(searchKey)
                  )

                  setServices(filteredServices)

                  if (searchKey == '') {
                    setServices(props.subscription?.services)
                  }
                }}
              />
            }
            bordered
            dataSource={services ?? []}
            renderItem={(item) => (
              <>
                <List.Item className={styles.list}>
                  <List.Item.Meta
                    avatar={
                      <Image
                        src={
                          item?.status == ServiceStatus.ACTIVE
                            ? item?.activeImage?.url
                            : item?.inactiveImage?.url
                        }
                      />
                    }
                    title={`${item?.name} (${item?.additionalInformation} ${t(
                      'aed'
                    )})`}
                    description={
                      <span>
                        {`${t('duration')}: ${item?.duration} ${
                          item?.duration === 1 ? t('month') : t('months')
                        }`}
                        <span className={styles.amount}>{`${t('amount')}: ${
                          item?.serviceAmount
                        }`}</span>
                      </span>
                    }
                  />
                  <div>
                    {item?.status && (
                      <Tag
                        color={
                          item?.status == ServiceStatus.ACTIVE
                            ? 'green'
                            : 'yellow'
                        }
                      >
                        {t(`${ServiceStatus[item?.status]}`)}
                      </Tag>
                    )}
                  </div>
                </List.Item>
              </>
            )}
          />
        </TabPane>
        <TabPane tab={t('offer_services')} key='2'>
          <List
            header={
              <Input
                placeholder={t('search_hint')}
                suffix={<SearchOutlined />}
                allowClear
                onChange={(event) => {
                  let searchKey = event.target.value

                  let filteredServices =
                    props.subscription?.offerServices?.filter((service) =>
                      service?.name?.includes(searchKey)
                    )

                  setOfferServices(filteredServices)

                  if (searchKey == '') {
                    setOfferServices(props.subscription?.offerServices)
                  }
                }}
              />
            }
            bordered
            dataSource={offerServices ?? []}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Image
                      src={
                        item?.status == ServiceStatus.ACTIVE
                          ? item?.activeImage?.url
                          : item?.inactiveImage?.url
                      }
                    />
                  }
                  title={`${item?.name} (${item?.additionalInformation}`}
                  description={
                    <>
                      <span style={{ display: 'block' }}>{item?.category}</span>
                      <span>{`${t('price')}: ${item?.price}`}</span>
                    </>
                  }
                />
                <div>
                  {item?.status && (
                    <Tag
                      color={
                        item?.status == ServiceStatus.ACTIVE
                          ? 'green'
                          : 'yellow'
                      }
                    >
                      {t(`${ServiceStatus[item?.status]}`)}
                    </Tag>
                  )}
                </div>
              </List.Item>
            )}
          />
        </TabPane>

        <TabPane tab={t('cities')} key='3'>
          <List
            header={
              <Input
                placeholder={t('search_hint')}
                suffix={<SearchOutlined />}
                allowClear
                onChange={(event) => {
                  let searchKey = event.target.value

                  let filteredCities = props.subscription?.cities?.filter(
                    (city) => city?.name?.includes(searchKey)
                  )

                  setCities(filteredCities)

                  if (searchKey == '') {
                    setCities(props.subscription?.cities)
                  }
                }}
              />
            }
            bordered
            dataSource={cities ?? []}
            renderItem={(item) => (
              <List.Item style={{ justifyContent: 'unset', gap: '15px' }}>
                <Image
                  style={{
                    margin: '0px',
                    padding: '0px',
                    width: '100px',
                  }}
                  src={
                    item?.plateImage?.url
                      ? item?.plateImage?.url
                      : `${process.env.PUBLIC_URL}/assets/images/notFound.png`
                  }
                  // icon={<FiActivity />}
                />
                <span>{`${item?.name}  ( ${item.subscriptionCityPrice} ${t(
                  'aed'
                )})`}</span>
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </>
  )
}

export default SubscriptionDetails
