import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import FieldBuilder from '../../form-components/field-builder'
import PermanentLocationSelectOptionsContext from '../../../contexts/select-options/permanent-location-select-options/context'

interface IProps {
  permanentLocationsIdsAlreadySelected: number[]
}

const AddServicePermanentLocationForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const {
    list: permanentLocationsOptions,
    loading: permanentLocationsOptionsLoading,
  } = useContext(PermanentLocationSelectOptionsContext)

  return (
    <div>
      {/* City */}
      <FieldBuilder
        name='permanentLocationId'
        label={t('permanent_location')}
        rules={{ required: true }}
        input={{
          type: 'select',
          allowSearch: true,
          loading: permanentLocationsOptionsLoading.includes('list'),
          options: permanentLocationsOptions?.data
            ?.filter(
              (s) => !props.permanentLocationsIdsAlreadySelected.includes(s.id)
            )
            ?.map((service) => {
              return { value: service.id, label: service.name }
            }),
        }}
      />

      <FieldBuilder
        name={'price'}
        label={t('price')}
        input={{ type: 'number' }}
        rules={{ required: true }}
      />

      <FieldBuilder
        name={'note'}
        label={t('note')}
        input={{ type: 'text-area' }}
        rules={{ required: true }}
      />
    </div>
  )
}

export default AddServicePermanentLocationForm
