import {Col, Row} from 'antd'
import React, {useContext, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import FieldBuilder from '../../form-components/field-builder'
import StaffContext from '../../../contexts/staff/context'
import FilterCard from '../../general/filter-card'
import Form from '../../form-components/from'
import {INITIAL_PAGE} from '../../../utils/helpers/constants'

import styles from './style.module.scss'
import PermanentLocationSelectOptionsContext
    from "../../../contexts/select-options/permanent-location-select-options/context";
import {StaffStatus} from "../../../models/staff/enum";

interface IProps {
}

const StaffFilters: React.FC<IProps> = () => {
    const {t} = useTranslation()
    const {actions, loading, query} = useContext(StaffContext)

    const {
        list: permanentLocations,
        actions: permanentLocationsActions,
        loading: permanentLocationsLoading,
    } = useContext(PermanentLocationSelectOptionsContext)

    useEffect(() => {
        permanentLocationsActions.getData()
    }, [])

    return (
        <div className={styles.formContainer}>
            <Form
                formId='staffs-filter'
                onSubmit={(data) => {
                    const filter: any = {
                        page: INITIAL_PAGE,
                        perPage: query?.perPage,
                        keyword: data?.keyword,
                        staticFilters: [
                            data?.permanentLocations?.length > 0
                                ? {
                                    name: 'permanentLocationId',
                                    value: [...(data?.permanentLocations ?? [])],
                                    // == != > < >= <= in
                                    operation: 'in',
                                }
                                : undefined,
                            data?.status?.length > 0
                                ? {
                                    name: 'status',
                                    value: [...(data?.status ?? [])],
                                    // == != > < >= <= in
                                    operation: 'in',
                                }
                                : undefined,
                        ].filter(f => f)
                    }
                    actions.setQuery(filter)
                }}
            >
                <FilterCard
                    formId='staffs-filter'
                    applyLoading={loading.includes('list')}
                    resetLoading={loading.includes('list')}
                    onReset={() => {
                        actions.setQuery({
                            page: query?.page,
                            perPage: query?.perPage,
                        })
                    }}
                >
                    <Row justify={'start'} gutter={[10, 0]} className={styles.filters}>
                        <Col span={8}>
                            <FieldBuilder
                                name='keyword'
                                label={t('search')}
                                input={{type: 'text'}}
                            />
                        </Col>
                        <Col span={8}>
                            <FieldBuilder
                                name='permanentLocations'
                                label={t('permanent_location')}
                                input={{
                                    type: 'multiSelect',
                                    loading: permanentLocationsLoading.includes('list'),
                                    allowSearch: true,

                                    options: permanentLocations?.data.map((permanentLocation) => {
                                        return {
                                            value: permanentLocation.id,
                                            label: permanentLocation.name
                                        }
                                    }),
                                }}
                            />
                        </Col>
                        <Col span={8}>
                            <FieldBuilder
                                name='status'
                                label={t('status')}
                                input={{
                                    type: 'multiSelect',
                                    options: [
                                        {
                                            value: StaffStatus.ACTIVE,
                                            label: t(
                                                `${
                                                    StaffStatus[
                                                        StaffStatus.ACTIVE
                                                        ]
                                                }`
                                            ),
                                        },
                                        {
                                            value: StaffStatus.INACTIVE,
                                            label: t(
                                                `${
                                                    StaffStatus[StaffStatus.INACTIVE]
                                                }`
                                            ),
                                        },

                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                </FilterCard>
            </Form>
        </div>
    )
}

export default StaffFilters
