import { ICarQuery } from '../../models/car/query'
import { ICreateCar, IUpdateCar } from '../../models/car/request'
import { ICar } from '../../models/car/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class CarService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllCars = (query: ICarQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICar>>({
      path: `/dashboard/cars/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createCar = (data: ICreateCar, params?: RequestParams) =>
    this.http.request<ICar>({
      path: '/dashboard/cars',
      method: 'POST',
      body: data,
      ...params,
    })

  updateCar = (id: number, data: IUpdateCar, params?: RequestParams) =>
    this.http.request<ICar>({
      path: `/dashboard/cars/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteCar = (id: number, params?: RequestParams) =>
    this.http.request<ICar>({
      path: `/dashboard/cars/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default CarService
