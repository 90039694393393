import { Descriptions as AntdDescriptions } from "antd";
import { useTranslation } from "react-i18next";
import { IAddress } from "../../../models/address/response";
import { formatDate } from "../../../utils/helpers/functions";
import Descriptions from "../../general/antd/descriptions";
import MapBox from "../../general/mapbox";

interface Props {
  address?: IAddress;
}

const AddressDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Descriptions size="middle" column={1} bordered>
      <AntdDescriptions.Item label={t("id")}>
        {props.address?.id}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t("name")}>
        {props.address?.name}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t("client")}>
        {props.address?.createdBy &&
          `${props.address?.createdBy?.firstName} ${props.address?.createdBy?.lastName}`}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t("created_at")}>
        {formatDate(props.address?.createdAt)}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t("location")}>
        {props.address && (
          <div style={{ height: "20rem", minWidth: "20rem" }}>
            <MapBox coordinates={[+props.address.lng, +props.address.lat]} />
          </div>
        )}
      </AntdDescriptions.Item>
    </Descriptions>
  );
};

export default AddressDetails;
