import React from 'react'
import styles from './style.module.scss'
import cn from 'classnames'
import Image from '../../general/antd/image/index'

interface IProps {
  src: string
}

const OldImage: React.FC<IProps> = ({ src }) => {
  return (
    <div className={styles.oldImageContainer}>
      <span className={cn(styles.p0, styles.mx1)}>
        <Image src={src} className={styles.imageIcon} />
      </span>
    </div>
  )
}

export default OldImage
