import { useTranslation } from "react-i18next";
import { IRoute } from "../../routes/routes";
import { useContext, useState } from "react";
import OrderContextProvider from "../../contexts/order/provider";
import PageHeader from "../../components/general/antd/page-header";
import OrderContext from "../../contexts/order/context";
import RefreshBtn from "../../components/table-components/actions/refresh-btn";
import Space from "../../components/general/antd/space";
import OrderTable from "../../components/order/order-table";
import OrderDetails from "../../components/order/order-details";
import InfoDrawer from "../../components/general/info-drawer";
import OrderFilters from "../../components/order/order-filters";
import eventManager, { EVENT_SUCCESS } from "../../utils/events";
import { IApproveOrder, IRejectOrder } from "../../models/order/request";
import ApproveOrderFormModal from "../../components/order/order-approve-form-modal";
import RejectOrderFormModal from "../../components/order/order-reject-form-modal";
import StaffSelectOptionsContextProvider from "../../contexts/select-options/staff-select-options/provider";
import { Button } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import ExcelOrderFormModal from "../../components/order/excel/order-excel-form-modal";

interface Props {
  route: IRoute;
}

const Orders: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { details, actions, loading } = useContext(OrderContext);

  const onApproveOrder = async (data: IApproveOrder) => {
    details && (await actions.approveOrder(details.id, data));

    eventManager.emit(EVENT_SUCCESS);
  };

  const onRejectOrder = async (data: IRejectOrder) => {
    details && (await actions.rejectOrder(details.id, data));

    eventManager.emit(EVENT_SUCCESS);
  };

  const exportExcel = async (data: any) => {
    await actions.exportExcel(data);
  };
  const [approveFormVisable, setApproveFormVisable] = useState(false);
  const [rejectFormVisable, setRejectFormVisable] = useState(false);
  const [excelFormVisable, setExcelFormVisable] = useState(false);

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false);

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t("manageRecource", { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <RefreshBtn
              loading={loading.includes("list")}
              onClick={() => actions.getData()}
            >
              {t("refresh")}
            </RefreshBtn>
            <Button
              type="primary"
              onClick={() => {
                setExcelFormVisable(true);
              }}
              icon={<FileExcelOutlined />}
            >
              {t("export_to_excel")}
            </Button>
            ,
          </Space>
        }
      />

      <StaffSelectOptionsContextProvider>
        <OrderFilters />
      </StaffSelectOptionsContextProvider>

      <OrderTable
        showApproveFormModal={() => setApproveFormVisable(true)}
        showRejectFormModal={() => setRejectFormVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <ApproveOrderFormModal
        loading={loading.includes("update")}
        visable={approveFormVisable}
        hideModal={() => setApproveFormVisable(false)}
        onSubmit={onApproveOrder}
      />

      <RejectOrderFormModal
        loading={loading.includes("update")}
        visable={rejectFormVisable}
        hideModal={() => setRejectFormVisable(false)}
        onSubmit={onRejectOrder}
      />
      <ExcelOrderFormModal
        loading={loading.includes("update")}
        visable={excelFormVisable}
        hideModal={() => setExcelFormVisable(false)}
        onSubmit={exportExcel}
      />
      <InfoDrawer
        title={t("order_details")}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes("details")}
      >
        <OrderDetails order={details} />
      </InfoDrawer>
    </>
  );
};

const OrdersPage: React.FC<Props> = (props) => {
  return (
    <OrderContextProvider>
      <Orders {...props} />
    </OrderContextProvider>
  );
};
export default OrdersPage;
