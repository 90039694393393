import { createContext } from 'react'
import { IAddressQuery } from '../../models/address/query'
import { ICreateAddress, IUpdateAddress } from '../../models/address/request'
import { IAddress } from '../../models/address/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type AddressLoading = 'list' | 'details' | 'create' | 'update' | 'delete'

export interface IInternalState {
  loading: AddressLoading[]

  list?: IBaseListingResponse<IAddress>
  query: IAddressQuery

  details?: IAddress
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IAddress) => void

    createAddress: (request: ICreateAddress) => void
    updateAddress: (id: number, request: IUpdateAddress) => void
    deleteAddress: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IAddressQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createAddress: DEFAULT_FUNCTION,
    updateAddress: DEFAULT_FUNCTION,
    deleteAddress: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const AddressContext = createContext(externalState)

export default AddressContext
