export enum RequestStatus {
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 3,
  RECEIVED = 4,
}

// Status Requests Colors
export const getRequestStatusColor = (status: RequestStatus) => {
  switch (status) {
    case RequestStatus.PENDING:
      return 'gray'
    case RequestStatus.APPROVED:
      return 'green'
    case RequestStatus.REJECTED:
      return 'red'
    case RequestStatus.RECEIVED:
      return 'blue'
    default:
      return 'gray'
  }
}
