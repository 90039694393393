import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import eventManager, { EVENT_ERORR, EVENT_SUCCESS } from '../../utils/events'
import FAQContextProvider from '../../contexts/faq/provider'
import PageHeader from '../../components/general/antd/page-header'
import FAQContext from '../../contexts/faq/context'
import CreateBtn from '../../components/table-components/actions/create-btn'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import FAQTable from '../../components/faq/faq-table'
import FAQDetails from '../../components/faq/faq-details'
import FAQFormDrawer from '../../components/faq/faq-form-drawer'
import InfoDrawer from '../../components/general/info-drawer'
import { getTranslations } from '../../utils/helpers/translations'
import FAQFilters from '../../components/faq/faq-filters'
import { ICreateFAQ } from '../../models/faq/request'
import { checkTranslations } from '../../utils/helpers/constants'
import NotificationContextProvider from '../../contexts/notifications/provider'
import NotificationTable from '../../components/notifications/notification-table'
import NotificationFormDrawer from '../../components/notifications/notification-form-drawer'
import NotificationContext from '../../contexts/notifications/context'
import NotificationFilters from '../../components/notifications/notification-filters'
import NotificationDetails from '../../components/notifications/notification-details'

interface Props {
  route: IRoute
}

const Notifications: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(NotificationContext)

  const onSubmit = async (data: any) => {
    data.message = {
      title: data.title,
      body: data.body,
    }
    delete data['title']
    delete data['body']

    if (
      checkTranslations(data.message.body) === true &&
      checkTranslations(data.message.title) === true
    ) {
      await actions.createPublicNotification(data)
      eventManager.emit(EVENT_SUCCESS)
    } else {
      eventManager.emit(EVENT_ERORR, t('translations_required_message'))
      throw 'tranlations required'
    }
  }

  const [formDrawerVisable, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <CreateBtn
              label='create_public_notification'
              onClick={() => {
                actions.setDetails(undefined)
                setFormDrawerVisable(true)
              }}
            />
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />
      <NotificationFilters />
      <NotificationTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />
      <NotificationFormDrawer
        formId={'notificaton-form'}
        visable={formDrawerVisable}
        loading={loading.includes('create') || loading.includes('update')}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      />
      <InfoDrawer
        title={t('notification_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <NotificationDetails notification={details} />
      </InfoDrawer>
    </>
  )
}

const NotificationsPage: React.FC<Props> = (props) => {
  return (
    <NotificationContextProvider>
      <Notifications {...props} />
    </NotificationContextProvider>
  )
}
export default NotificationsPage
