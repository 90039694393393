import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import FieldBuilder from '../../form-components/field-builder'
import ServiceSelectOptionsContext from '../../../contexts/select-options/service-select-options/context'

interface IProps {
  servicesIdsAlreadySelected: number[]
}

const AddCityServiceForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { list: serviceOptions, loading: serviceOptionsLoading } = useContext(
    ServiceSelectOptionsContext
  )

  return (
    <div>
      {/* Service */}
      <FieldBuilder
        name='serviceId'
        label={t('service')}
        rules={{ required: true }}
        input={{
          type: 'select',
          allowSearch: true,
          loading: serviceOptionsLoading.includes('list'),
          options: serviceOptions?.data
            ?.filter((s) => !props.servicesIdsAlreadySelected.includes(s.id))
            ?.map((service) => {
              return { value: service.id, label: service.name }
            }),
        }}
      />

      <FieldBuilder
        name={'price'}
        label={t('price')}
        input={{ type: 'number' }}
        rules={{ required: true }}
      />

      <FieldBuilder
        name={'note'}
        label={t('note')}
        input={{ type: 'text-area' }}
        rules={{ required: true }}
      />
    </div>
  )
}

export default AddCityServiceForm
