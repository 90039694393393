import { createContext } from 'react'
import { IStaffTypeQuery } from '../../models/staff-type/query'
import {
  ICreateStaffType,
  IUpdateStaffType,
} from '../../models/staff-type/request'
import { IStaffType } from '../../models/staff-type/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type StaffTypeLoading =
  | 'list'
  | 'details'
  | 'create'
  | 'update'
  | 'delete'

export interface IInternalState {
  loading: StaffTypeLoading[]

  list?: IBaseListingResponse<IStaffType>
  query: IStaffTypeQuery

  details?: IStaffType
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IStaffType) => void

    createStaffType: (request: ICreateStaffType) => void
    updateStaffType: (id: number, request: IUpdateStaffType) => void
    deleteStaffType: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IStaffTypeQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createStaffType: DEFAULT_FUNCTION,
    updateStaffType: DEFAULT_FUNCTION,
    deleteStaffType: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const StaffTypeContext = createContext(externalState)

export default StaffTypeContext
