import { createContext } from 'react'
import { IFAQQuery } from '../../models/faq/query'
import { ICreateFAQ, IUpdateFAQ } from '../../models/faq/request'
import { IFAQ } from '../../models/faq/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type FAQLoading = 'list' | 'details' | 'create' | 'update' | 'delete'

export interface IInternalState {
  loading: FAQLoading[]

  list?: IBaseListingResponse<IFAQ>
  query: IFAQQuery

  details?: IFAQ
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IFAQ) => void

    createFAQ: (request: ICreateFAQ) => void
    updateFAQ: (id: number, request: IUpdateFAQ) => void
    deleteFAQ: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IFAQQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createFAQ: DEFAULT_FUNCTION,
    updateFAQ: DEFAULT_FUNCTION,
    deleteFAQ: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const FAQContext = createContext(externalState)

export default FAQContext
