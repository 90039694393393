import { Col, Row } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FieldBuilder from '../../form-components/field-builder'
import BrandContext from '../../../contexts/brand/context'
import FilterCard from '../../general/filter-card'
import Form from '../../form-components/from'
import { INITIAL_PAGE } from '../../../utils/helpers/constants'
import styles from './style.module.scss'
import BrandSelectOptionsContext from '../../../contexts/select-options/brand-select-options/context'

interface IProps {}

const BrandFilters: React.FC<IProps> = () => {
  const { t } = useTranslation()
  const { actions, loading, query } = useContext(BrandContext)

  const {
    list: mainBrands,
    actions: mainBrandsActions,
    loading: mainBrandsLoading,
  } = useContext(BrandSelectOptionsContext)

  useEffect(() => {
    mainBrandsActions.getData()
  }, [])

  return (
    <div className={styles.formContainer}>
      <Form
        formId='brands-filter'
        onSubmit={(data) => {
          const filter: any = {
            page: INITIAL_PAGE,
            perPage: query?.perPage,
            keyword: data?.keyword,
            staticFilters: [
              data?.mainBrands?.length > 0
                ? {
                    name: 'parentId',
                    value: [...(data?.mainBrands ?? [])],
                    // == != > < >= <= in
                    operation: 'in',
                  }
                : undefined,
            ].filter((f) => f),
          }
          actions.setQuery(filter)
        }}
      >
        <FilterCard
          formId='brands-filter'
          applyLoading={loading.includes('list')}
          resetLoading={loading.includes('list')}
          onReset={() => {
            actions.setQuery({
              page: query?.page,
              perPage: query?.perPage,
            })
          }}
        >
          <Row justify={'start'} gutter={[10, 0]} className={styles.filters}>
            <Col span={8}>
              <FieldBuilder
                name='keyword'
                label={t('search')}
                input={{ type: 'text' }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name='mainBrands'
                label={t('main_brands')}
                input={{
                  type: 'multiSelect',
                  loading: mainBrandsLoading.includes('list'),
                  allowSearch: true,

                  options: mainBrands?.data.map((mainBrand) => {
                    return { value: mainBrand.id, label: mainBrand.name }
                  }),
                }}
              />
            </Col>
          </Row>
        </FilterCard>
      </Form>
    </div>
  )
}

export default BrandFilters
