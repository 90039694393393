import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import eventManager, { EVENT_ERORR, EVENT_SUCCESS } from '../../utils/events'
import ProductContextProvider from '../../contexts/product/provider'
import PageHeader from '../../components/general/antd/page-header'
import ProductContext from '../../contexts/product/context'
import CreateBtn from '../../components/table-components/actions/create-btn'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import ProductTable from '../../components/product/product-table'
import ProductDetails from '../../components/product/product-details'
import ProductFormDrawer from '../../components/product/product-form-drawer'
import InfoDrawer from '../../components/general/info-drawer'
import { getTranslations } from '../../utils/helpers/translations'
import ProductFilters from '../../components/product/product-filters'
import { checkTranslations } from '../../utils/helpers/constants'
import CityTable from '../../components/city/city-table'

interface Props {
  route: IRoute
}

const Products: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(ProductContext)

  const onSubmit = async (data: any) => {
    if (
      checkTranslations(data.name) === true &&
      checkTranslations(data.description) === true
    ) {
      details
        ? await actions.updateProduct(details.id, data)
        : await actions.createProduct(data)
      eventManager.emit(EVENT_SUCCESS)
    } else {
      eventManager.emit(EVENT_ERORR, t('translations_required_message'))
      throw 'tranlations required'
    }
  }

  const [formDrawerVisable, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <CreateBtn
              onClick={() => {
                actions.setDetails(undefined)
                setFormDrawerVisable(true)
              }}
            />
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />

      <ProductFilters />

      <ProductTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <ProductFormDrawer
        formId={'product-form'}
        visable={formDrawerVisable}
        defaultValues={
          details && {
            id: details?.id,
            name: getTranslations(details?.translations?.name),
            description: getTranslations(details?.translations?.description),
            imageId: details?.image?.id,
            price: details?.price,
          }
        }
        details={details}
        loading={loading.includes('create') || loading.includes('update')}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      />

      <InfoDrawer
        title={t('product_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <ProductDetails product={details} />
      </InfoDrawer>
    </>
  )
}

const ProductsPage: React.FC<Props> = (props) => {
  return (
    <ProductContextProvider>
      <Products {...props} />
    </ProductContextProvider>
  )
}
export default ProductsPage
