import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import enTranslation from './locales/en/translate.json'
import arTranslation from './locales/ar/translate.json'
import { LANGUAGE_CODE } from '../helpers/constants'

export const resources = {
  en: enTranslation,
  ar: arTranslation,
}

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,

    lng: localStorage.getItem(LANGUAGE_CODE) ?? 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export default i18next
