import { createContext } from 'react'
import { ISubscriptionQuery } from '../../../models/subscription/query'
import { ISubscription } from '../../../models/subscription/response'
import IBaseListingResponse from '../../../utils/api/base-listing-response'
import {
  DEFAULT_FUNCTION,
  DEFAULT_MAXIMUM_PAGE_SIZE,
  INITIAL_PAGE,
} from '../../../utils/helpers/constants'

export type SubscriptionLoading = 'list'

export interface IInternalState {
  loading: SubscriptionLoading[]

  list?: IBaseListingResponse<ISubscription>
  query: ISubscriptionQuery
}

export const internalState: IInternalState = {
  loading: [],
  query: {
    page: INITIAL_PAGE,
    perPage: DEFAULT_MAXIMUM_PAGE_SIZE,
  },
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void

    setSearch: (search?: string) => void
    setQuery: (query: ISubscriptionQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const SubscriptionSelectOptionsContext = createContext(externalState)

export default SubscriptionSelectOptionsContext
