import { IStaffTypeQuery } from '../../models/staff-type/query'
import {
  ICreateStaffType,
  IUpdateStaffType,
} from '../../models/staff-type/request'
import { IStaffType } from '../../models/staff-type/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class StaffTypeService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllStaffTypes = (query: IStaffTypeQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IStaffType>>({
      path: `/dashboard/staff-types/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createStaffType = (data: ICreateStaffType, params?: RequestParams) =>
    this.http.request<IStaffType>({
      path: '/dashboard/staff-types',
      method: 'POST',
      body: data,
      ...params,
    })

  updateStaffType = (
    id: number,
    data: IUpdateStaffType,
    params?: RequestParams
  ) =>
    this.http.request<IStaffType>({
      path: `/dashboard/staff-types/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteStaffType = (id: number, params?: RequestParams) =>
    this.http.request<IStaffType>({
      path: `/dashboard/staff-types/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default StaffTypeService
