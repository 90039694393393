import React from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { AttendanceStatus } from '../../../models/attendance/enum'

interface IProps {}

const AttendanceForm: React.FC<IProps> = () => {
  const { t } = useTranslation()
  return (
    <div>
      {/* Check in status */}
      <FieldBuilder
        name='checkInStatus'
        label={t('check_in_status')}
        // rules={{ required: true }}
        input={{
          type: 'select',
          options: [
            {
              value: AttendanceStatus.NORMAL,
              label: t(`${AttendanceStatus[AttendanceStatus.NORMAL]}`),
            },
            {
              value: AttendanceStatus.EARLY,
              label: t(`${AttendanceStatus[AttendanceStatus.EARLY]}`),
            },
            {
              value: AttendanceStatus.LATE,
              label: t(`${AttendanceStatus[AttendanceStatus.LATE]}`),
            },
          ],
        }}
      />

      {/* Check out status */}
      <FieldBuilder
        name='checkOutStatus'
        label={t('check_out_status')}
        // rules={{ required: true }}
        input={{
          type: 'select',
          options: [
            {
              value: AttendanceStatus.NORMAL,
              label: t(`${AttendanceStatus[AttendanceStatus.NORMAL]}`),
            },
            {
              value: AttendanceStatus.EARLY,
              label: t(`${AttendanceStatus[AttendanceStatus.EARLY]}`),
            },
            {
              value: AttendanceStatus.LATE,
              label: t(`${AttendanceStatus[AttendanceStatus.LATE]}`),
            },
          ],
        }}
      />
    </div>
  )
}

export default AttendanceForm
