import { HttpClient, RequestParams } from '../../utils/api/http-client'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { INotificationQuery } from '../../models/notifications/query'
import { INotifications } from '../../models/notifications/response'
import { ICreatePublicNotification } from '../../models/notifications/request'
import { IClientSubscriptionQuery } from '../../models/client-subscription/query'
import { IClientSubscription } from '../../models/client-subscription/response'

class ClientSubscriptionService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllSubscriptions = (
    query: IClientSubscriptionQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IClientSubscription>>({
      path: `/dashboard/subscriptions/clients-subscriptions`,
      method: 'POST',
      body: query,
      ...params,
    })
}

export default ClientSubscriptionService
