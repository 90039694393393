import React, { useContext, useEffect, useState } from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { languages } from '../../../utils/helpers/constants'
import { Tabs, Divider, Form } from 'antd'
import TranslationsCard from '../../form-components/translations-card'
import ImageUploader from '../../form-components/image-uploader'
import Controller from '../../form-components/controller'
import { MediumFor } from '../../../models/medium/enum'
import { IBrand } from '../../../models/brand/response'
import OldImage from '../../form-components/old-image'
import BrandSelectOptionsContext from '../../../contexts/select-options/brand-select-options/context'

const { TabPane } = Tabs

interface IProps {
  brand?: IBrand
}

const BrandForm: React.FC<IProps> = ({ brand }) => {
  const { t } = useTranslation()
  const { list, actions, loading } = useContext(BrandSelectOptionsContext)

  // Old Image
  const [image, setImage] = useState(brand?.brandImage?.url)

  useEffect(() => {
    brand?.brandImage?.url && setImage(brand?.brandImage?.url)
  }, [brand?.brandImage?.url])

  useEffect(() => {
    actions.getData()
  }, [])

  return (
    <div>
      <TranslationsCard>
        <Tabs type='line'>
          {languages?.map((lang, index) => {
            return (
              <TabPane tab={lang.name} key={index}>
                <FieldBuilder
                  key={index}
                  name={`name[${lang?.code}]`}
                  label={`${t('name')} (${lang?.name})`}
                  input={{ type: 'text' }}
                  rules={{ required: true }}
                />
              </TabPane>
            )
          })}
        </Tabs>
      </TranslationsCard>

      <Divider />
      {/* Parent Id */}
      <FieldBuilder
        name='parentId'
        label={t('main_brand')}
        input={{
          type: 'select',
          allowSearch: true,
          loading: loading.includes('list'),
          options: list?.data?.map((brand) => {
            return { value: brand.id, label: brand.name }
          }),
        }}
      />

      {/* Upload Image */}
      <div>
        <Form.Item label={t('image')} required>
          <Controller
            name='brandImageId'
            // rules={{
            //   required: {
            //     value: true,
            //     message: `${t('field_is_required_message')}`,
            //   },
            // }}
            render={({ field: { ref, ...field } }) => {
              return (
                <ImageUploader
                  {...field}
                  onChange={(response) => {
                    setImage(undefined)
                    field.onChange(response)
                  }}
                  imageFor={MediumFor.BRAND_IMAGE}
                />
              )
            }}
          />
        </Form.Item>
      </div>

      {/* Old  Image */}
      {image && <OldImage src={image} />}
    </div>
  )
}

export default BrandForm
