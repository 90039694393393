import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import eventManager, { EVENT_SUCCESS } from '../../utils/events'
import ClientContextProvider from '../../contexts/client/provider'
import PageHeader from '../../components/general/antd/page-header'
import ClientContext from '../../contexts/client/context'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import ClientTable from '../../components/client/client-table'
import ClientDetails from '../../components/client/client-details'
import ClientFormDrawer from '../../components/client/client-form-drawer'
import InfoDrawer from '../../components/general/info-drawer'
import ClientFilters from '../../components/client/client-filters'

interface Props {
  route: IRoute
}

const Clients: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(ClientContext)

  const onSubmit = async (data: any) => {
    details && (await actions.updateClient(details.id, data))
    eventManager.emit(EVENT_SUCCESS)
  }

  const [formDrawerVisable, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />

      <ClientFilters />

      <ClientTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <ClientFormDrawer
        formId={'client-form'}
        visable={formDrawerVisable}
        defaultValues={
          details && {
            id: details?.id,
            status: details?.status,
            balance: details?.balance,
          }
        }
        details={details}
        loading={loading.includes('create') || loading.includes('update')}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      />

      <InfoDrawer
        title={t('client_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <ClientDetails client={details} />
      </InfoDrawer>
    </>
  )
}

const ClientsPage: React.FC<Props> = (props) => {
  return (
    <ClientContextProvider>
      <Clients {...props} />
    </ClientContextProvider>
  )
}
export default ClientsPage
