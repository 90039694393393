import { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import DashboardLayout from '../components/layouts/dashboard-layout'
import AuthContext from '../contexts/auth/context'
import { dashboardRoutes, renderRoute } from './routes'

interface Props {}
const DashboardRoutes: React.FC<Props> = () => {
  const { userPermissions } = useContext(AuthContext)

  return (
    <DashboardLayout>
      <Switch>
        {dashboardRoutes?.map((route, index) => {
          if (route.hasSubMenus) {
            return route.subMenus?.map((menu, index) => {
              return renderRoute(menu as any, userPermissions)
            })
          } else {
            return renderRoute(route, userPermissions)
          }
        })}
      </Switch>
    </DashboardLayout>
  )
}

export default DashboardRoutes
