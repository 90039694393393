export enum TransactionAdminToStaffStatus {
  REQUESTED = 1,
  CONFIRMED = 2,
}

// Service Status Colors
export const getTransactionAdminToStaffStatusColor = (
  status: TransactionAdminToStaffStatus
) => {
  switch (status) {
    case TransactionAdminToStaffStatus.REQUESTED:
      return 'green'
    case TransactionAdminToStaffStatus.CONFIRMED:
      return 'gray'
    default:
      return 'gray'
  }
}
