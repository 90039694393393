import { ITagQuery } from '../../models/tag/query'
import { ICreateTag, IUpdateTag } from '../../models/tag/request'
import { ITag } from '../../models/tag/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class TagService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllTags = (query: ITagQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ITag>>({
      path: `/dashboard/tags/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createTag = (data: ICreateTag, params?: RequestParams) =>
    this.http.request<ITag>({
      path: '/dashboard/tags',
      method: 'POST',
      body: data,
      ...params,
    })

  updateTag = (id: number, data: IUpdateTag, params?: RequestParams) =>
    this.http.request<ITag>({
      path: `/dashboard/tags/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteTag = (id: number, params?: RequestParams) =>
    this.http.request<ITag>({
      path: `/dashboard/tags/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default TagService
