import React from 'react'
import { useContext } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import StaffContext from '../../../contexts/staff/context'
import { IStaff } from '../../../models/staff/response'
import Space from '../../general/antd/space'
import EditBtn from '../../table-components/actions/edit-btn'
import ViewBtn from '../../table-components/actions/view-btn'
import Table from '../../table-components/table'
import DeleteBtn from '../../table-components/actions/delete-btn'
import eventManager, { EVENT_SUCCESS } from '../../../utils/events'
import { getStaffStatusColor, StaffStatus } from '../../../models/staff/enum'
import { Image, Tag } from 'antd'
import { useState } from 'react'
import { ISort, IStaticFilter } from '../../../models/base/base-query'
import { useEffect } from 'react'
import { tableOnChange } from '../../../utils/helpers/table-sorts-filters'

interface Props {
  showFormDrawer: () => void
  showDetailsDrawer: () => void
}

const StaffTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { list, actions, loading, query } = useContext(StaffContext)

  const columns: ColumnProps<IStaff>[] = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'userId',
      sorter: {
        multiple: 1,
      },
      render: (_, record) => {
        return record?.id
      },
    },
    {
      key: 'name',
      title: t('name'),
      dataIndex: 'name',
      render: (_, record) => {
        return `${record?.user?.firstName} ${record?.user?.lastName}`
      },
      sorter: { multiple: 2 },
    },

    {
      key: 'permanentLocation',
      title: t('permanent_location'),
      dataIndex: 'permanentLocation',
      render: (_, record) => {
        return record?.permanentLocation?.name
      },
    },
    {
      key: 'image',
      title: t('image'),
      dataIndex: 'image',
      render: (_, record) => {
        return (
          <Image
            style={{ objectFit: 'contain' }}
            height='40px'
            preview={false}
            src={record.image.url}
          />
        )
      },
    },
    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      render: (status: StaffStatus) => {
        return (
          status && (
            <Tag color={getStaffStatusColor(status)}>
              {t(`${StaffStatus[status]}`)}
            </Tag>
          )
        )
        // return t(`${StaffStatus[status]}`)
      },
    },
    {
      key: 'deservedCommission',
      title: t('deserved_commission'),
      dataIndex: 'deservedCommission',
      align: 'center',
      render: (_, record) => {
        return `${record?.deservedCommission} ${t('AED')}`
      },
      sorter: {
        multiple: 3,
      },
    },
    {
      key: 'funds',
      title: t('cash_in_hand'),
      dataIndex: 'funds',
      align: 'center',
      render: (_, record) => {
        return `${record?.funds} ${t('AED')}`
      },
      sorter: {
        multiple: 4,
      },
    },
    {
      key: 'actions',
      title: t('actions'),
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Space>
          <ViewBtn
            onClick={() => {
              actions.setDetails(record)
              props.showDetailsDrawer()
            }}
          />
          <EditBtn
            onClick={() => {
              actions.setDetails(record)
              props.showFormDrawer()
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteStaff(record?.id)
              eventManager.emit(EVENT_SUCCESS)
            }}
            loading={loading.includes('delete')}
          />
        </Space>
      ),
    },
  ]

  // Filters && Sorts
  let tableFiltersProps = {}
  const [sorts, setSorts] = useState<ISort[]>([])
  const [, setStaticFilters] = useState<IStaticFilter[]>(
    query?.staticFilters ?? []
  )

  useEffect(() => {
    sorts &&
      actions.setQuery({
        ...query,
        sorts: sorts,
      })
  }, [sorts])

  return (
    <Table
      rowKey='id'
      columns={columns}
      dataSource={list?.data}
      loading={loading.includes('list')}
      showPagination
      total={list?.meta?.total}
      page={query.page}
      pageSize={query.perPage}
      scroll={{ x: 200 }}
      onPaginationChange={(page, pageSize) => {
        actions.setQuery({
          ...query,
          page,
          perPage: pageSize,
        })
      }}
      onChange={(_, tableFilters, tabelSorters) => {
        tableOnChange(
          tableFilters,
          tabelSorters,
          tableFiltersProps,
          setSorts,
          setStaticFilters
        )
      }}
    />
  )
}

export default StaffTable
