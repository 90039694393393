import { createContext } from 'react'
import { IPermanentLocationQuery } from '../../models/permanent-location/query'
import {
  ICreatePermanentLocation,
  IUpdatePermanentLocation,
} from '../../models/permanent-location/request'
import { IPermanentLocation } from '../../models/permanent-location/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type PermanentLocationLoading =
  | 'list'
  | 'details'
  | 'create'
  | 'update'
  | 'delete'

export interface IInternalState {
  loading: PermanentLocationLoading[]

  list?: IBaseListingResponse<IPermanentLocation>
  query: IPermanentLocationQuery

  details?: IPermanentLocation
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IPermanentLocation) => void

    createPermanentLocation: (request: ICreatePermanentLocation) => void
    updatePermanentLocation: (
      id: number,
      request: IUpdatePermanentLocation
    ) => void
    deletePermanentLocation: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IPermanentLocationQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createPermanentLocation: DEFAULT_FUNCTION,
    updatePermanentLocation: DEFAULT_FUNCTION,
    deletePermanentLocation: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const PermanentLocationContext = createContext(externalState)

export default PermanentLocationContext
