import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { execute } from '../../../utils/api/api-execute'
import Form from '../from'
import SubmitBtn from '../submit-btn'
import Button from '../../general/antd/button'
import Modal, { ModalProps } from '../../general/antd/modal'

interface Props<DataType> extends ModalProps {
  hideModal: () => void
  defaultValues?: DataType
  onSubmit: (data: DataType) => void
  formId: string
}

const FormApproveModal = function <DataType>({
  hideModal,
  onSubmit,
  defaultValues,
  formId,
  ...props
}: PropsWithChildren<Props<DataType>>) {
  const { t } = useTranslation()

  const formSubmit = async (data: DataType) => {
    await execute({
      callback: async () => {
        await onSubmit(data)
        hideModal()
      },
      fallback: (error) => {},
      finallyCallback: () => {},
      throwException: false,
    })
  }

  return (
    <Modal
      onCancel={hideModal}
      footer={[
        <Button key='cancle' onClick={() => hideModal()}>
          {t('cancel')}
        </Button>,
        <SubmitBtn key='submit' loading={props.confirmLoading} form={formId}>
          {t('ok')}
        </SubmitBtn>,
      ]}
      modalRender={(node) => {
        return (
          <Form<DataType>
            onSubmit={formSubmit}
            defaultValues={defaultValues}
            formId={formId}
          >
            {node}
          </Form>
        )
      }}
      {...props}
    />
  )
}

export default FormApproveModal
