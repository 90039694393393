import React from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { IOrder } from '../../../models/order/response'

interface IProps {
  order?: IOrder
}

const OrderRejefctForm: React.FC<IProps> = ({ order }) => {
  const { t } = useTranslation()

  return (
    <div>
      <FieldBuilder
        name='note'
        label={t('note')}
        input={{ type: 'text-area' }}
        rules={{ required: true }}
      />
    </div>
  )
}

export default OrderRejefctForm
