import React, { useEffect, useState } from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { languages } from '../../../utils/helpers/constants'
import { Tabs, Divider, Input } from 'antd'
import TranslationsCard from '../../form-components/translations-card'
import { Controller } from 'react-hook-form'
import { SketchPicker } from 'react-color'
const { TabPane } = Tabs

interface IProps {}

const CarColorForm: React.FC<IProps> = () => {
  const { t } = useTranslation()

  return (
    <div>
      <TranslationsCard>
        <Tabs type='line'>
          {languages?.map((lang, index) => {
            return (
              <TabPane tab={lang.name} key={index}>
                <FieldBuilder
                  key={index}
                  name={`name[${lang?.code}]`}
                  label={`${t('name')} (${lang?.name})`}
                  input={{ type: 'text' }}
                  rules={{ required: true }}
                />
              </TabPane>
            )
          })}
        </Tabs>
      </TranslationsCard>

      <Divider />
      <span style={{ textAlign: 'center' }}>
        <FieldBuilder
          name='hex'
          label={t('pick_color')}
          input={{ type: 'color-picker' }}
          rules={{ required: true }}
        />
      </span>
    </div>
  )
}

export default CarColorForm
