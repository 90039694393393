import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import {
  Card,
  Descriptions as AntdDescriptions,
  Divider,
  Input,
  List,
  Tabs,
  Timeline,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getOrderProgressStatusColor,
  OrderPaymentMethod,
  OrderProgressStatus,
  OrderStatus,
  OrderUserType,
} from "../../../models/order/enum";
import { IOrder } from "../../../models/order/response";
import { formatDate } from "../../../utils/helpers/functions";
import Descriptions from "../../general/antd/descriptions";
import Image from "../../general/antd/image/index";
import ColorCircle from "../../general/color-circle";
import MapBox from "../../general/mapbox";
import styles from "./style.module.scss";
const { TabPane } = Tabs;
const { Text } = Typography;

interface Props {
  order?: IOrder;
}

const OrderDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [services, setServices] = useState(props.order?.services);
  const [orderCars, setOrderCars] = useState(props.order?.orderCars);
  const [orderStaffCars, setOrderStaffCars] = useState(
    props.order?.orderStaffCars
  );

  useEffect(() => {
    props.order?.services && setServices(props.order?.services);
    props.order?.orderCars && setOrderCars(props.order?.orderCars);
    props.order?.orderStaffCars &&
      setOrderStaffCars(props.order?.orderStaffCars);
  }, [props.order?.services]);

  return (
    <>
      <Descriptions size="middle" column={1} bordered>
        <AntdDescriptions.Item label={t("id")}>
          {props.order?.id}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("client")}>
          {props.order?.clientName}
        </AntdDescriptions.Item>
        {props.order?.userType === 2 && (
          <AntdDescriptions.Item label={t("phone")}>
            {props?.order?.clientPhone}
          </AntdDescriptions.Item>
        )}

        {props.order?.clientAddress && (
          <AntdDescriptions.Item label={t("client_address")}>
            <div style={{ height: "20rem", minWidth: "20rem" }}>
              <MapBox
                coordinates={[
                  props.order?.clientAddress?.lng,
                  props.order?.clientAddress?.lat,
                ]}
              />
            </div>
          </AntdDescriptions.Item>
        )}
        <AntdDescriptions.Item label={t("total_value")}>
          {`${props.order?.totalValue} (${t("AED")})`}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("rating")}>
          {props.order?.rating}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("is_flexi")}>
          {t(`${props.order?.isFlexi}`)}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("required_date")}>
          {props.order?.requiredDate}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("required_time")}>
          {props.order?.requiredTime}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("estimated_time")}>
          {props.order?.estimatedTime}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("main_value")}>
          {props.order?.mainValue}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("payment_method")}>
          {props.order?.paymentMethod &&
            t(`${OrderPaymentMethod[props.order.paymentMethod]}`)}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("user_type")}>
          {props.order?.userType && t(`${OrderUserType[props.order.userType]}`)}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("status")}>
          {props?.order?.status && t(`${OrderStatus[props?.order?.status]}`)}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("staff")}>
          {props.order?.staff &&
            `${props.order?.staff?.user?.firstName} ${props.order?.staff?.user?.lastName}`}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("city")}>
          {props.order?.city?.name}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("permanent_location")}>
          {props.order?.permanentLocation?.name}
        </AntdDescriptions.Item>

        {props.order?.subscription && (
          <AntdDescriptions.Item label={t("subscription")}>
            {t(
              `${props.order?.subscription?.mainName} ${props.order?.subscription?.subName}`
            )}
          </AntdDescriptions.Item>
        )}

        <AntdDescriptions.Item label={t("image_before")}>
          <Image preview src={props.order?.imageBefore?.url} />
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("image_after")}>
          <Image preview src={props.order?.imageAfter?.url} />
        </AntdDescriptions.Item>
      </Descriptions>
      <Tabs defaultActiveKey="1" animated type="line">
        {/* Services */}
        <TabPane tab={t("services")} key="1">
          <List
            header={
              <Input
                placeholder={t("search_hint")}
                suffix={<SearchOutlined />}
                allowClear
                onChange={(event) => {
                  let searchKey = event.target.value;

                  let filteredServices = props.order?.services?.filter(
                    (service) => service?.name?.includes(searchKey)
                  );

                  setServices(filteredServices);

                  if (searchKey == "") {
                    setServices(props.order?.services);
                  }
                }}
              />
            }
            bordered
            dataSource={services ?? []}
            renderItem={(item) => (
              <List.Item
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  // flexWrap: 'wrap',
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <Image src={item?.activeImage?.url} />
                <span>{item?.name}</span>
                {/* <span style={{ width: '100%' }}></span> */}
                <Divider type="vertical" />

                <div>{`${item.additionalInformation}`}</div>
              </List.Item>
            )}
          />
        </TabPane>
        {/* Order Cars */}

        {props.order?.userType === OrderUserType.CLIENT ? (
          <TabPane tab={t("order_cars")} key="2">
            <List
              header={
                <Input
                  placeholder={t("search_hint")}
                  suffix={<SearchOutlined />}
                  allowClear
                  onChange={(event) => {
                    let searchKey = event.target.value;

                    let filteredOrderCars = props.order?.orderCars?.filter(
                      (orderCar) => orderCar?.brand?.name?.includes(searchKey)
                    );

                    setOrderCars(filteredOrderCars);

                    if (searchKey == "") {
                      setOrderCars(props.order?.orderCars);
                    }
                  }}
                />
              }
              bordered
              dataSource={orderCars ?? []}
              renderItem={(item) => (
                <List.Item
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <div className={styles.carLogoContainer}>
                    <img
                      className={styles.carLogo}
                      src={item?.brand?.parentBrand?.brandImage?.url}
                    />
                  </div>
                  {`${item?.brand?.parentBrand?.name} - ${item?.brand?.name} (${item?.year})`}
                  <div className={styles.colorCircle}>
                    <ColorCircle color={item?.carColor?.hex} />
                  </div>
                </List.Item>
              )}
            />
          </TabPane>
        ) : (
          <TabPane tab={t("order_staff_cars")} key="2">
            <List
              header={
                <Input
                  placeholder={t("search_hint")}
                  suffix={<SearchOutlined />}
                  allowClear
                  onChange={(event) => {
                    let searchKey = event.target.value;

                    let filteredOrderStaffCars =
                      props.order?.orderStaffCars?.filter((orderStaffCars) =>
                        orderStaffCars?.brand?.name?.includes(searchKey)
                      );

                    setOrderStaffCars(filteredOrderStaffCars);

                    if (searchKey == "") {
                      setOrderStaffCars(props.order?.orderStaffCars);
                    }
                  }}
                />
              }
              bordered
              dataSource={orderStaffCars ?? []}
              renderItem={(item) => (
                <List.Item
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <div className={styles.carLogoContainer}>
                    <img
                      className={styles.carLogo}
                      src={item?.brand?.parentBrand?.brandImage?.url}
                    />
                  </div>
                  {`${item?.brand?.parentBrand?.name} - ${item?.brand?.name} (${item?.year})`}
                  <div className={styles.colorCircle}>
                    <ColorCircle color={item?.carColor?.hex} />
                  </div>
                  <span> | {item?.plateNumber}</span>
                </List.Item>
              )}
            />
          </TabPane>
        )}

        {/* Order Progress */}
        <TabPane tab={t("order_history")} key="3">
          <Card title={t("history_of_order_status")}>
            <Timeline mode="left" style={{ paddingTop: "2rem" }}>
              {props?.order?.orderProgress?.map((orderProgres, index) => {
                return (
                  <Timeline.Item
                    key={index}
                    color={getOrderProgressStatusColor(
                      orderProgres?.progressStatus
                    )}
                    label={formatDate(orderProgres?.createdAt)}
                  >
                    <>
                      <div>
                        {t(
                          `${OrderProgressStatus[orderProgres?.progressStatus]}`
                        )}
                      </div>
                      <Text type="secondary">
                        <span>{t("note")}: </span>
                        {orderProgres?.progressNote}
                      </Text>
                      <Text type="secondary" style={{ display: "block" }}>
                        <span>{t("user")}: </span>
                        {orderProgres?.userModifiedName}
                      </Text>
                    </>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Card>
        </TabPane>
      </Tabs>
    </>
  );
};

export default OrderDetails;
