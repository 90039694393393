import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import FormDrawer from '../../form-components/form-drawer/index'
import PlateCityForm from '../plate-city-form'
import { IPlateCity } from '../../../models/plate-cities/response'

interface Props<DataType> {
  formId: string
  loading: boolean
  visable: boolean
  hideDrawer: () => void
  defaultValues?: DataType
  details?: IPlateCity
  onSubmit: (data: DataType) => void
}

const PlateCityFormDrawer = function <DataType>(
  props: PropsWithChildren<Props<DataType>>
) {
  const { t } = useTranslation()

  return (
    <FormDrawer<DataType>
      defaultValues={props.defaultValues}
      title={props.details ? t('update_plate_city') : t('create_plate_city')}
      onSubmit={props.onSubmit}
      hideDrawer={props.hideDrawer}
      visible={props.visable}
      formId={props.formId}
      confirmLoading={props.loading}
      children={<PlateCityForm plateCity={props?.details} />}
    />
  )
}

export default PlateCityFormDrawer
