import { IPermanentLocationQuery } from '../../models/permanent-location/query'
import {
  ICreatePermanentLocation,
  IUpdatePermanentLocation,
} from '../../models/permanent-location/request'
import { IPermanentLocation } from '../../models/permanent-location/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class PermanentLocationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllPermanentLocations = (
    query: IPermanentLocationQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IPermanentLocation>>({
      path: `/dashboard/permanent-locations/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createPermanentLocation = (
    data: ICreatePermanentLocation,
    params?: RequestParams
  ) =>
    this.http.request<IPermanentLocation>({
      path: '/dashboard/permanent-locations',
      method: 'POST',
      body: data,
      ...params,
    })

  updatePermanentLocation = (
    id: number,
    data: IUpdatePermanentLocation,
    params?: RequestParams
  ) =>
    this.http.request<IPermanentLocation>({
      path: `/dashboard/permanent-locations/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deletePermanentLocation = (id: number, params?: RequestParams) =>
    this.http.request<IPermanentLocation>({
      path: `/dashboard/permanent-locations/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default PermanentLocationService
