import React from 'react'
import { Button, Drawer, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

interface IInfoDrawer {
  title: string
  visible: boolean
  toggleVisible: (visible: boolean) => void
  loading?: boolean
}

const InfoDrawer: React.FC<IInfoDrawer> = ({
  children,
  title,
  visible,
  toggleVisible,
  loading,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  return (
    <Drawer
      title={title}
      visible={visible}
      closable
      onClose={() => toggleVisible(false)}
      width='50vw'
      placement={language === 'ar' ? 'left' : 'right'}
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={() => toggleVisible(false)} type='primary'>
            {t('ok')}
          </Button>
        </div>
      }
    >
      <Spin spinning={loading}>{children}</Spin>
    </Drawer>
  )
}

export default InfoDrawer
