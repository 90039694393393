import { useContext } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import UserContext from '../../../contexts/user/context'
import { IUser } from '../../../models/user/response'
import Space from '../../general/antd/space'
import EditBtn from '../../table-components/actions/edit-btn'
import ViewBtn from '../../table-components/actions/view-btn'
import Table from '../../table-components/table'
import DeleteBtn from '../../table-components/actions/delete-btn'
import eventManager, { EVENT_SUCCESS } from '../../../utils/events'
import Switch from '../../general/antd/switch'

interface Props {
  showFormModal: () => void
  showDetailsModal: () => void
}

const UserTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { list, actions, loading } = useContext(UserContext)

  const columns: ColumnProps<IUser>[] = [
    {
      key: 'userName',
      title: t('user_name'),
      dataIndex: 'userName',
    },

    // {
    //   key: 'isAdmin',
    //   title: t('isAdmin'),
    //   dataIndex: 'isAdmin',
    //   align: 'center',
    //   render: (isAdmin: boolean, record) => {
    //     return (
    //       <Switch
    //         checked={isAdmin}
    //         loading={loading.includes('update')}
    //         onChange={async (checked) => {
    //           await actions.updateUser(record?.id, {
    //             ...record,
    //             isAdmin: checked,
    //           })
    //         }}
    //       />
    //     )
    //   },
    // },
    {
      key: 'isActive',
      title: t('isActive'),
      dataIndex: 'isActive',
      align: 'center',
      render: (isActive: boolean, record) => {
        return (
          <Switch
            checked={isActive}
            loading={loading.includes('update')}
            onChange={async (checked) => {
              await actions.updateUser(record?.id, {
                ...record,
                isActive: checked,
              })
            }}
          />
        )
      },
    },
    // {
    //   key: 'isDefault',
    //   title: t('isDefault'),
    //   dataIndex: 'isDefault',
    //   align: 'center',
    //   render: (isDefault: boolean) => {
    //     return <StatusTag value={isDefault} boolean />
    //   },
    // },
    {
      key: 'actions',
      title: t('actions'),
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Space>
          <ViewBtn
            onClick={() => {
              actions.setDetails(record)
              props.showDetailsModal()
            }}
          />
          <EditBtn
            onClick={() => {
              actions.setDetails(record)
              props.showFormModal()
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteUser(record?.id)
              eventManager.emit(EVENT_SUCCESS)
            }}
            loading={loading.includes('delete')}
          />
        </Space>
      ),
    },
  ]

  return (
    <Table<IUser>
      rowKey='guid'
      columns={columns}
      dataSource={list}
      scroll={{ x: 200 }}
      loading={loading.includes('list')}
    />
  )
}

export default UserTable
