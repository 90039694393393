import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import FormDrawer from '../../form-components/form-drawer/index'
import BrandForm from '../brand-form'
import { IBrand } from '../../../models/brand/response'
import BrandSelectOptionsContextProvider from '../../../contexts/select-options/brand-select-options/provider'

interface Props<DataType> {
  formId: string
  loading: boolean
  visable: boolean
  hideDrawer: () => void
  defaultValues?: DataType
  details?: IBrand
  onSubmit: (data: DataType) => void
}

const BrandFormDrawer = function <DataType>(
  props: PropsWithChildren<Props<DataType>>
) {
  const { t } = useTranslation()

  return (
    <BrandSelectOptionsContextProvider>
      <FormDrawer<DataType>
        defaultValues={props.defaultValues}
        title={props.details ? t('update_brand') : t('create_brand')}
        onSubmit={props.onSubmit}
        hideDrawer={props.hideDrawer}
        visible={props.visable}
        formId={props.formId}
        confirmLoading={props.loading}
        children={<BrandForm brand={props?.details} />}
      />
    </BrandSelectOptionsContextProvider>
  )
}

export default BrandFormDrawer
