/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from 'react'
import AuthContext, { internalState } from './context'
import reducer from './reducer'
import { useHistory } from 'react-router-dom'
import { httpclient } from '../../services/http-client'
import { ACCESS_TOKEN } from '../../utils/helpers/constants'
import { execute } from '../../utils/api/api-execute'
import EndPoints from '../../services/end-points'
import { IChangePassword, ILoginRequest } from '../../models/auth/request'
import {
  errorNotification,
  successNotification,
} from '../../utils/helpers/notification'
import { useTranslation } from 'react-i18next'
import eventManager, {
  EVENT_ERORR,
  EVENT_FORBIDDEN,
  EVENT_SUCCESS,
  EVENT_UNAOUTHORIZED,
} from '../../utils/events'
import { guestPathes, LOGIN_PAGE, Path } from '../../pages/paths'

const AuthContextProvider: React.FC = (props) => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(reducer, internalState)

  /**
   * Check if there is access token
   */
  const history = useHistory()
  /**
   * Events
   */
  useEffect(() => {
    eventManager.on(EVENT_SUCCESS, (message?: string) => {
      successNotification(message ?? t('operationDoneSuccessfully'))
    })
    eventManager.on(EVENT_ERORR, (message) => {
      errorNotification(message)
    })
    eventManager.on(EVENT_UNAOUTHORIZED, () => {
      history.replace('/auth/login')
    })
    eventManager.on(EVENT_FORBIDDEN, () => {
      history.replace('/403')
    })
  }, [])

  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN)
    console.log('token', token)

    if (token) {
      console.log('entered')

      httpclient.setSecurityData({ token })
      me()
    } else {
      console.log('else')

      localStorage.removeItem(ACCESS_TOKEN)
      if (!guestPathes.includes(history.location.pathname as Path)) {
        history.replace(LOGIN_PAGE)
      }
    }
  }, [state.isAuthenticated])

  useEffect(() => {
    if (state.isAuthenticated) {
      successNotification(
        t('loggedIn'),
        t('welcomeMessage', { username: state.authUser?.username })
      )
    }
  }, [state.isAuthenticated])

  // Me
  const me = async () => {
    await execute({
      callback: async () => {
        const { data } = await EndPoints.auth.me()

        dispatch({
          type: 'LOGIN',
          payload: { user: data },
        })
      },
      fallback: (error) => {
        dispatch({ type: 'LOGOUT' })
      },
      finallyCallback: () => {},
      throwException: false,
    })
  }

  // Login
  const login = async (request: ILoginRequest) => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'login' } })

        const { data } = await EndPoints.auth.login(request)

        dispatch({
          type: 'LOGIN',
          payload: { user: data.user },
        })

        localStorage.setItem(ACCESS_TOKEN, data.token)

        httpclient.setSecurityData({
          token: data.token,
        })
      },
      fallback: (error) => {
        dispatch({ type: 'LOGOUT' })
      },
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'login' } })
      },
      throwException: false,
    })
  }

  // Logout
  const logout = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'logout' } })

        successNotification(t('loggedOut'), t('goodbyeMessage'))

        dispatch({ type: 'LOGOUT' })

        localStorage.removeItem(ACCESS_TOKEN)
        httpclient.setSecurityData({
          token: undefined,
        })

        history.replace(LOGIN_PAGE)
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'logout' } })
      },
      throwException: false,
    })
  }

  // Logout
  const changePassword = async (request: IChangePassword) => {
    await execute({
      callback: async () => {
        await EndPoints.auth.changePassword(request)
        // dispatch({ type: 'LOADING', payload: { loading: 'logout' } })

        // successNotification(t('loggedOut'), t('goodbyeMessage'))

        // dispatch({ type: 'LOGOUT' })

        // localStorage.removeItem(ACCESS_TOKEN)
        // httpclient.setSecurityData({
        //   token: undefined,
        // })

        // history.replace(LOGIN_PAGE)
      },
      fallback: (error) => {},
      finallyCallback: () => {
        // dispatch({ type: 'LOADING', payload: { loading: 'logout' } })
      },
      throwException: false,
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        actions: {
          login,
          changePassword,
          logout,
        },
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
