import React, { Fragment, useState } from 'react'
import { Collapse, Space } from 'antd'
import cn from 'classnames'
import Button from '../../general/antd/button'
import {
  FilterOutlined,
  ClearOutlined,
  CaretRightOutlined,
} from '@ant-design/icons'
import SubmitBtn from '../../form-components/submit-btn'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

import styles from './style.module.scss'

const { Panel } = Collapse

interface IProps {
  onReset: () => void
  applyLoading: boolean
  resetLoading: boolean
  formId: string
}

const FilterCard: React.FC<IProps> = ({
  children,
  onReset,
  applyLoading,
  resetLoading,
  formId,
}) => {
  const { t } = useTranslation()
  const [showFilters, setShowFilters] = useState(false)

  const { reset } = useFormContext()

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      className={cn('shadow', styles.collapse)}
      onChange={(key) => {
        setShowFilters(key.includes('1'))
      }}
    >
      <Panel
        key='1'
        header={t('filters')}
        extra={
          <Fragment>
            {showFilters && (
              <Space>
                <Button
                  danger
                  type='ghost'
                  loading={resetLoading}
                  icon={<ClearOutlined />}
                  onClick={(e) => {
                    e.stopPropagation()
                    reset()
                    onReset()
                  }}
                >
                  {t('reset')}
                </Button>
                <SubmitBtn
                  icon={<FilterOutlined />}
                  loading={applyLoading}
                  onClick={(e) => e.stopPropagation()}
                  form={formId}
                >
                  {t('apply')}
                </SubmitBtn>
              </Space>
            )}
          </Fragment>
        }
      >
        {children}
      </Panel>
    </Collapse>
  )
}

export default FilterCard
