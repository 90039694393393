import { Descriptions as AntdDescriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { ICarColor } from '../../../models/car-color/response'
import Descriptions from '../../general/antd/descriptions'
import ColorCircle from '../../general/color-circle'

interface Props {
  carColor?: ICarColor
}

const CarColorDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')} style={{ textAlign: 'center' }}>
        {props.carColor?.id}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('name')} style={{ textAlign: 'center' }}>
        {props.carColor?.name}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('color')} style={{ textAlign: 'center' }}>
        <ColorCircle color={props.carColor?.hex} />
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default CarColorDetails
