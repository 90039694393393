import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import OrderContext from "../../../contexts/order/context";
import { IOrder } from "../../../models/order/response";
import Space from "../../general/antd/space";
import ViewBtn from "../../table-components/actions/view-btn";
import Table from "../../table-components/table";
import DeleteBtn from "../../table-components/actions/delete-btn";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import ApproveBtn from "../../table-components/actions/approve-btn";
import { TagOutlined } from "@ant-design/icons";

import RejectBtn from "../../table-components/actions/reject-btn";
import {
  getOrderStatusColor,
  OrderStatus,
  OrderUserType,
} from "../../../models/order/enum";
import { Button, Tag, Tooltip } from "antd";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";

interface Props {
  showApproveFormModal: () => void;
  showRejectFormModal: () => void;
  showDetailsDrawer: () => void;
}

const OrderTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { list, actions, loading, query } = useContext(OrderContext);

  const columns: ColumnProps<IOrder>[] = [
    {
      key: "id",
      title: t("id"),
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
    },
    {
      key: "clientName",
      title: t("client"),
      dataIndex: "clientName",
      render: (_, record: any) => {
        return OrderUserType[record.userType] === "STAFF"
          ? record.clientName
          : `${record?.createdBy?.user?.firstName} ${record?.createdBy?.user?.lastName}`;
      },
      sorter: {
        multiple: 1,
      },
    },
    {
      key: "staff",
      title: t("staff"),
      dataIndex: "staff",

      render: (_, record) => {
        return (
          record.staff &&
          `${record?.staff?.user?.firstName} ${record?.staff?.user?.lastName}`
        );
      },
    },

    {
      key: "status",
      title: t("status"),

      dataIndex: "status",
      render: (status: OrderStatus) => {
        return (
          status && (
            <Tag color={getOrderStatusColor(status)}>
              {t(`${OrderStatus[status]}`)}
            </Tag>
          )
        );
      },
    },
    {
      key: "totalValue",
      title: t("total_value"),
      dataIndex: "totalValue",
      render: (_, record) => {
        return record.totalValue && `${record?.totalValue} (${t("AED")}) `;
      },
    },
    {
      key: "createdAt",
      title: t("createdAt"),
      dataIndex: "createdAt",
    },

    {
      key: "actions",
      title: t("actions"),
      align: "center",
      width: 200,
      render: (_, record) => (
        <Space>
          <ViewBtn
            onClick={() => {
              actions.setDetails(record);
              props.showDetailsDrawer();
            }}
          />
          <Tooltip title={t("ticket")}>
            <a href={record?.ticket} target="_blank" rel="noreferrer">
              <Button
                type="link"
                shape="circle"
                icon={<TagOutlined />}
              ></Button>
            </a>
          </Tooltip>
          <ApproveBtn
            onClick={() => {
              actions.setDetails(record);
              props.showApproveFormModal();
            }}
            disabled={record?.status !== OrderStatus.PENDING}
            loading={loading.includes("update")}
          />
          <RejectBtn
            onClick={() => {
              actions.setDetails(record);
              props.showRejectFormModal();
            }}
            disabled={
              record?.status === OrderStatus.STARTED ||
              record?.status === OrderStatus.COMPLETED ||
              record?.status === OrderStatus.PAID ||
              record?.status === OrderStatus.CARD_DECLINED ||
              record?.status === OrderStatus.REJECTED
            }
            loading={loading.includes("update")}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteOrder(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(
    query?.staticFilters ?? []
  );

  useEffect(() => {
    sorts &&
      actions.setQuery({
        ...query,
        sorts: sorts,
      });
  }, [sorts]);

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={list?.data}
      loading={loading.includes("list")}
      showPagination
      total={list?.meta?.total}
      page={query.page}
      pageSize={query.perPage}
      scroll={{ x: 200 }}
      onPaginationChange={(page, pageSize) => {
        actions.setQuery({
          ...query,
          page,
          perPage: pageSize,
        });
      }}
      onChange={(_, tableFilters, tabelSorters) => {
        tableOnChange(
          tableFilters,
          tabelSorters,
          tableFiltersProps,
          setSorts,
          setStaticFilters
        );
      }}
    />
  );
};

export default OrderTable;
