import { createContext } from 'react'
import { IStaffQuery } from '../../models/staff/query'
import { ICreateStaff, IUpdateStaff } from '../../models/staff/request'
import { IStaff } from '../../models/staff/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type StaffLoading = 'list' | 'details' | 'create' | 'update' | 'delete'

export interface IInternalState {
  loading: StaffLoading[]

  list?: IBaseListingResponse<IStaff>
  query: IStaffQuery

  details?: IStaff
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IStaff) => void

    createStaff: (request: ICreateStaff) => void
    updateStaff: (id: number, request: IUpdateStaff) => void
    deleteStaff: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IStaffQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createStaff: DEFAULT_FUNCTION,
    updateStaff: DEFAULT_FUNCTION,
    deleteStaff: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const StaffContext = createContext(externalState)

export default StaffContext
