import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import LoadingPage from './components/general/loading-page'
import './assets/styles/custom.less'
import './index.css'

ReactDOM.render(
  <BrowserRouter basename={process.env.REACT_APP_BASE_HOME_PATH}>
    <Suspense fallback={<LoadingPage />}>
        <React.StrictMode>
        <App />
      </React.StrictMode>
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
