import React from 'react'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Input } from 'antd'
import { ErrorMessage } from '@hookform/error-message'

import styles from './style.module.scss'
import FieldBuilder from '../../form-components/field-builder'
import FormItem from '../../general/form-item'

const ChangePasswordForm: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useFormContext()
  const { t } = useTranslation()
  const onSubmit = async (data: any) => {}
  return (
    <>
      {/* Old Password */}
      <FormItem label={t('old_password')}>
        <Controller
          render={({ field }) => {
            return <Input type='password' {...field} />
          }}
          name='oldPassword'
          control={control}
          rules={{
            required: `${t('field_is_required_message')}`,
            minLength: {
              value: 8,
              message: t('invalid_password'),
            },
          }}
        />
        <ErrorMessage
          errors={errors}
          name='oldPassword'
          render={({ message }) => <p className={styles.alert}>{message}</p>}
        />
      </FormItem>
      {/* </FormItem> */}

      {/* New Password */}
      <FormItem label={t('new_password')}>
        <Controller
          control={control}
          name='password'
          render={({ field }) => {
            return <Input type='password' {...field} />
          }}
          rules={{
            required: `${t('field_is_required_message')}`,
            minLength: {
              value: 8,
              message: t('invalid_password'),
            },
            maxLength: {
              value: 32,
              message: t('invalid_password'),
            },
          }}
        />

        <ErrorMessage
          errors={errors}
          name='password'
          render={({ message }) => <p className={styles.alert}>{message}</p>}
        />
      </FormItem>

      {/* Confirm New Password */}
      <FormItem label={t('confirm_new_password')}>
        <Controller
          render={({ field }) => {
            return <Input type='password' {...field} />
          }}
          name='confirmPassword'
          control={control}
          rules={{
            required: `${t('field_is_required_message')}`,
            minLength: {
              value: 8,
              message: t('invalid_password'),
            },
            maxLength: {
              value: 32,
              message: t('invalid_password'),
            },
            validate: (value) => {
              return (
                value === getValues('password') ||
                `${t('passwords_mismatched')}`
              )
            },
          }}
        />

        <ErrorMessage
          errors={errors}
          name='confirmPassword'
          render={({ message }) => <p className={styles.alert}>{message}</p>}
        />
      </FormItem>
    </>
  )
}

export default ChangePasswordForm
