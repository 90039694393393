import { IClientQuery } from '../../models/client/query'
import { IUpdateClient } from '../../models/client/request'
import { IClient } from '../../models/client/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class ClientService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllClients = (query: IClientQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IClient>>({
      path: `/dashboard/clients/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  updateClient = (id: number, data: IUpdateClient, params?: RequestParams) =>
    this.http.request<IClient>({
      path: `/dashboard/clients/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteClient = (id: number, params?: RequestParams) =>
    this.http.request<IClient>({
      path: `/dashboard/clients/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default ClientService
