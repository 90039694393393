import { createContext } from 'react'
import { IBrandQuery } from '../../../models/brand/query'
import { IBrand } from '../../../models/brand/response'
import IBaseListingResponse from '../../../utils/api/base-listing-response'
import {
  DEFAULT_FUNCTION,
  DEFAULT_MAXIMUM_PAGE_SIZE,
  INITIAL_PAGE,
} from '../../../utils/helpers/constants'

export type BrandLoading = 'list'

export interface IInternalState {
  loading: BrandLoading[]

  list?: IBaseListingResponse<IBrand>
  query: IBrandQuery
}

export const internalState: IInternalState = {
  loading: [],
  query: {
    page: INITIAL_PAGE,
    perPage: DEFAULT_MAXIMUM_PAGE_SIZE,
  },
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void

    setSearch: (search?: string) => void
    setQuery: (query: IBrandQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const BrandSelectOptionsContext = createContext(externalState)

export default BrandSelectOptionsContext
