import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import FormDrawer from '../../form-components/form-drawer/index'
import { ISubscription } from '../../../models/subscription/response'
import SubscriptionContextProvider from '../../../contexts/subscription/provider'
import SubscriptionForm from '../subcription-form'
import ServiceSelectOptionsContextProvider from '../../../contexts/select-options/service-select-options/provider'
import CitySelectOptionsContextProvider from '../../../contexts/select-options/city-select-options/provider'

interface Props<DataType> {
  formId: string
  loading: boolean
  visable: boolean
  hideDrawer: () => void
  defaultValues?: DataType
  details?: ISubscription
  onSubmit: (data: DataType) => void
}

const SubscriptionFormDrawer = function <DataType>(
  props: PropsWithChildren<Props<DataType>>
) {
  const { t } = useTranslation()

  return (
    <SubscriptionContextProvider>
      <ServiceSelectOptionsContextProvider>
        <CitySelectOptionsContextProvider>
          <FormDrawer<DataType>
            defaultValues={props.defaultValues}
            title={
              props.details
                ? t('update_subscription')
                : t('create_subscription')
            }
            onSubmit={props.onSubmit}
            hideDrawer={props.hideDrawer}
            visible={props.visable}
            formId={props.formId}
            confirmLoading={props.loading}
            children={<SubscriptionForm subscription={props?.details} />}
          />
        </CitySelectOptionsContextProvider>
      </ServiceSelectOptionsContextProvider>
    </SubscriptionContextProvider>
  )
}

export default SubscriptionFormDrawer
