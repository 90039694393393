/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from 'react'
import en from '../../utils/localization/locales/en/en-locale'
import ar from '../../utils/localization/locales/ar/ar-locale'
import AppContext, { internalState } from './context'
import reducer from './reducer'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { ConfigProvider, notification } from 'antd'
import useMediaQuery from 'use-media-antd-query'
import { IconContext } from 'react-icons'
import { httpclient } from '../../services/http-client'
import { ACCESS_TOKEN, LANGUAGE_CODE } from '../../utils/helpers/constants'

const AppContextProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState)

  const screenSize = useMediaQuery()

  useEffect(() => {
    if (screenSize === 'sm' || screenSize === 'xs') {
      dispatch({ type: 'SET_SCREEN_TYPE', payload: { screenType: 'Mobile' } })
    } else {
      dispatch({ type: 'SET_SCREEN_TYPE', payload: { screenType: 'Laptop' } })
    }
  }, [screenSize])

  /**
   * Langauge
   */
  const {
    i18n: { language },
  } = useTranslation()

  useEffect(() => {
    dispatch({
      type: 'SET_DIRECTION',
      payload: { direction: i18next.dir(language) },
    })

    const locale = language === 'ar' ? ar : en
    dispatch({ type: 'SET_LOCALE', payload: { locale } })

    httpclient.instance.interceptors.request.use((config) => {
      config.headers['accept-language'] = localStorage.getItem(LANGUAGE_CODE)

      return config
    })

    // httpclient.instance = axios.create({
    //   ...httpclient.instance,
    //   baseURL: process.env.REACT_APP_BASE_API_URL,
    //   headers: {
    //     'accept-language': localStorage.getItem('i18nextLng'),
    //   },
    // })
  }, [language])

  useEffect(() => {
    document.body.classList.remove('rtl', 'ltr')
    state.direction && document.body.classList.add(state.direction)

    notification.config({
      placement: state.direction === 'rtl' ? 'bottomLeft' : 'bottomRight',
      rtl: state.direction === 'rtl',
    })
  }, [state.direction])

  return (
    <ConfigProvider locale={state.locale} direction={state.direction}>
      <IconContext.Provider value={{ className: 'react-icon-class' }}>
        <AppContext.Provider
          value={{
            ...state,
            actions: {},
          }}
        >
          {props.children}
        </AppContext.Provider>
      </IconContext.Provider>
    </ConfigProvider>
  )
}

export default AppContextProvider
