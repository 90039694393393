import { IStaffTypeQuery } from '../../../models/staff-type/query'
import { IStaffType } from '../../../models/staff-type/response'
import IBaseListingResponse from '../../../utils/api/base-listing-response'
import { toggleLoading } from '../../../utils/helpers/functions'
import { IInternalState, StaffTypeLoading } from './context'
import paramsReducer, { QueryAction } from '../../base/base-reducer'

type Action =
  | {
      type: 'LOADING'
      payload: { loading: StaffTypeLoading | StaffTypeLoading[] }
    }
  | { type: 'SET_LIST'; payload: { list: IBaseListingResponse<IStaffType> } }
  | QueryAction<IStaffTypeQuery>

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      }
    case 'SET_LIST':
      return {
        ...state,
        list: action.payload.list,
      }

    default:
      return paramsReducer(state, action)
  }
}

export default reducer
