import React, { useContext, useEffect, useState } from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { Divider, Form, Select } from 'antd'
import ImageUploader from '../../form-components/image-uploader'
import Controller from '../../form-components/controller'
import { MediumFor } from '../../../models/medium/enum'
import { IStaff } from '../../../models/staff/response'
import OldImage from '../../form-components/old-image'
import { CommissionType, StaffStatus } from '../../../models/staff/enum'
import PermanentLocationSelectOptionsContext from '../../../contexts/select-options/permanent-location-select-options/context'
import StaffTypeSelectOptionsContext from '../../../contexts/select-options/staff-type-select-options/context'

interface IProps {
  staff?: IStaff
}

const StaffForm: React.FC<IProps> = ({ staff }) => {
  const { t } = useTranslation()

  const [commisionType, setCommisionType] = useState<number>()

  // Select Options
  const {
    list: permanentLocations,
    actions: permanentLocationsActions,
    loading: permanentLocationsLoading,
  } = useContext(PermanentLocationSelectOptionsContext)
  const {
    list: staffTypes,
    actions: staffTypesActions,
    loading: staffTypesLoading,
  } = useContext(StaffTypeSelectOptionsContext)

  useEffect(() => {
    permanentLocationsActions.getData()
    staffTypesActions.getData()
  }, [])

  // Old Image
  const [image, setImage] = useState(staff?.image?.url)

  useEffect(() => {
    staff?.image?.url && setImage(staff?.image?.url)
  }, [staff?.image?.url])

  // Old National Id Image
  const [nationalIdImage, setNationalIdImage] = useState(
    staff?.nationalIdImage?.url
  )

  useEffect(() => {
    staff?.nationalIdImage?.url &&
      setNationalIdImage(staff?.nationalIdImage?.url)
  }, [staff?.nationalIdImage?.url])

  // Old Passport Image Id
  const [passportImage, setpassportImage] = useState(staff?.passportImage?.url)

  useEffect(() => {
    staff?.passportImage?.url && setpassportImage(staff?.passportImage?.url)
  }, [staff?.passportImage?.url])

  return (
    <div>
      <FieldBuilder
        name='firstName'
        label={t('firstName')}
        input={{ type: 'text' }}
        rules={{ required: true }}
      />
      <FieldBuilder
        name='lastName'
        label={t('lastName')}
        input={{ type: 'text' }}
        rules={{ required: true }}
      />

      {
        <FieldBuilder
          name='password'
          label={t('password')}
          input={{ type: 'password' }}
          rules={{ required: !staff ? true : false }}
        />
      }
      <Form.Item label={t('commission_type')} required>
        <Controller
          name='commissionType'
          rules={{
            required: { value: true, message: t('field_is_required_message') },
          }}
          render={({ field }) => {
            return (
              <Select
                allowClear
                style={{ display: 'block', width: '100%' }}
                placeholder={t('commission_type')}
                options={[
                  {
                    label: t(
                      CommissionType[CommissionType.PERCENTAGE].toLowerCase()
                    ),
                    value: 1,
                  },
                  {
                    label: t(
                      CommissionType[CommissionType.COMMISSION].toLowerCase()
                    ),
                    value: 2,
                  },
                ]}
                {...field}
                onChange={(value) => {
                  field.onChange(value)
                  setCommisionType(value)
                }}
              />
            )
          }}
        />
      </Form.Item>

      <FieldBuilder
        name='commission'
        label={t('commission')}
        input={{ type: 'number' }}
        rules={{
          required: true,
          min: 0,
          max: commisionType === 1 ? 100 : 999999999999999,
        }}
      />
      <FieldBuilder
        name='userName'
        label={t('user_name')}
        input={{ type: 'text' }}
        rules={{ required: true }}
      />

      <Divider />
      {/* Status */}
      <FieldBuilder
        name='status'
        label={t('status')}
        rules={{ required: true }}
        input={{
          type: 'select',
          options: [
            {
              value: StaffStatus.ACTIVE,
              label: t(`${StaffStatus[StaffStatus.ACTIVE]}`),
            },
            {
              value: StaffStatus.INACTIVE,
              label: t(`${StaffStatus[StaffStatus.INACTIVE]}`),
            },
          ],
        }}
      />
      {/* Permanent Location Id */}
      <FieldBuilder
        name='permanentLocationId'
        label={t('permanent_location')}
        rules={{ required: true }}
        input={{
          type: 'select',
          allowSearch: true,
          loading: permanentLocationsLoading.includes('list'),
          options: permanentLocations?.data?.map((permanentLocation) => {
            return {
              value: permanentLocation.id,
              label: permanentLocation.name,
            }
          }),
        }}
      />

      {/* Staff Type Id */}
      <FieldBuilder
        name='staffTypeId'
        label={t('staffTypeId')}
        rules={{ required: true }}
        input={{
          type: 'select',
          allowSearch: true,
          loading: staffTypesLoading.includes('list'),
          options: staffTypes?.data?.map((staffType) => {
            return {
              value: staffType.id,
              label: staffType.name,
            }
          }),
        }}
      />
      {/* Upload Image */}
      <div>
        <Form.Item label={t('image')} required>
          <Controller
            name='imageId'
            rules={{
              required: {
                value: true,
                message: `${t('field_is_required_message')}`,
              },
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <ImageUploader
                  {...field}
                  onChange={(response) => {
                    setImage(undefined)
                    field.onChange(response)
                  }}
                  imageFor={MediumFor.STAFF_IMAGE}
                />
              )
            }}
          />
        </Form.Item>
      </div>
      {/* Old  Image */}
      {image && <OldImage src={image} />}

      {/* Upload National Id Image */}
      <div>
        <Form.Item label={t('national_id_image')} required>
          <Controller
            name='nationalIdImageId'
            rules={{
              required: {
                value: true,
                message: `${t('field_is_required_message')}`,
              },
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <ImageUploader
                  {...field}
                  onChange={(response) => {
                    setNationalIdImage(undefined)
                    field.onChange(response)
                  }}
                  imageFor={MediumFor.STAFF_IMAGE}
                />
              )
            }}
          />
        </Form.Item>
      </div>
      {/* Old  National Id Image */}
      {nationalIdImage && <OldImage src={nationalIdImage} />}

      {/* Upload Passport Image Id */}
      <div>
        <Form.Item label={t('passport_image')} required>
          <Controller
            name='passportImageId'
            rules={{
              required: {
                value: true,
                message: `${t('field_is_required_message')}`,
              },
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <ImageUploader
                  {...field}
                  onChange={(response) => {
                    setpassportImage(undefined)
                    field.onChange(response)
                  }}
                  imageFor={MediumFor.STAFF_IMAGE}
                />
              )
            }}
          />
        </Form.Item>
      </div>
      {/* Old  Passport Image Id */}
      {passportImage && <OldImage src={passportImage} />}
    </div>
  )
}

export default StaffForm
