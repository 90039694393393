import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import FormDrawer from '../../form-components/form-drawer/index'
import BannerForm from '../banner-form'
import { IBanner } from '../../../models/banner/response'
import SubscriptionSelectOptionsContextProvider from '../../../contexts/select-options/subscription-select-options/provider'

interface Props<DataType> {
  formId: string
  loading: boolean
  visable: boolean
  hideDrawer: () => void
  defaultValues?: DataType
  details?: IBanner
  onSubmit: (data: DataType) => void
}

const BannerFormDrawer = function <DataType>(
  props: PropsWithChildren<Props<DataType>>
) {
  const { t } = useTranslation()

  return (
    <SubscriptionSelectOptionsContextProvider>
      <FormDrawer<DataType>
        defaultValues={props.defaultValues}
        title={props.details ? t('update_banner') : t('create_banner')}
        onSubmit={props.onSubmit}
        hideDrawer={props.hideDrawer}
        visible={props.visable}
        formId={props.formId}
        confirmLoading={props.loading}
        children={<BannerForm />}
      />
    </SubscriptionSelectOptionsContextProvider>
  )
}

export default BannerFormDrawer
