export enum CategoryStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

// Status Colors
export const getCategoryStatusColor = (status: CategoryStatus) => {
  switch (status) {
    case CategoryStatus.ACTIVE:
      return 'green'
    case CategoryStatus.INACTIVE:
      return 'red'
    default:
      return 'gray'
  }
}
