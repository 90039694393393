import { Avatar, Card, Modal, Tooltip } from 'antd'
import { PoweroffOutlined, LockOutlined, EditOutlined } from '@ant-design/icons'
import { useContext, useState } from 'react'
import AuthContext from '../../../contexts/auth/context'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import Button from '../../general/antd/button'
import ChangePasswordForm from '../change-password-form'
import FieldBuilder from '../../form-components/field-builder'
import { FormProvider, useForm } from 'react-hook-form'
import EndPoints from '../../../services/end-points'
import { EventEmitter } from 'stream'
import eventManager, { EVENT_SUCCESS } from '../../../utils/events'
import UpdateInfoForm from '../update-profile-form'

interface Props {}

const AuthUserCard: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { authUser, actions, loading } = useContext(AuthContext)

  const methods = useForm()
  const [changeLoading, setChangeLoading] = useState(false)

  const [isChangePasswordModalVisible, setIsChangedPasswordModalVisible] =
    useState(false)

  const showChangePasswordModal = () => {
    setIsChangedPasswordModalVisible(true)
  }

  const handleChangePasswordOk = () => {
    setIsChangedPasswordModalVisible(false)
  }

  const handleChangePasswordCancel = () => {
    setIsChangedPasswordModalVisible(false)
  }

  // update profile info

  const [isUpdateInfoModalVisible, setIsUpdateInfoModalVisible] =
    useState(false)

  const showUpdateInfoModal = () => {
    setIsUpdateInfoModalVisible(true)
  }

  const handleUpdateInfoOk = () => {
    setIsUpdateInfoModalVisible(false)
  }

  const handleUpdateInfoCancel = () => {
    setIsUpdateInfoModalVisible(false)
  }
  const updateInfoSubmit = async (data: any) => {
    try {
      setChangeLoading(true)
      await EndPoints.auth.updateProfile(data)
      await eventManager.emit(EVENT_SUCCESS)

      handleUpdateInfoCancel()
    } catch (Exception) {
    } finally {
      setChangeLoading(false)
    }
  }
  const onSubmit = async (data: any) => {
    try {
      setChangeLoading(true)
      await EndPoints.auth.changePassword(data)
      await eventManager.emit(EVENT_SUCCESS)

      actions.logout()
    } catch (Exception) {
    } finally {
      setChangeLoading(false)
    }
  }

  return (
    <>
      {/* <FieldBuilder /> */}
      <Card
        bordered={false}
        bodyStyle={{ padding: 0 }}
        headStyle={{ padding: 0 }}
        title={
          <Card.Meta
            avatar={
              <Avatar
                size={55}
                src={`${process.env.REACT_APP_BASE_HOME_PATH}/assets/images/placeholders/user.jpg`}
              />
            }
            title={authUser?.username ?? ''}
            description={`#${authUser?.username}`}
          />
        }
        extra={
          <Tooltip title={t('updateProfile')} placement='bottom'>
            <Button
              onClick={() => {
                showUpdateInfoModal()
              }}
              shape='circle'
              icon={<EditOutlined />}
            />
          </Tooltip>
        }
        actions={[
          <Tooltip title={t('change_password')}>
            <Button
              className={styles['card-action']}
              type='link'
              onClick={() => {
                showChangePasswordModal()
              }}
              size='small'
              icon={<LockOutlined />}
            />
          </Tooltip>,
          <Tooltip title={t('logout')}>
            <Button
              className={styles['card-action']}
              type='link'
              onClick={() => {
                actions.logout()
              }}
              size='small'
              icon={<PoweroffOutlined />}
              loading={loading.includes('logout')}
            />
          </Tooltip>,
        ]}
      />
      <Modal
        title={t('change_password')}
        visible={isChangePasswordModalVisible}
        onOk={methods.handleSubmit(onSubmit)}
        onCancel={handleChangePasswordCancel}
        okText={t('change_password_and_signout')}
        confirmLoading={changeLoading}
      >
        <FormProvider {...methods}>
          <ChangePasswordForm />
        </FormProvider>
      </Modal>
      {/* update info modal */}
      <Modal
        title={t('update_profile')}
        visible={isUpdateInfoModalVisible}
        onOk={methods.handleSubmit(updateInfoSubmit)}
        onCancel={handleUpdateInfoCancel}
        okText={t('update')}
        confirmLoading={changeLoading}
      >
        <FormProvider {...methods}>
          <UpdateInfoForm />
        </FormProvider>
      </Modal>
    </>
  )
}

export default AuthUserCard
