import { createContext } from "react";
import { IOrderQuery } from "../../models/order/query";
import { IApproveOrder, IRejectOrder } from "../../models/order/request";
import { IOrder } from "../../models/order/response";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";

export type OrderLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: OrderLoading[];

  list?: IBaseListingResponse<IOrder>;
  query: IOrderQuery;

  details?: IOrder;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IOrder) => void;

    approveOrder: (id: number, request: IApproveOrder) => void;
    rejectOrder: (id: number, request: IRejectOrder) => void;
    deleteOrder: (id: number) => void;

    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IOrderQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    approveOrder: DEFAULT_FUNCTION,
    rejectOrder: DEFAULT_FUNCTION,
    deleteOrder: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const OrderContext = createContext(externalState);

export default OrderContext;
