import React, { useContext, useEffect, useState } from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { languages } from '../../../utils/helpers/constants'
import { Tabs, Divider, Form, List, Row, Col, Tag } from 'antd'
import TranslationsCard from '../../form-components/translations-card'
import ImageUploader from '../../form-components/image-uploader'
import Controller from '../../form-components/controller'
import { MediumFor } from '../../../models/medium/enum'
import {
  IService,
  IServiceCity,
  IServicePermanentLocation,
} from '../../../models/service/response'
import OldImage from '../../form-components/old-image'
import { ServiceStatus } from '../../../models/service/enum'
import TagSelectOptionsContext from '../../../contexts/select-options/tag-select-options/context'
import CategorySelectOptionsContext from '../../../contexts/select-options/category-select-options/context'
import CitySelectOptionsContext from '../../../contexts/select-options/city-select-options/context'
import { useFormContext } from 'react-hook-form'
import AddBtn from '../../table-components/actions/add-btn'
import Input from '../../form-components/input'
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import DeleteBtn from '../../table-components/actions/delete-btn'
import Image from '../../general/antd/image'
import FormModal from '../../form-components/form-modal'
import AddServiceCityForm from '../service-city-form'
import PermanentLocationSelectOptionsContext from '../../../contexts/select-options/permanent-location-select-options/context'
import { PermanentLocationStatus } from '../../../models/permanent-location/enum'
import AddServicePermanentLocationForm from '../service-permanent-location-form'

const { TabPane } = Tabs

interface IProps {
  service?: IService
}

const ServiceForm: React.FC<IProps> = ({ service }) => {
  const { t } = useTranslation()
  const { setValue } = useFormContext()

  const {
    list: tagOptions,
    actions: tagActions,
    loading: tagLoading,
  } = useContext(TagSelectOptionsContext)
  const {
    list: categoryOptions,
    actions: categoryActions,
    loading: categoryLoading,
  } = useContext(CategorySelectOptionsContext)

  // Old Active Image
  const [activeImage, setActiveImage] = useState(service?.activeImage?.url)

  // Old Inactive Image
  const [inactiveImage, setInactiveImage] = useState(
    service?.inactiveImage?.url
  )

  useEffect(() => {
    service?.activeImage?.url && setActiveImage(service?.activeImage?.url)
    service?.inactiveImage?.url && setInactiveImage(service?.inactiveImage?.url)
  }, [service])

  // Cities
  const [cities, setCities] = useState(service?.cities)

  useEffect(() => {
    service?.cities && setCities(service?.cities)
  }, [service?.cities])

  const {
    list: cityOptions,
    actions: cityOptionsAction,
    loading: cityOptionsLoading,
  } = useContext(CitySelectOptionsContext)

  // Cities Management
  const [addCityModalVisible, setAddCityModalVisible] = useState(false)

  const addCity = (cityId: number, price: number, note: string) => {
    let selectedCity = cityOptions?.data.find((s) => s.id == cityId)
    let newCity: IServiceCity
    if (selectedCity) {
      newCity = {
        ...selectedCity,
        price: price,
        CityServiceNote: note,
      }
      if (cities) {
        setCities([...cities, newCity])
      } else {
        setCities([newCity])
      }
    }
  }

  const deleteCity = (cityId: number) => {
    setCities(cities?.filter((c) => c.id != cityId))
  }

  useEffect(() => {
    setValue('cities', undefined)
    cities &&
      cities.forEach((city, index) => {
        setValue(`cities[${index}].cityId`, city?.id)
        setValue(`cities[${index}].price`, +city?.price)
        setValue(`cities[${index}].note`, city?.CityServiceNote)
      })
  }, [cities])

  // Todo: Permanent Locations
  // Permanent Locations
  const [permanentLocations, setPermanentLocations] = useState(
    service?.permanentLocations
  )

  useEffect(() => {
    service?.permanentLocations &&
      setPermanentLocations(service?.permanentLocations)
  }, [service?.permanentLocations])

  const {
    list: permanentLocationOptions,
    actions: permanentLocationOptionsAction,
    loading: permanentLocationOptionsLoading,
  } = useContext(PermanentLocationSelectOptionsContext)

  // Permanent Locations Management
  const [
    addPermanentLocationModalVisible,
    setAddPermanentLocationModalVisible,
  ] = useState(false)

  const addPermanentLocation = (
    permanentLocationId: number,
    price: number,
    note: string
  ) => {
    let selectedPermanentLocation = permanentLocationOptions?.data.find(
      (s) => s.id == permanentLocationId
    )
    let newPermanentLocation: IServicePermanentLocation
    if (selectedPermanentLocation) {
      newPermanentLocation = {
        ...selectedPermanentLocation,
        price: price,
        PermanentLocationServiceNote: note,
      }
      if (permanentLocations) {
        setPermanentLocations([...permanentLocations, newPermanentLocation])
      } else {
        setPermanentLocations([newPermanentLocation])
      }
    }
  }

  const deletePermanentLocation = (permanentLocationId: number) => {
    setPermanentLocations(
      permanentLocations?.filter((c) => c.id != permanentLocationId)
    )
  }

  useEffect(() => {
    setValue('permanentLocations', undefined)
    permanentLocations &&
      permanentLocations.forEach((permanentLocation, index) => {
        setValue(
          `permanentLocations[${index}].permanentLocationId`,
          permanentLocation?.id
        )
        setValue(
          `permanentLocations[${index}].price`,
          +permanentLocation?.price
        )
        setValue(
          `permanentLocations[${index}].note`,
          permanentLocation?.PermanentLocationServiceNote
        )
      })
  }, [permanentLocations])

  useEffect(() => {
    tagActions.getData()
    categoryActions.getData()
    cityOptionsAction.getData()
    permanentLocationOptionsAction.getData()
  }, [])

  return (
    <div>
      <TranslationsCard>
        <Tabs type='line'>
          {languages?.map((lang, index) => {
            return (
              <TabPane tab={lang.name} key={index}>
                <FieldBuilder
                  key={index}
                  name={`name[${lang?.code}]`}
                  label={`${t('name')} (${lang?.name})`}
                  input={{ type: 'text' }}
                  rules={{ required: true }}
                />

                <FieldBuilder
                  key={index}
                  name={`additionalInformation[${lang?.code}]`}
                  label={`${t('additional_information')} (${lang?.name})`}
                  input={{ type: 'text-area' }}
                  rules={{ required: true }}
                />
              </TabPane>
            )
          })}
        </Tabs>
      </TranslationsCard>

      <Divider />

      {/* Status */}
      <FieldBuilder
        name='status'
        label={t('status')}
        rules={{ required: true }}
        input={{
          type: 'select',
          options: [
            {
              value: ServiceStatus.ACTIVE,
              label: t(`${ServiceStatus[ServiceStatus.ACTIVE]}`),
            },
            {
              value: ServiceStatus.INACTIVE,
              label: t(`${ServiceStatus[ServiceStatus.INACTIVE]}`),
            },
          ],
        }}
      />

      {/* Tag */}
      <FieldBuilder
        name='tagId'
        label={t('tag')}
        rules={{ required: true }}
        input={{
          type: 'select',
          allowSearch: true,
          loading: tagLoading.includes('list'),
          options: tagOptions?.data?.map((tag) => {
            return { value: tag.id, label: tag.name }
          }),
        }}
      />

      {/* Category */}
      <FieldBuilder
        name='categoryId'
        label={t('category')}
        rules={{ required: true }}
        input={{
          type: 'select',
          allowSearch: true,
          loading: categoryLoading.includes('list'),
          options: categoryOptions?.data?.map((category) => {
            return { value: category.id, label: category.name }
          }),
        }}
      />

      {/* Upload Active Image */}
      <div>
        <Form.Item label={t('active_image')} required>
          <Controller
            name='activeImageId'
            rules={{
              required: {
                value: true,
                message: `${t('field_is_required_message')}`,
              },
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <ImageUploader
                  {...field}
                  onChange={(response) => {
                    setActiveImage(undefined)
                    field.onChange(response)
                  }}
                  imageFor={MediumFor.CATEGORY_ACTIVE_IMAGE}
                />
              )
            }}
          />
        </Form.Item>
      </div>

      {/* Old Active Image */}
      {activeImage && <OldImage src={activeImage} />}

      {/* Upload Inactive Image */}
      <div>
        <Form.Item label={t('inactive_image')} required>
          <Controller
            name='inactiveImageId'
            rules={{
              required: {
                value: true,
                message: `${t('field_is_required_message')}`,
              },
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <ImageUploader
                  {...field}
                  onChange={(response) => {
                    setInactiveImage(undefined)
                    field.onChange(response)
                  }}
                  imageFor={MediumFor.CATEGORY_INACTIVE_IMAGE}
                />
              )
            }}
          />
        </Form.Item>
      </div>

      {/* Old Inactive Image */}
      {inactiveImage && <OldImage src={inactiveImage} />}

      {/* Price Management */}
      <Tabs defaultActiveKey='1' animated type='line'>
        {/* Cities */}
        <TabPane tab={t('cities')} key='1'>
          <List
            bordered={false}
            header={
              <Row justify='space-between'>
                <Col>
                  <AddBtn
                    title={t('add_city')}
                    onClick={() => {
                      // Show Modal
                      setAddCityModalVisible(true)
                    }}
                  />
                </Col>
                <Col>
                  <Input
                    placeholder={t('search_hint')}
                    suffix={<SearchOutlined />}
                    allowClear
                    onChange={(event) => {
                      let searchKey = event.target.value
                      let filteredcities = service?.cities?.filter((service) =>
                        service?.name?.includes(searchKey)
                      )
                      setCities(filteredcities)
                      if (searchKey == '') {
                        setCities(service?.cities)
                      }
                    }}
                  />
                </Col>
              </Row>
            }
            dataSource={cities ?? []}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <DeleteBtn
                    onConfirm={() => {
                      deleteCity(item?.id)
                    }}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={<Image src={item?.plateImage?.url} />}
                  title={`${item?.name} (${item?.price} ${t('aed')})`}
                  description={item?.CityServiceNote}
                />
              </List.Item>
            )}
          />
        </TabPane>
        {/* Permanent Locations */}
        <TabPane tab={t('permanent_locations')} key='2'>
          <List
            bordered={false}
            header={
              <Row justify='space-between'>
                <Col>
                  <AddBtn
                    title={t('add_permanent_location')}
                    onClick={() => {
                      // Show Modal
                      setAddPermanentLocationModalVisible(true)
                    }}
                  />
                </Col>
                <Col>
                  <Input
                    placeholder={t('search_hint')}
                    suffix={<SearchOutlined />}
                    allowClear
                    onChange={(event) => {
                      let searchKey = event.target.value
                      let permanentLocations =
                        service?.permanentLocations?.filter((p) =>
                          p?.name?.includes(searchKey)
                        )
                      setPermanentLocations(permanentLocations)
                      if (searchKey == '') {
                        setPermanentLocations(service?.permanentLocations)
                      }
                    }}
                  />
                </Col>
              </Row>
            }
            dataSource={permanentLocations ?? []}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <DeleteBtn
                    onConfirm={() => {
                      deletePermanentLocation(item?.id)
                    }}
                  />,
                ]}
              >
                <List.Item.Meta
                  title={`${item?.name} (${item?.price} ${t('aed')})`}
                  description={item?.PermanentLocationServiceNote}
                />
                <div>
                  {item?.status && (
                    <Tag
                      color={
                        item?.status == PermanentLocationStatus.ACTIVE
                          ? 'green'
                          : 'yellow'
                      }
                    >
                      {t(`${PermanentLocationStatus[item?.status]}`)}
                    </Tag>
                  )}
                </div>
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>

      {/* Add City Modal */}
      <FormModal
        title={t('add_city_to_service')}
        formId='add-city-to-service'
        hideModal={() => {
          setAddCityModalVisible(false)
        }}
        visible={addCityModalVisible}
        onSubmit={(data: { cityId: number; price: number; note: string }) => {
          addCity(data.cityId, data.price, data.note)
        }}
        confirmLoading={false}
      >
        <AddServiceCityForm
          citiesIdsAlreadySelected={cities ? cities.map((s) => s.id) : []}
        />
      </FormModal>

      {/* Add Permanent Location Modal */}
      <FormModal
        title={t('add_permanent_location_to_service')}
        formId='add-permanent-location-to-service'
        hideModal={() => {
          setAddPermanentLocationModalVisible(false)
        }}
        visible={addPermanentLocationModalVisible}
        onSubmit={(data: {
          permanentLocationId: number
          price: number
          note: string
        }) => {
          addPermanentLocation(data.permanentLocationId, data.price, data.note)
        }}
        confirmLoading={false}
      >
        <AddServicePermanentLocationForm
          permanentLocationsIdsAlreadySelected={
            permanentLocations ? permanentLocations.map((s) => s.id) : []
          }
        />
      </FormModal>
    </div>
  )
}

export default ServiceForm
