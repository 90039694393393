import { createContext } from 'react'
import { IPermanentLocationQuery } from '../../../models/permanent-location/query'
import { IPermanentLocation } from '../../../models/permanent-location/response'
import IBaseListingResponse from '../../../utils/api/base-listing-response'
import {
  DEFAULT_FUNCTION,
  DEFAULT_MAXIMUM_PAGE_SIZE,
  INITIAL_PAGE,
} from '../../../utils/helpers/constants'

export type PermanentLocationLoading = 'list'

export interface IInternalState {
  loading: PermanentLocationLoading[]

  list?: IBaseListingResponse<IPermanentLocation>
  query: IPermanentLocationQuery
}

export const internalState: IInternalState = {
  loading: [],
  query: {
    page: INITIAL_PAGE,
    perPage: DEFAULT_MAXIMUM_PAGE_SIZE,
  },
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void

    setSearch: (search?: string) => void
    setQuery: (query: IPermanentLocationQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const PermanentLocationSelectOptionsContext = createContext(externalState)

export default PermanentLocationSelectOptionsContext
