import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FieldBuilder from '../../form-components/field-builder'
import ServiceSelectOptionsContext from '../../../contexts/select-options/service-select-options/context'

interface IProps {
  servicesIdsAlreadySelected: number[]
}

const AddSubscriptionOfferServiceForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const {
    list: serviceOptions,
    actions: serviceOptionsAction,
    loading: serviceOptionsLoading,
  } = useContext(ServiceSelectOptionsContext)

  // useEffect(() => {
  //   serviceOptionsAction.getData()
  // }, [])

  return (
    <div>
      {/* Service */}
      <FieldBuilder
        name='serviceId'
        label={t('service')}
        rules={{ required: true }}
        input={{
          type: 'select',
          allowSearch: true,
          loading: serviceOptionsLoading.includes('list'),
          options: serviceOptions?.data
            ?.filter((s) => !props.servicesIdsAlreadySelected.includes(s.id))
            ?.map((service) => {
              return { value: service.id, label: service.name }
            }),
        }}
      />

      <FieldBuilder
        name={'price'}
        label={`${t('price')}`}
        input={{ type: 'number' }}
        rules={{ required: true }}
      />
    </div>
  )
}

export default AddSubscriptionOfferServiceForm
