import { HttpClient } from '../../utils/api/http-client'

class AppService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }
}

export default AppService
