import { createContext } from 'react'
import { ICarQuery } from '../../models/car/query'
import { ICreateCar, IUpdateCar } from '../../models/car/request'
import { ICar } from '../../models/car/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type CarLoading = 'list' | 'details' | 'create' | 'update' | 'delete'

export interface IInternalState {
  loading: CarLoading[]

  list?: IBaseListingResponse<ICar>
  query: ICarQuery

  details?: ICar
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: ICar) => void

    createCar: (request: ICreateCar) => void
    updateCar: (id: number, request: IUpdateCar) => void
    deleteCar: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: ICarQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createCar: DEFAULT_FUNCTION,
    updateCar: DEFAULT_FUNCTION,
    deleteCar: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const CarContext = createContext(externalState)

export default CarContext
