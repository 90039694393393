import { useTranslation } from 'react-i18next'
import { IApproveRequest } from '../../../models/request/request'
import FormApproveModal from '../form-approve-modal'

interface IProps {
  loading: boolean
  visable: boolean
  hideModal: () => void
  onSubmit: () => void
}

const ApproveFormModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  return (
    <FormApproveModal
      title={t('confirm_title')}
      formId='confirm-request'
      onSubmit={props.onSubmit}
      hideModal={props.hideModal}
      confirmLoading={props.loading}
      visible={props.visable}
    >
      <p>{t('confirm_message')}</p>
    </FormApproveModal>
  )
}

export default ApproveFormModal
