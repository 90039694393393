import React from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { IRequest } from '../../../models/request/response'

interface IProps {
  request?: IRequest
}

const RequestRejefctForm: React.FC<IProps> = ({ request }) => {
  const { t } = useTranslation()

  return (
    <div>
      <FieldBuilder
        name='note'
        label={t('note')}
        input={{ type: 'text-area' }}
        rules={{ required: true }}
      />
    </div>
  )
}

export default RequestRejefctForm
