import { Descriptions as AntdDescriptions, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  getPromoCodeStatusColor,
  PromoCodeStatus,
} from '../../../models/promo-code/enum'
import { IPromoCode } from '../../../models/promo-code/response'
import Descriptions from '../../general/antd/descriptions'

interface Props {
  promoCode?: IPromoCode
}

const PromoCodeDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.promoCode?.id}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('code')}>
        {props.promoCode?.code}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('discount_percentage')}>
        {`${props.promoCode?.discountPercentage}%`}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('amount')}>
        {props.promoCode?.amount}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('status')}>
        {props?.promoCode?.status && (
          <Tag color={getPromoCodeStatusColor(props.promoCode.status)}>
            {t(`${PromoCodeStatus[props?.promoCode?.status]}`)}
          </Tag>
        )}
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default PromoCodeDetails
