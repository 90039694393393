import { Tooltip } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Button, { ButtonProps } from '../../../general/antd/button'

interface Props extends ButtonProps {}

const ViewBtn: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('view')}>
      <Button shape='circle' icon={<EyeOutlined />} {...props} />
    </Tooltip>
  )
}

export default ViewBtn
