import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import PageHeader from '../../components/general/antd/page-header'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import InfoDrawer from '../../components/general/info-drawer'
import NotificationTable from '../../components/notifications/notification-table'
import ClientSubscriptionContextProvider from '../../contexts/client-subscription/provider'
import ClientSubscriptionContext from '../../contexts/client-subscription/context'
import ClientSubscriptionsTable from '../../components/client-subscription/client-subscription-table'
import SubscriptionFilters from '../../components/subscription/subcription-filter'
import ClientSubscriptionFilters from '../../components/client-subscription/client-subscription-filters'
import SubscriptionContextProvider from '../../contexts/subscription/provider'
import ClientSubscriptionDetails from '../../components/client-subscription/client-subscription-details'

interface Props {
  route: IRoute
}

const ClientSubscriptions: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(ClientSubscriptionContext)

  const [formDrawerVisable, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />

      <SubscriptionContextProvider>
        <ClientSubscriptionFilters />
      </SubscriptionContextProvider>

      <ClientSubscriptionsTable
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <InfoDrawer
        title={t('client_subscription_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <ClientSubscriptionDetails clientSubscription={details} />
      </InfoDrawer>
    </>
  )
}

const ClientSubscriptionsPage: React.FC<Props> = (props) => {
  return (
    <ClientSubscriptionContextProvider>
      <ClientSubscriptions {...props} />
    </ClientSubscriptionContextProvider>
  )
}
export default ClientSubscriptionsPage
