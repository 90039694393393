import React from 'react'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Input } from 'antd'
import { ErrorMessage } from '@hookform/error-message'

import styles from './style.module.scss'
import FieldBuilder from '../../form-components/field-builder'
import FormItem from '../../general/form-item'

const UpdateInfoForm: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useFormContext()
  const { t } = useTranslation()
  const onSubmit = async (data: any) => {}
  return (
    <>
      {/* Old Password */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormItem label={t('email')}>
          <Controller
            render={({ field }) => {
              return <Input type='email' {...field} />
            }}
            name='email'
            control={control}
            rules={{
              required: `${t('field_is_required_message')}`,
            }}
          />
          <ErrorMessage
            errors={errors}
            name='email'
            render={({ message }) => <p className={styles.alert}>{message}</p>}
          />
        </FormItem>
      </form>
      {/* </FormItem> */}
    </>
  )
}

export default UpdateInfoForm
