import { ITransactionAdminToStaffQuery } from '../../models/transaction-admin-staff/query'
import {
  ICreateTransactionAdminToStaff,
  IUpdateTransactionAdminToStaff,
} from '../../models/transaction-admin-staff/request'
import { ITransactionAdminToStaff } from '../../models/transaction-admin-staff/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class TransactionAdminToStaffService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllTransactionsAdminToStaff = (
    query: ITransactionAdminToStaffQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<ITransactionAdminToStaff>>({
      path: `dashboard/admin-to-staff-transaction/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createTransactionAdminToStaff = (
    data: ICreateTransactionAdminToStaff,
    params?: RequestParams
  ) =>
    this.http.request<ITransactionAdminToStaff>({
      path: '/dashboard/admin-to-staff-transaction',
      method: 'POST',
      body: data,
      ...params,
    })

  updateTransactionAdminToStaff = (
    id: number,
    data: IUpdateTransactionAdminToStaff,
    params?: RequestParams
  ) =>
    this.http.request<ITransactionAdminToStaff>({
      path: `/dashboard/admin-to-staff-transaction/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteTransactionAdminToStaff = (id: number, params?: RequestParams) =>
    this.http.request<ITransactionAdminToStaff>({
      path: `/dashboard/admin-to-staff-transaction/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default TransactionAdminToStaffService
