import { createContext } from 'react'
import { ICategoryQuery } from '../../models/category/query'
import { ICreateCategory, IUpdateCategory } from '../../models/category/request'
import { ICategory } from '../../models/category/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type CategoryLoading =
  | 'list'
  | 'details'
  | 'create'
  | 'update'
  | 'delete'

export interface IInternalState {
  loading: CategoryLoading[]

  list?: IBaseListingResponse<ICategory>
  query: ICategoryQuery

  details?: ICategory
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: ICategory) => void

    createCategory: (request: ICreateCategory) => void
    updateCategory: (id: number, request: IUpdateCategory) => void
    deleteCategory: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: ICategoryQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createCategory: DEFAULT_FUNCTION,
    updateCategory: DEFAULT_FUNCTION,
    deleteCategory: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const CategoryContext = createContext(externalState)

export default CategoryContext
