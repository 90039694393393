import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import FormDrawer from '../../form-components/form-drawer/index'
import TransactionAdminToStaffForm from '../transaction-admin-staff-form'
import { ITransactionAdminToStaff } from '../../../models/transaction-admin-staff/response'
import StaffSelectOptionsContextProvider from '../../../contexts/select-options/staff-select-options/provider'

interface Props<DataType> {
  formId: string
  loading: boolean
  visable: boolean
  hideDrawer: () => void
  defaultValues?: DataType
  details?: ITransactionAdminToStaff
  onSubmit: (data: DataType) => void
}

const TransactionAdminToStaffFormDrawer = function <DataType>(
  props: PropsWithChildren<Props<DataType>>
) {
  const { t } = useTranslation()

  return (
    <StaffSelectOptionsContextProvider>
      <FormDrawer<DataType>
        defaultValues={props.defaultValues}
        title={
          props.details
            ? t('update_transaction_admin_to_staff')
            : t('create_transaction_admin_to_staff')
        }
        onSubmit={props.onSubmit}
        hideDrawer={props.hideDrawer}
        visible={props.visable}
        formId={props.formId}
        confirmLoading={props.loading}
        children={<TransactionAdminToStaffForm />}
      />
    </StaffSelectOptionsContextProvider>
  )
}

export default TransactionAdminToStaffFormDrawer
