import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import eventManager, { EVENT_SUCCESS } from '../../utils/events'
import StaffContextProvider from '../../contexts/staff/provider'
import PageHeader from '../../components/general/antd/page-header'
import StaffContext from '../../contexts/staff/context'
import CreateBtn from '../../components/table-components/actions/create-btn'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import StaffTable from '../../components/staff/staff-table'
import StaffDetails from '../../components/staff/staff-details'
import StaffFormDrawer from '../../components/staff/staff-form-drawer'
import InfoDrawer from '../../components/general/info-drawer'
import StaffFilters from '../../components/staff/staff-filters'
import PermanentLocationSelectOptionsContextProvider from '../../contexts/select-options/permanent-location-select-options/provider'

interface Props {
  route: IRoute
}

const Staffs: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(StaffContext)

  const onSubmit = async (data: any) => {
    details
      ? await actions.updateStaff(details.id, data)
      : await actions.createStaff(data)
    eventManager.emit(EVENT_SUCCESS)
  }

  const [formDrawerVisable, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <CreateBtn
              onClick={() => {
                actions.setDetails(undefined)
                setFormDrawerVisable(true)
              }}
            />
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />

      <PermanentLocationSelectOptionsContextProvider>
        <StaffFilters />
      </PermanentLocationSelectOptionsContextProvider>

      <StaffTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <StaffFormDrawer
        formId={'staff-form'}
        visable={formDrawerVisable}
        defaultValues={
          details && {
            id: details?.id,
            firstName: details?.user?.firstName,
            lastName: details?.user?.lastName,
            userName: details?.userName,
            commission: details?.commission,
            city: details?.city,
            country: details?.country,
            status: details?.status,
            permanentLocationId: details?.permanentLocation?.id,
            staffTypeId: details?.staffType?.id,
            imageId: details?.image?.id,
            nationalIdImageId: details?.nationalIdImage?.id,
            passportImageId: details?.passportImage?.id,
            commissionType: details?.commissionType,
          }
        }
        details={details}
        loading={loading.includes('create') || loading.includes('update')}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      />

      <InfoDrawer
        title={t('staff_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <StaffDetails staff={details} />
      </InfoDrawer>
    </>
  )
}

const StaffsPage: React.FC<Props> = (props) => {
  return (
    <StaffContextProvider>
      <Staffs {...props} />
    </StaffContextProvider>
  )
}
export default StaffsPage
