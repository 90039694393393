import { createContext } from 'react'
import { IBrandQuery } from '../../models/brand/query'
import { ICreateBrand, IUpdateBrand } from '../../models/brand/request'
import { IBrand } from '../../models/brand/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type BrandLoading = 'list' | 'details' | 'create' | 'update' | 'delete'

export interface IInternalState {
  loading: BrandLoading[]

  list?: IBaseListingResponse<IBrand>
  query: IBrandQuery

  details?: IBrand
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IBrand) => void

    createBrand: (request: ICreateBrand) => void
    updateBrand: (id: number, request: IUpdateBrand) => void
    deleteBrand: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IBrandQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createBrand: DEFAULT_FUNCTION,
    updateBrand: DEFAULT_FUNCTION,
    deleteBrand: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const BrandContext = createContext(externalState)

export default BrandContext
