import { Descriptions as AntdDescriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { IUser } from '../../../models/user/response'
import Descriptions from '../../general/antd/descriptions'

interface Props {
  user?: IUser
}

const UserDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions title={t('userDetails')} size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.user?.id}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('username')}>
        {props.user?.username}
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default UserDetails
