import React from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'

interface IProps {}

const ConfigForm: React.FC<IProps> = () => {
  const { t } = useTranslation()

  return (
    <div>
      <FieldBuilder
        name='key'
        label={t('key')}
        input={{ type: 'text' }}
        rules={{ required: true }}
      />
      <FieldBuilder
        name='value'
        label={t('value')}
        input={{ type: 'text' }}
        rules={{ required: true }}
      />
    </div>
  )
}

export default ConfigForm
