import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import FormDrawer from '../../form-components/form-drawer/index'
import StaffTypeForm from '../staff-type-form'
import { IStaffType } from '../../../models/staff-type/response'

interface Props<DataType> {
  formId: string
  loading: boolean
  visable: boolean
  hideDrawer: () => void
  defaultValues?: DataType
  details?: IStaffType
  onSubmit: (data: DataType) => void
}

const StaffTypeFormDrawer = function <DataType>(
  props: PropsWithChildren<Props<DataType>>
) {
  const { t } = useTranslation()

  return (
    <FormDrawer<DataType>
      defaultValues={props.defaultValues}
      title={props.details ? t('update_staff_type') : t('create_staff_type')}
      onSubmit={props.onSubmit}
      hideDrawer={props.hideDrawer}
      visible={props.visable}
      formId={props.formId}
      confirmLoading={props.loading}
      children={<StaffTypeForm />}
    />
  )
}

export default StaffTypeFormDrawer
