/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useReducer } from "react"
import reducer from "./reducer"
import TransactionAdminToStaffContext, { internalState } from "./context"
import { execute } from "../../utils/api/api-execute"
import EndPoints from "../../services/end-points"
import AuthContext from "../auth/context"
import { ITransactionAdminToStaffQuery } from "../../models/transaction-admin-staff/query"
import { ITransactionAdminToStaffDetails } from "../../models/transaction-admin-staff/response"
import {
  ICreateTransactionAdminToStaff,
  IUpdateTransactionAdminToStaff,
} from "../../models/transaction-admin-staff/request"

const TransactionAdminToStaffContextProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState)

  const { isAuthenticated } = useContext(AuthContext)
  /**
   * Get data when query changed
   */
  useEffect(() => {
    isAuthenticated && getData()
  }, [state.query, isAuthenticated])

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } })

        const { data } =
          await EndPoints.transactionAdminToStaff.getAllTransactionsAdminToStaff(
            state.query
          )

        dispatch({ type: "SET_LIST", payload: { list: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } })
      },
      throwException: false,
    })
  }

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } })

        const data = state.list?.data?.find((u) => u?.id === id)

        dispatch({ type: "SET_DETAILS", payload: { details: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } })
      },
      throwException: false,
    })
  }

  const setDetails = async (data?: ITransactionAdminToStaffDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } })
  }

  const createTransactionAdminToStaff = async (
    request: ICreateTransactionAdminToStaff
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        })

        await EndPoints.transactionAdminToStaff.createTransactionAdminToStaff(
          request
        )

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        })
      },
      throwException: true,
    })
  }

  const updateTransactionAdminToStaff = async (
    id: number,
    request: IUpdateTransactionAdminToStaff
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        })
        await EndPoints.transactionAdminToStaff.updateTransactionAdminToStaff(
          id,
          request
        )

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        })
      },
      throwException: true,
    })
  }

  const deleteTransactionAdminToStaff = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        })

        await EndPoints.transactionAdminToStaff.deleteTransactionAdminToStaff(
          id
        )

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        })
      },
      throwException: true,
    })
  }

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } })
  }

  const setQuery = (query: ITransactionAdminToStaffQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } })
  }

  return (
    <TransactionAdminToStaffContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createTransactionAdminToStaff,
          updateTransactionAdminToStaff,
          deleteTransactionAdminToStaff,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </TransactionAdminToStaffContext.Provider>
  )
}

export default TransactionAdminToStaffContextProvider
