import { ITransactionStaffToAdminQuery } from '../../models/transaction-staff-to-admin/query'
import {
  ITransactionStaffToAdmin,
  ITransactionStaffToAdminDetails,
} from '../../models/transaction-staff-to-admin/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { toggleLoading } from '../../utils/helpers/functions'
import paramsReducer, { QueryAction } from './../base/base-reducer'
import { IInternalState, TransactionStaffToAdminLoading } from './context'

type Action =
  | {
      type: 'LOADING'
      payload: {
        loading:
          | TransactionStaffToAdminLoading
          | TransactionStaffToAdminLoading[]
      }
    }
  | {
      type: 'SET_LIST'
      payload: { list: IBaseListingResponse<ITransactionStaffToAdmin> }
    }
  | {
      type: 'SET_DETAILS'
      payload: { details?: ITransactionStaffToAdminDetails }
    }
  | QueryAction<ITransactionStaffToAdminQuery>

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      }
    case 'SET_LIST':
      return {
        ...state,
        list: action.payload.list,
      }

    case 'SET_DETAILS':
      return {
        ...state,
        details: action.payload.details,
      }

    default:
      return paramsReducer(state, action)
  }
}

export default reducer
