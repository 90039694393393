import { useTranslation } from "react-i18next";
import { IRoute } from "../../routes/routes";
import { useContext, useState } from "react";
import eventManager, { EVENT_SUCCESS } from "../../utils/events";
import AttendanceContextProvider from "../../contexts/attendance/provider";
import PageHeader from "../../components/general/antd/page-header";
import AttendanceContext from "../../contexts/attendance/context";
import RefreshBtn from "../../components/table-components/actions/refresh-btn";
import Space from "../../components/general/antd/space";
import AttendanceTable from "../../components/attendance/attendance-table";
import AttendanceDetails from "../../components/attendance/attendance-details";
import AttendanceFormDrawer from "../../components/attendance/attendance-form-drawer";
import InfoDrawer from "../../components/general/info-drawer";
import AttendanceFilters from "../../components/attendance/attendance-filters";
import StaffSelectOptionsContextProvider from "../../contexts/select-options/staff-select-options/provider";
import ExcelAttedenceFormModal from "../../components/attendance/excel/attedance-excel-form-modal";
import { Button } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";

interface Props {
  route: IRoute;
}

const Attendances: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { details, actions, loading } = useContext(AttendanceContext);

  const onSubmit = async (data: any) => {
    details && (await actions.updateAttendance(details.id, data));

    eventManager.emit(EVENT_SUCCESS);
  };

  const [formDrawerVisable, setFormDrawerVisable] = useState(false);

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false);
  const [excelFormVisable, setExcelFormVisable] = useState(false);
  const exportExcel = async (data: any) => {
    await actions.exportExcel(data);
  };
  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t("manageRecource", { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <RefreshBtn
              loading={loading.includes("list")}
              onClick={() => actions.getData()}
            >
              {t("refresh")}
            </RefreshBtn>
            <Button
              type="primary"
              onClick={() => {
                setExcelFormVisable(true);
              }}
              icon={<FileExcelOutlined />}
            >
              {t("export_to_excel")}
            </Button>
          </Space>
        }
      />

      <StaffSelectOptionsContextProvider>
        <AttendanceFilters />
      </StaffSelectOptionsContextProvider>

      <AttendanceTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <AttendanceFormDrawer
        formId={"attendance-form"}
        visable={formDrawerVisable}
        defaultValues={
          details && {
            id: details?.id,
            checkInTime: details?.checkInTime,
            checkOutTime: details?.checkOutTime,
            attendanceDate: details?.attendanceDate,
            checkInStatus: details?.checkInStatus,
            checkOutStatus: details?.checkOutStatus,
            note: details?.note,
            permanentLocationId: details?.permanentLocation?.id,
            imageId: details?.image?.id,
          }
        }
        details={details}
        loading={loading.includes("create") || loading.includes("update")}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      />

      <InfoDrawer
        title={t("attendance_details")}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes("details")}
      >
        <AttendanceDetails attendance={details} />
      </InfoDrawer>

      <ExcelAttedenceFormModal
        loading={loading.includes("create")}
        visable={excelFormVisable}
        hideModal={() => setExcelFormVisable(false)}
        onSubmit={exportExcel}
      />
    </>
  );
};

const AttendancesPage: React.FC<Props> = (props) => {
  return (
    <AttendanceContextProvider>
      <Attendances {...props} />
    </AttendanceContextProvider>
  );
};
export default AttendancesPage;
