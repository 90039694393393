import { Layout } from 'antd'
import styles from './styles.module.scss'

const { Content } = Layout
interface Props {}

const AuthLayout: React.FC<Props> = (props) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content
        className={styles.Content}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/login-bg.jpg)`,
        }}
      >
        {props.children}
      </Content>
    </Layout>
  )
}

export default AuthLayout
