import { createContext } from "react";
import { IAttendanceQuery } from "../../models/attendance/query";
import {
  ICreateAttendance,
  IUpdateAttendance,
} from "../../models/attendance/request";
import { IAttendance } from "../../models/attendance/response";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";

export type AttendanceLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: AttendanceLoading[];

  list?: IBaseListingResponse<IAttendance>;
  query: IAttendanceQuery;

  details?: IAttendance;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IAttendance) => void;

    createAttendance: (request: ICreateAttendance) => void;
    updateAttendance: (id: number, request: IUpdateAttendance) => void;
    deleteAttendance: (id: number) => void;

    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IAttendanceQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createAttendance: DEFAULT_FUNCTION,
    updateAttendance: DEFAULT_FUNCTION,
    deleteAttendance: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const AttendanceContext = createContext(externalState);

export default AttendanceContext;
