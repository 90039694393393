import { Descriptions as AntdDescriptions, Input, List, Tabs, Tag } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PermanentLocationStatus } from '../../../models/permanent-location/enum'
import { IPermanentLocation } from '../../../models/permanent-location/response'
import Descriptions from '../../general/antd/descriptions'
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import { ServiceStatus } from '../../../models/service/enum'
import Image from '../../general/antd/image'
import MapBox from '../../general/mapbox'

const { TabPane } = Tabs

interface Props {
  permanentLocation?: IPermanentLocation
}

const PermanentLocationDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [services, setServices] = useState(props?.permanentLocation?.services)

  useEffect(() => {
    props?.permanentLocation?.services &&
      setServices(props?.permanentLocation?.services)
  }, [props?.permanentLocation?.services])

  return (
    <>
      <Descriptions size='middle' column={1} bordered>
        <AntdDescriptions.Item label={t('id')}>
          {props.permanentLocation?.id}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('name')}>
          {props.permanentLocation?.name}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('status')}>
          {props?.permanentLocation?.status &&
            t(`${PermanentLocationStatus[props?.permanentLocation?.status]}`)}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('start_time')}>
          {props.permanentLocation?.startTime}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('close_time')}>
          {props.permanentLocation?.closeTime}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('additional_information')}>
          {props.permanentLocation?.additionalInformation}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('city')}>
          {props.permanentLocation?.city?.name}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t('radius')}>
          {props.permanentLocation?.radius}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={`${t('lng')}, ${t('lat')}`}>
          {props.permanentLocation?.lat}, {props.permanentLocation?.lng}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('location')}>
          {props.permanentLocation && (
            <div style={{ height: '20rem', minWidth: '20rem' }}>
              <MapBox
                coordinates={[
                  props.permanentLocation.lng,
                  props.permanentLocation.lat,
                ]}
              />
            </div>
          )}
        </AntdDescriptions.Item>

        {/* Todo: Map with radius*/}
      </Descriptions>

      <Tabs defaultActiveKey='1' animated type='line'>
        <TabPane tab={t('services')} key='1'>
          <List
            header={
              <Input
                placeholder={t('search_hint')}
                suffix={<SearchOutlined />}
                allowClear
                onChange={(event) => {
                  let searchKey = event.target.value

                  let filteredServices =
                    props.permanentLocation?.services?.filter((service) =>
                      service?.name?.includes(searchKey)
                    )

                  setServices(filteredServices)

                  if (searchKey == '') {
                    setServices(props.permanentLocation?.services)
                  }
                }}
              />
            }
            bordered
            dataSource={services ?? []}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Image
                      src={
                        item?.status == ServiceStatus.ACTIVE
                          ? item?.activeImage?.url
                          : item?.inactiveImage?.url
                      }
                    />
                  }
                  title={`${item?.name} (${item?.permanentLocationPrice} ${t(
                    'aed'
                  )})`}
                  description={item?.permanentLocationNote}
                />
                <div>
                  {item?.status && (
                    <Tag
                      color={
                        item?.status == ServiceStatus.ACTIVE
                          ? 'green'
                          : 'yellow'
                      }
                    >
                      {t(`${ServiceStatus[item?.status]}`)}
                    </Tag>
                  )}
                </div>
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </>
  )
}

export default PermanentLocationDetails
