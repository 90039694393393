import { createContext } from 'react'
import { IChangePassword, ILoginRequest } from '../../models/auth/request'
import { DEFAULT_FUNCTION } from '../../utils/helpers/constants'
import Permission from '../../utils/rbac/permissions'
import { IUser } from '../../models/user/response'

export type AuthLoading = 'login' | 'logout' | 'change_password' | 'roles'

export interface IInternalState {
  loading: AuthLoading[]

  isAuthenticated?: boolean
  authUser?: IUser
  userPermissions: Permission[]
}

export const internalState: IInternalState = {
  loading: [],

  userPermissions: [],
}

export interface IExternalState extends IInternalState {
  actions: {
    login: (request: ILoginRequest) => void
    logout: () => void
    changePassword: (request: IChangePassword) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    login: DEFAULT_FUNCTION,
    logout: DEFAULT_FUNCTION,
    changePassword: DEFAULT_FUNCTION,
  },
}

const AuthContext = createContext(externalState)

export default AuthContext
