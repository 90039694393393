import { IStaffQuery } from '../../models/staff/query'
import { ICreateStaff, IUpdateStaff } from '../../models/staff/request'
import { IStaff } from '../../models/staff/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class StaffService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllStaffs = (query: IStaffQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IStaff>>({
      path: `/dashboard/staff/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createStaff = (data: ICreateStaff, params?: RequestParams) =>
    this.http.request<IStaff>({
      path: '/dashboard/staff',
      method: 'POST',
      body: data,
      ...params,
    })

  updateStaff = (id: number, data: IUpdateStaff, params?: RequestParams) =>
    this.http.request<IStaff>({
      path: `/dashboard/staff/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteStaff = (id: number, params?: RequestParams) =>
    this.http.request<IStaff>({
      path: `/dashboard/staff/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default StaffService
