/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useReducer } from 'react'
import reducer from './reducer'
import FAQContext, { internalState } from './context'
import { execute } from '../../utils/api/api-execute'
import EndPoints from '../../services/end-points'
import { IFAQ } from '../../models/faq/response'
import { IFAQQuery } from '../../models/faq/query'
import AuthContext from '../auth/context'
import { ICreateFAQ, IUpdateFAQ } from '../../models/faq/request'

const FAQContextProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState)

  const { isAuthenticated } = useContext(AuthContext)
  /**
   * Get data when query changed
   */
  useEffect(() => {
    isAuthenticated && getData()
  }, [state.query, isAuthenticated])

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'list' } })

        const { data } = await EndPoints.faq.getAllFAQs(state.query)

        dispatch({ type: 'SET_LIST', payload: { list: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'list' } })
      },
      throwException: false,
    })
  }

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })

        const data = state.list?.data?.find((u) => u?.id === id)

        dispatch({ type: 'SET_DETAILS', payload: { details: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })
      },
      throwException: false,
    })
  }

  const setDetails = async (data?: IFAQ) => {
    dispatch({ type: 'SET_DETAILS', payload: { details: data } })
  }

  const createFAQ = async (request: ICreateFAQ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'create' },
        })

        await EndPoints.faq.createFAQ(request)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'create' },
        })
      },
      throwException: true,
    })
  }

  const updateFAQ = async (id: number, request: IUpdateFAQ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'update' },
        })
        await EndPoints.faq.updateFAQ(id, request)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'update' },
        })
      },
      throwException: true,
    })
  }

  const deleteFAQ = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete' },
        })

        await EndPoints.faq.deleteFAQ(id)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete' },
        })
      },
      throwException: true,
    })
  }

  const setSearch = (search?: string) => {
    dispatch({ type: 'SET_SEARCH', payload: { search } })
  }

  const setQuery = (query: IFAQQuery) => {
    dispatch({ type: 'SET_QUERY', payload: { query } })
  }

  return (
    <FAQContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createFAQ,
          updateFAQ,
          deleteFAQ,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </FAQContext.Provider>
  )
}

export default FAQContextProvider
