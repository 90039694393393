import { Descriptions as AntdDescriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { BannerStatus } from '../../../models/banner/enum'
import { IBanner } from '../../../models/banner/response'
import Descriptions from '../../general/antd/descriptions'
import Image from '../../general/antd/image/index'

interface Props {
  banner?: IBanner
}

const BannerDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.banner?.id}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('status')}>
        {props?.banner?.status && t(`${BannerStatus[props?.banner?.status]}`)}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('subscription')}>
        {props?.banner?.subscription?.mainName}
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default BannerDetails
