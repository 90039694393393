import { Descriptions as AntdDescriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { ICar } from '../../../models/car/response'
import Descriptions from '../../general/antd/descriptions'
import Image from '../../general/antd/image/index'
import ColorCircle from '../../general/color-circle'
import styles from './style.module.scss'

interface Props {
  car?: ICar
}

const CarDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.car?.id}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('client')}>
        {props.car?.createdBy &&
          `${props.car?.createdBy?.firstName} ${props.car?.createdBy?.lastName}`}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('city')}>
        {props.car?.city?.name}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('brand')}>
        {props.car?.brand?.name}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('year')}>
        {props.car?.year}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('plate_code')}>
        {props.car?.plateCode}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('plate_number')}>
        {props.car?.plateNumber}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('car_color')}>
        {/* {props.car?.carColor?.name} ({props.car?.carColor?.hex}) */}
        <ColorCircle color={props.car?.carColor?.hex} />
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('car_image')}>
        <Image
          className={styles.change}
          preview
          src={props.car?.carImage?.url}
        />
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default CarDetails
