import {useTranslation} from 'react-i18next'
import {IRoute} from '../../routes/routes'
import {useContext, useState} from 'react'
import eventManager, {EVENT_SUCCESS} from '../../utils/events'
import PageHeader from '../../components/general/antd/page-header'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import InfoDrawer from '../../components/general/info-drawer'
import TransactionStaffToAdminContext from '../../contexts/transaction-staff-to-admin/context'
import TransactionStaffToAdminContextProvider from '../../contexts/transaction-staff-to-admin/provider'
import TransactionStaffToAdminTable from '../../components/transaction-staff-to-admin/transaction-staff-to-admin-table'
import ApproveFormModal from '../../components/form-components/approve-modal'
import TransactionStaffToAdminFilters
    from '../../components/transaction-staff-to-admin/transaction-staff-to-admin-filters'
import StaffSelectOptionsContextProvider from "../../contexts/select-options/staff-select-options/provider";

interface Props {
    route: IRoute
}

const TransactionsStaffToAdmin: React.FC<Props> = (props) => {
    const {t} = useTranslation()

    const {details, actions, loading} = useContext(
        TransactionStaffToAdminContext
    )

    const onApproveRequest = async () => {
        details && (await actions.confirmTransactionStaffToAdmin(details.id))

        eventManager.emit(EVENT_SUCCESS)
    }

    const [approveFormVisable, setApproveFormVisable] = useState(false)

    const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

    return (
        <>
            <PageHeader
                title={t(props.route.labelKey)}
                subTitle={t('manageRecource', {resource: t(props.route.labelKey)})}
                avatar={{
                    icon: props.route.icon,
                }}
                extra={
                    <Space size={0}>
                        <RefreshBtn
                            loading={loading.includes('list')}
                            onClick={() => actions.getData()}
                        >
                            {t('refresh')}
                        </RefreshBtn>
                    </Space>
                }
            />

            <StaffSelectOptionsContextProvider>
                <TransactionStaffToAdminFilters/>
            </StaffSelectOptionsContextProvider>

            <TransactionStaffToAdminTable
                showApproveFormModal={() => setApproveFormVisable(true)}
                showDetailsDrawer={() => setDetailsDrawerVisable(true)}
            />

            <ApproveFormModal
                loading={loading.includes('update')}
                visable={approveFormVisable}
                hideModal={() => setApproveFormVisable(false)}
                onSubmit={onApproveRequest}
            />
        </>
    )
}

const TransactionStaffToAdminPage: React.FC<Props> = (props) => {
    return (
        <TransactionStaffToAdminContextProvider>
            <TransactionsStaffToAdmin {...props} />
        </TransactionStaffToAdminContextProvider>
    )
}
export default TransactionStaffToAdminPage
