import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import eventManager, { EVENT_SUCCESS } from '../../utils/events'
import ConfigContextProvider from '../../contexts/config/provider'
import PageHeader from '../../components/general/antd/page-header'
import ConfigContext from '../../contexts/config/context'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import ConfigTable from '../../components/config/config-table'
import ConfigDetails from '../../components/config/config-details'
import ConfigFormDrawer from '../../components/config/config-form-drawer'
import InfoDrawer from '../../components/general/info-drawer'
import ConfigFilters from '../../components/config/config-filters'

interface Props {
  route: IRoute
}

const Configs: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(ConfigContext)

  const onSubmit = async (data: any) => {
    details && (await actions.updateConfig(details.key, data))
    eventManager.emit(EVENT_SUCCESS)
  }

  const [formDrawerVisable, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />

      <ConfigFilters />

      <ConfigTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <ConfigFormDrawer
        formId={'config-form'}
        visable={formDrawerVisable}
        defaultValues={
          details && {
            key: details?.key,
            value: details?.value,
          }
        }
        details={details}
        loading={loading.includes('create') || loading.includes('update')}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      />

      <InfoDrawer
        title={t('config_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <ConfigDetails config={details} />
      </InfoDrawer>
    </>
  )
}

const ConfigsPage: React.FC<Props> = (props) => {
  return (
    <ConfigContextProvider>
      <Configs {...props} />
    </ConfigContextProvider>
  )
}
export default ConfigsPage
