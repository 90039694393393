import { Descriptions as AntdDescriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  ClientAgeGroup,
  ClientGender,
  ClientStatus,
  getClientAgeGroup,
  getClientGender,
} from '../../../models/client/enum'
import { IClient } from '../../../models/client/response'
import Descriptions from '../../general/antd/descriptions'

interface Props {
  client?: IClient
}

const ClientDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.client?.id}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('username')}>
        {props.client?.user?.username}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('name')}>
        {`${props.client?.user?.firstName} ${props.client?.user?.lastName}`}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('email')}>
        {props.client?.email}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('phone')}>
        {props.client?.phone}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('status')}>
        {props?.client?.status && t(`${ClientStatus[props?.client?.status]}`)}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('age_group')}>
        {getClientAgeGroup(props?.client?.ageGroup as ClientAgeGroup)}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('gender')}>
        {t(`${getClientGender(props?.client?.gender as ClientGender)}`)}
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default ClientDetails
