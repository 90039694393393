import { IServiceQuery } from '../../models/service/query'
import { IService } from '../../models/service/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { toggleLoading } from '../../utils/helpers/functions'
import paramsReducer, { QueryAction } from './../base/base-reducer'
import { IInternalState, ServiceLoading } from './context'

type Action =
  | { type: 'LOADING'; payload: { loading: ServiceLoading | ServiceLoading[] } }
  | { type: 'SET_LIST'; payload: { list: IBaseListingResponse<IService> } }
  | { type: 'SET_DETAILS'; payload: { details?: IService } }
  | QueryAction<IServiceQuery>

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      }
    case 'SET_LIST':
      return {
        ...state,
        list: action.payload.list,
      }

    case 'SET_DETAILS':
      return {
        ...state,
        details: action.payload.details,
      }

    default:
      return paramsReducer(state, action)
  }
}

export default reducer
