import { createContext } from 'react'
import { IStaffQuery } from '../../../models/staff/query'
import { IStaff } from '../../../models/staff/response'
import IBaseListingResponse from '../../../utils/api/base-listing-response'
import {
  DEFAULT_FUNCTION,
  DEFAULT_MAXIMUM_PAGE_SIZE,
  INITIAL_PAGE,
} from '../../../utils/helpers/constants'

export type StaffLoading = 'list'

export interface IInternalState {
  loading: StaffLoading[]

  list?: IBaseListingResponse<IStaff>
  query: IStaffQuery
}

export const internalState: IInternalState = {
  loading: [],
  query: {
    page: INITIAL_PAGE,
    perPage: DEFAULT_MAXIMUM_PAGE_SIZE,
  },
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void

    setSearch: (search?: string) => void
    setQuery: (query: IStaffQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const StaffSelectOptionsContext = createContext(externalState)

export default StaffSelectOptionsContext
