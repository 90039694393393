import { IBrandQuery } from '../../models/brand/query'
import { IBrand } from '../../models/brand/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { toggleLoading } from '../../utils/helpers/functions'
import paramsReducer, { QueryAction } from './../base/base-reducer'
import { IInternalState, BrandLoading } from './context'

type Action =
  | { type: 'LOADING'; payload: { loading: BrandLoading | BrandLoading[] } }
  | { type: 'SET_LIST'; payload: { list: IBaseListingResponse<IBrand> } }
  | { type: 'SET_DETAILS'; payload: { details?: IBrand } }
  | QueryAction<IBrandQuery>

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      }
    case 'SET_LIST':
      return {
        ...state,
        list: action.payload.list,
      }

    case 'SET_DETAILS':
      return {
        ...state,
        details: action.payload.details,
      }

    default:
      return paramsReducer(state, action)
  }
}

export default reducer
