import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import {
  Descriptions as AntdDescriptions,
  Input,
  List,
  Tabs,
  Timeline,
  Typography,
} from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getRequestStatusColor,
  RequestStatus,
} from '../../../models/request/enum'
import { IRequest } from '../../../models/request/response'
import Descriptions from '../../general/antd/descriptions'
import { Card } from 'antd'
import { formatDate } from '../../../utils/helpers/functions'
import Image from '../../general/antd/image'

const { TabPane } = Tabs
const { Text } = Typography

interface Props {
  request?: IRequest
}

const RequestDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [models, setModels] = useState(props.request?.products)

  useEffect(() => {
    props.request?.products && setModels(props.request?.products)
  }, [props.request?.products])

  return (
    <>
      <Descriptions size='middle' column={1} bordered>
        <AntdDescriptions.Item label={t('id')}>
          {props.request?.id}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('created_by')}>
          {props.request?.createdBy &&
            `${props.request?.createdBy?.firstName} ${props.request?.createdBy?.lastName}`}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('note')}>
          {props.request?.note}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('date')}>
          {props.request?.date}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t('status')}>
          {props?.request?.status &&
            t(`${RequestStatus[props?.request?.status]}`)}
        </AntdDescriptions.Item>
      </Descriptions>

      <Tabs defaultActiveKey='1' animated type='line'>
        <TabPane tab={t('products')} key='1'>
          <List
            header={
              <Input
                placeholder={t('search_hint')}
                suffix={<SearchOutlined />}
                allowClear
                onChange={(event) => {
                  let searchKey = event.target.value

                  let filteredModels = props.request?.products?.filter(
                    (model) => model?.name?.includes(searchKey)
                  )

                  setModels(filteredModels)

                  if (searchKey == '') {
                    setModels(props.request?.products)
                  }
                }}
              />
            }
            bordered
            dataSource={models ?? []}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Image src={item?.image?.url} />}
                  title={item?.name}
                  description={`${item?.amount} ${
                    item?.description ? `| ${item?.description}` : ''
                  } ${item?.price ? `| ${item?.price} ${t('AED')}` : ''}`}
                />
              </List.Item>
            )}
          />
        </TabPane>

        <TabPane tab={t('request_history')} key='2'>
          <Card title={t('history_of_request_status')}>
            <Timeline mode='left' style={{ paddingTop: '2rem' }}>
              {props?.request?.requestProgress?.map((requestProgres, index) => {
                return (
                  <Timeline.Item
                    key={index}
                    color={getRequestStatusColor(
                      requestProgres?.progressStatus
                    )}
                    label={formatDate(requestProgres?.createdAt)}
                  >
                    <>
                      <div>
                        {t(`${RequestStatus[requestProgres?.progressStatus]}`)}
                      </div>
                      <Text type='secondary'>
                        <span>{t('note')}: </span>
                        {requestProgres?.progressNote}
                      </Text>
                    </>
                  </Timeline.Item>
                )
              })}
            </Timeline>
          </Card>
        </TabPane>
      </Tabs>
    </>
  )
}

export default RequestDetails
