export enum PermanentLocationStatus {
  ACTIVE = 1,
  BUSY = 2,
}

// Status Orders Colors
export const getPermanentLocationStatusColor = (
  status: PermanentLocationStatus
) => {
  switch (status) {
    case PermanentLocationStatus.ACTIVE:
      return 'green'
    case PermanentLocationStatus.BUSY:
      return 'yellow'
    default:
      return 'gray'
  }
}
