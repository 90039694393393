export enum ServiceStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

// Service Status Colors
export const getServiceStatusColor = (status: ServiceStatus) => {
  switch (status) {
    case ServiceStatus.ACTIVE:
      return 'green'
    case ServiceStatus.INACTIVE:
      return 'red'
    default:
      return 'gray'
  }
}
