import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import eventManager, { EVENT_ERORR, EVENT_SUCCESS } from '../../utils/events'
import PermanentLocationContextProvider from '../../contexts/permanent-location/provider'
import PageHeader from '../../components/general/antd/page-header'
import PermanentLocationContext from '../../contexts/permanent-location/context'
import CreateBtn from '../../components/table-components/actions/create-btn'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import PermanentLocationTable from '../../components/permanent-location/permanent-location-table'
import PermanentLocationDetails from '../../components/permanent-location/permanent-location-details'
import PermanentLocationFormDrawer from '../../components/permanent-location/permanent-location-form-drawer'
import InfoDrawer from '../../components/general/info-drawer'
import { getTranslations } from '../../utils/helpers/translations'
import PermanentLocationFilters from '../../components/permanent-location/permanent-location-filters'
import {
  checkObjectTranslations,
  checkObjectValuesTranslations,
  checkTranslations,
} from '../../utils/helpers/constants'
import { ICreatePermanentLocation } from '../../models/permanent-location/request'
import CitySelectOptionsContextProvider from '../../contexts/select-options/city-select-options/provider'

interface Props {
  route: IRoute
}

const PermanentLocations: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(PermanentLocationContext)

  const onSubmit = async (data: any) => {
    data.startTime = data.time[0]
    data.closeTime = data.time[1]
    console.log(data)

    if (checkObjectTranslations(data.additionalInformation) === false) {
      data.additionalInformation = undefined
    }

    if (checkObjectTranslations(data.additionalInformation === true)) {
      if (
        checkTranslations(data.name) === true &&
        checkObjectValuesTranslations(data.additionalInformation)
      ) {
        details
          ? await actions.updatePermanentLocation(details.id, data)
          : await actions.createPermanentLocation(data)
        eventManager.emit(EVENT_SUCCESS)
      } else {
        eventManager.emit(EVENT_ERORR, t('translations_required_message'))
        throw 'tranlations required'
      }
    } else {
      if (checkTranslations(data.name) === true) {
        details
          ? await actions.updatePermanentLocation(details.id, data)
          : await actions.createPermanentLocation(data)
        eventManager.emit(EVENT_SUCCESS)
      } else {
        eventManager.emit(EVENT_ERORR, t('translations_required_message'))
        throw 'tranlations required'
      }
    }
  }

  const [formDrawerVisable, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <CreateBtn
              onClick={() => {
                actions.setDetails(undefined)
                setFormDrawerVisable(true)
              }}
            />
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />
      <CitySelectOptionsContextProvider>
        <PermanentLocationFilters />
      </CitySelectOptionsContextProvider>

      <PermanentLocationTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <PermanentLocationFormDrawer
        formId={'permanent-location-form'}
        visable={formDrawerVisable}
        defaultValues={
          details && {
            id: details?.id,
            name: getTranslations(details?.translations?.name),
            additionalInformation: getTranslations(
              details?.translations?.additionalInformation
            ),
            status: details?.status,
            cityId: details?.city?.id,
            lat: details?.lat,
            lng: details?.lng,
            radius: details?.radius,
            time: [details?.startTime, details?.closeTime],
            // services: details?. // Todo: Services in Permanent Locations
          }
        }
        details={details}
        loading={loading.includes('create') || loading.includes('update')}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      />

      <InfoDrawer
        title={t('permanent_location_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <PermanentLocationDetails permanentLocation={details} />
      </InfoDrawer>
    </>
  )
}

const PermanentLocationsPage: React.FC<Props> = (props) => {
  return (
    <PermanentLocationContextProvider>
      <PermanentLocations {...props} />
    </PermanentLocationContextProvider>
  )
}
export default PermanentLocationsPage
