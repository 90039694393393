export enum SubscriptionStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

// Status Orders Colors
export const getSubcriptionStatusColor = (status: SubscriptionStatus) => {
  switch (status) {
    case SubscriptionStatus.ACTIVE:
      return 'green'
    case SubscriptionStatus.INACTIVE:
      return 'red'
    default:
      return 'gray'
  }
}
