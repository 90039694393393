import React from "react";
import { useContext } from "react";
import { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import AddressContext from "../../../contexts/address/context";
import { IAddress } from "../../../models/address/response";
import Space from "../../general/antd/space";
import ViewBtn from "../../table-components/actions/view-btn";
import Table from "../../table-components/table";
import DeleteBtn from "../../table-components/actions/delete-btn";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { ICity } from "../../../models/city/response";
import { IUser } from "../../../models/user/response";
import { Tag } from "antd";
import {
  AddressStatus,
  getAddressStatusColor,
} from "../../../models/address/enum";
import { useState } from "react";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { useEffect } from "react";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";

interface Props {
  showFormDrawer: () => void;
  showDetailsDrawer: () => void;
}

const AddressTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    list: addresses,
    actions,
    loading,
    query,
  } = useContext(AddressContext);

  const columns: ColumnProps<IAddress>[] = [
    {
      key: "id",
      title: t("id"),
      dataIndex: "id",
      sorter: {
        multiple: 1,
      },
    },
    {
      key: "name",
      title: t("name"),
      dataIndex: "name",
      sorter: {
        multiple: 2,
      },
    },
    {
      key: "createdBy",
      title: t("client"),
      dataIndex: "createdBy",
      render: (createdBy: IUser) => {
        return createdBy && `${createdBy?.firstName} ${createdBy?.lastName}`;
      },
    },
    {
      key: "status",
      title: t("status"),
      dataIndex: "status",
      render: (status: AddressStatus) => {
        return (
          status && (
            <Tag color={getAddressStatusColor(status)}>
              {t(`${AddressStatus[status]}`)}
            </Tag>
          )
        );
      },
    },
    {
      key: "actions",
      title: t("actions"),
      align: "center",
      width: 200,
      render: (_, record) => (
        <Space>
          <ViewBtn
            onClick={() => {
              actions.setDetails(record);
              props.showDetailsDrawer();
            }}
          />
          {/* <EditBtn
            onClick={() => {
              actions.setDetails(record)
              props.showFormDrawer()
            }}
          /> */}
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteAddress(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];
  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(
    query?.staticFilters ?? []
  );

  useEffect(() => {
    sorts &&
      actions.setQuery({
        ...query,
        sorts: sorts,
      });
  }, [sorts]);

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={addresses?.data}
      loading={loading.includes("list")}
      showPagination
      total={addresses?.meta?.total}
      scroll={{ x: 200 }}
      page={query.page}
      pageSize={query.perPage}
      onPaginationChange={(page, pageSize) => {
        actions.setQuery({
          ...query,
          page,
          perPage: pageSize,
        });
      }}
      onChange={(_, tableFilters, tabelSorters) => {
        tableOnChange(
          tableFilters,
          tabelSorters,
          tableFiltersProps,
          setSorts,
          setStaticFilters
        );
      }}
    />
  );
};
console.log("🚀 ~ file: index.tsx:154 ~ AddressTable:", AddressTable);

export default AddressTable;
