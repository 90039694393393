import {Col, Row} from 'antd'
import React, {useContext, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import FieldBuilder from '../../form-components/field-builder'
import FilterCard from '../../general/filter-card'
import Form from '../../form-components/from'
import {INITIAL_PAGE} from '../../../utils/helpers/constants'

import styles from './style.module.scss'
import TransactionAdminToStaffContext from '../../../contexts/transaction-admin-staff/context'
import {TransactionStaffToAdminStatus} from "../../../models/transaction-staff-to-admin/enum";
import {TransactionAdminToStaffStatus} from "../../../models/transaction-admin-staff/enum";
import StaffSelectOptionsContext from "../../../contexts/select-options/staff-select-options/context";

interface IProps {
}

const TransactionAdminToStaffFilters: React.FC<IProps> = () => {
    const {t} = useTranslation()
    const {actions, loading, query} = useContext(TransactionAdminToStaffContext)

    const {
        list: staffs,
        actions: staffActions,
        loading: staffLoading,
    } = useContext(StaffSelectOptionsContext)

    useEffect(() => {
        staffActions.getData()
    }, [])

    return (
        <div className={styles.formContainer}>
            <Form
                formId='transaction-admin-to-staff-filter'
                onSubmit={(data) => {
                    const filter: any = {
                        page: INITIAL_PAGE,
                        perPage: query?.perPage,
                        keyword: data?.keyword,
                        staticFilters: [
                            data?.staffs?.length > 0
                                ? {
                                    name: 'staffId',
                                    value: [...(data?.staffs ?? [])],
                                    // == != > < >= <= in
                                    operation: 'in',
                                }
                                : undefined,
                            data?.status?.length > 0
                                ? {
                                    name: 'status',
                                    value: [...(data?.status ?? [])],
                                    // == != > < >= <= in
                                    operation: 'in',
                                }
                                : undefined,
                            data?.date?.length > 0
                                ? {
                                    name: 'depositDate',
                                    value: data?.date[0],
                                    operation: '>=',
                                } : undefined,
                            data?.date?.length > 0
                                ? {
                                    name: 'depositDate',
                                    value: data?.date[1],
                                    operation: '<='
                                } : undefined,

                        ].filter(f => f)
                    }
                    actions.setQuery(filter)
                }}
            >
                <FilterCard
                    formId='transaction-admin-to-staff-filter'
                    applyLoading={loading.includes('list')}
                    resetLoading={loading.includes('list')}
                    onReset={() => {
                        actions.setQuery({
                            page: query?.page,
                            perPage: query?.perPage,
                        })
                    }}
                >
                    <Row justify={'start'} gutter={[10, 0]} className={styles.filters}>
                        <Col span={8}>
                            <FieldBuilder
                                name='keyword'
                                label={t('search')}
                                input={{type: 'text'}}
                            />
                        </Col>
                        <Col span={8}>
                            <FieldBuilder
                                name='staffs'
                                label={t('staffs')}
                                input={{
                                    type: 'multiSelect',
                                    loading: staffLoading.includes('list'),
                                    allowSearch: true,

                                    options: staffs?.data.map((staff) => {
                                        return {
                                            value: staff.user.id,
                                            label: `${staff.user.firstName} ${staff.user.lastName}`
                                        }
                                    }),
                                }}
                            />
                        </Col>
                        <Col span={8}>
                            <FieldBuilder
                                name='status'
                                label={t('status')}
                                input={{
                                    type: 'multiSelect',
                                    options: [
                                        {
                                            value: TransactionAdminToStaffStatus.CONFIRMED,
                                            label: t(
                                                `${
                                                    TransactionAdminToStaffStatus[
                                                        TransactionAdminToStaffStatus.CONFIRMED
                                                        ]
                                                }`
                                            ),
                                        },
                                        {
                                            value: TransactionAdminToStaffStatus.REQUESTED,
                                            label: t(
                                                `${
                                                    TransactionAdminToStaffStatus[TransactionAdminToStaffStatus.REQUESTED]
                                                }`
                                            ),
                                        },

                                    ],
                                }}
                            />
                        </Col>
                        <Col span={8}>
                            <FieldBuilder name='date' input={
                                {
                                    type: 'range-picker'
                                }
                            }/>
                        </Col>
                    </Row>
                </FilterCard>
            </Form>
        </div>
    )
}

export default TransactionAdminToStaffFilters
