import React, { useContext, useEffect, useState } from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { languages } from '../../../utils/helpers/constants'
import { Tabs, Divider, Input, Row, Col, List, Tag, Form } from 'antd'
import TranslationsCard from '../../form-components/translations-card'
import AddBtn from '../../table-components/actions/add-btn'
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import Image from '../../general/antd/image'

import {
  ISubscription,
  ISubscriptionCity,
  ISubscriptionOfferService,
  ISubscriptionService,
} from '../../../models/subscription/response'
import ServiceSelectOptionsContext from '../../../contexts/select-options/service-select-options/context'
import DeleteBtn from '../../table-components/actions/delete-btn'
import { ServiceStatus } from '../../../models/service/enum'
import FormModal from '../../form-components/form-modal'
import AddServiceForm from '../../permanent-location/permanent-location-service-form'
import { Controller, useFormContext } from 'react-hook-form'
import AddSubscriptionServiceForm from '../subscription-service-form'
import { SubscriptionStatus } from '../../../models/subscription/enum'
import AddSubscriptionOfferServiceForm from '../subscription-offer-service-form'
import CitySelectOptionsContext from '../../../contexts/select-options/city-select-options/context'
import AddSubscriptionCityForm from '../subscription-city-form'
import OldImage from '../../form-components/old-image'
import ImageUploader from '../../form-components/image-uploader'
import { MediumFor } from '../../../models/medium/enum'
const { TabPane } = Tabs

interface IProps {
  subscription?: ISubscription
}

const SubscriptionForm: React.FC<IProps> = ({ subscription }) => {
  const { t } = useTranslation()
  const [services, setServices] = useState(subscription?.services)
  const [offerServices, setOfferServices] = useState(subscription?.services)
  const { setValue } = useFormContext()

  const [searchTerm, setSearchTerm] = useState('')
  // Old Image
  const [image, setImage] = useState(subscription?.image?.url)

  useEffect(() => {
    subscription?.image?.url && setImage(subscription?.image?.url)
  }, [subscription?.image?.url])

  const [addServiceModalVisible, setAddServiceModalVisible] = useState(false)
  const [addOfferServiceModalVisible, setAddOfferServiceModalVisible] =
    useState(false)

  useEffect(() => {
    serviceOptionsAction.getData()
  }, [])
  const {
    list: serviceOptions,
    actions: serviceOptionsAction,
    loading: serviceOptionsLoading,
  } = useContext(ServiceSelectOptionsContext)

  useEffect(() => {
    subscription?.services && setServices(subscription?.services)
    subscription?.offerServices && setOfferServices(subscription?.offerServices)
  }, [subscription?.services])

  const addService = (
    serviceId: number,
    duration: number,
    serviceAmount: number
  ) => {
    let selectedService = serviceOptions?.data.find((s) => s.id == serviceId)
    let newService: ISubscriptionService
    if (selectedService) {
      newService = {
        ...selectedService,
        duration: duration,
        serviceAmount: serviceAmount,
      }
      if (services) {
        setServices([...services, newService])
      } else {
        setServices([newService])
      }
    }
  }
  const addOfferService = (serviceId: number, price: number) => {
    let selectedService = serviceOptions?.data.find((s) => s.id == serviceId)
    let newService: ISubscriptionOfferService
    if (selectedService) {
      newService = {
        ...selectedService,
        price: price as number,
      }
      if (offerServices) {
        setOfferServices([...offerServices, newService])
      } else {
        setOfferServices([newService])
      }
    }
  }

  const deleteService = (serviceId: number) => {
    setServices(services?.filter((s) => s.id != serviceId))
  }
  const deleteOfferService = (serviceId: number) => {
    setOfferServices(offerServices?.filter((s) => s.id != serviceId))
  }

  useEffect(() => {
    setValue('services', undefined)
    services &&
      services.forEach((service, index) => {
        setValue(`services[${index}].serviceId`, service?.id)
        setValue(`services[${index}].serviceAmount`, service?.serviceAmount)
        setValue(`services[${index}].duration`, service?.duration)
      })
  }, [services])

  useEffect(() => {
    setValue('offerServices', undefined)
    offerServices &&
      offerServices.forEach((service, index) => {
        setValue(`offerServices[${index}].serviceId`, service?.id)
        setValue(`offerServices[${index}].price`, service?.price)
      })
  }, [offerServices])

  //Cities
  const [cities, setCities] = useState(subscription?.cities)

  useEffect(() => {
    subscription?.cities && setCities(subscription?.cities)
  }, [subscription?.cities])

  const {
    list: cityOptions,
    actions: cityOptionsAction,
    loading: cityOptionsLoading,
  } = useContext(CitySelectOptionsContext)

  // Cities Management
  const [addCityModalVisible, setAddCityModalVisible] = useState(false)

  const addCity = (cityId: number, price: number, note: string) => {
    let selectedCity = cityOptions?.data.find((s) => s.id == cityId)
    let newCity: ISubscriptionCity
    if (selectedCity) {
      newCity = {
        ...selectedCity,
        subscriptionCityPrice: price,
        note: note,
      }
      if (cities) {
        setCities([...cities, newCity])
      } else {
        setCities([newCity])
      }
    }
  }

  const deleteCity = (cityId: number) => {
    setCities(cities?.filter((c) => c.id != cityId))
  }

  useEffect(() => {
    setValue('cities', undefined)
    cities &&
      cities.forEach((city, index) => {
        setValue(`cities[${index}].cityId`, city?.id)
        setValue(`cities[${index}].price`, +city?.subscriptionCityPrice)
        setValue(`cities[${index}].note`, city?.note)
      })
  }, [cities])

  return (
    <div>
      <TranslationsCard>
        <Tabs type='line'>
          {languages?.map((lang, index) => {
            return (
              <TabPane tab={lang.name} key={index}>
                <FieldBuilder
                  key={index}
                  name={`mainName[${lang?.code}]`}
                  label={`${t('main_name')} (${lang?.name})`}
                  input={{ type: 'text' }}
                  rules={{ required: true }}
                />
                <FieldBuilder
                  key={index}
                  name={`subName[${lang?.code}]`}
                  label={`${t('sub_name')} (${lang?.name})`}
                  input={{ type: 'text' }}
                  rules={{ required: true }}
                />
                <FieldBuilder
                  key={index}
                  name={`additionalInformation[${lang?.code}]`}
                  label={`${t('additional_information')} (${lang?.name})`}
                  input={{ type: 'text' }}
                  rules={{ required: true }}
                />
              </TabPane>
            )
          })}
        </Tabs>
      </TranslationsCard>

      {/* Upload Image */}
      <div>
        <Form.Item label={t('image')} required>
          <Controller
            name='imageId'
            // rules={{
            //   required: {
            //     value: true,
            //     message: `${t('field_is_required_message')}`,
            //   },
            // }}
            render={({ field: { ref, ...field } }) => {
              return (
                <ImageUploader
                  {...field}
                  onChange={(response) => {
                    setImage(undefined)
                    field.onChange(response)
                  }}
                  imageFor={MediumFor.SUBSCRIPTION_IMAGE}
                />
              )
            }}
          />
        </Form.Item>
      </div>

      {/* Old  Image */}
      {image && <OldImage src={image} />}

      <Divider />
      <Row gutter={12}>
        <Col span={12}>
          <FieldBuilder
            name='period'
            label={t('period')}
            input={{ type: 'number' }}
            rules={{ required: true }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            name='price'
            label={t('price')}
            input={{ type: 'number' }}
            rules={{ required: true }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            name='discountPercentage'
            label={t('discount_percentage')}
            input={{ type: 'number' }}
            rules={{ required: true, min: 1, max: 100 }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            name='carsCount'
            label={t('cars_count')}
            input={{ type: 'number' }}
            rules={{ required: true }}
          />
        </Col>
      </Row>

      {/* Status */}
      <FieldBuilder
        name='status'
        label={t('status')}
        rules={{ required: true }}
        input={{
          type: 'select',
          options: [
            {
              value: SubscriptionStatus.ACTIVE,
              label: t(`${SubscriptionStatus[SubscriptionStatus.ACTIVE]}`),
            },
            {
              value: SubscriptionStatus.INACTIVE,
              label: t(`${SubscriptionStatus[SubscriptionStatus.INACTIVE]}`),
            },
          ],
        }}
      />

      <Divider />
      {/* Services & Location*/}
      <Tabs defaultActiveKey='1' animated type='line'>
        <TabPane tab={t('services')} key='1'>
          <List
            bordered={false}
            header={
              <Row justify='space-between'>
                <Col>
                  <AddBtn
                    title={t('add_service')}
                    onClick={() => {
                      // Show Modal
                      setAddServiceModalVisible(true)
                    }}
                  />
                </Col>
                <Col>
                  <Input
                    placeholder={t('search_hint')}
                    suffix={<SearchOutlined />}
                    allowClear
                    onChange={(event) => {
                      setSearchTerm(event.target.value)
                    }}
                  />
                </Col>
              </Row>
            }
            dataSource={
              services?.filter((service) =>
                service.name.toLowerCase().includes(searchTerm.toLowerCase())
              ) ?? []
            }
            renderItem={(item) => (
              <List.Item
                actions={[
                  <DeleteBtn
                    onConfirm={() => {
                      deleteService(item?.id)
                    }}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Image
                      src={
                        item?.status == ServiceStatus.ACTIVE
                          ? item?.activeImage?.url
                          : item?.inactiveImage?.url
                      }
                    />
                  }
                  title={`${item?.name} (${item?.duration} ${t('months')}) `}
                  description={item?.additionalInformation}
                />
                <div>
                  {item?.status && (
                    <Tag
                      color={
                        item?.status == ServiceStatus.ACTIVE ? 'green' : 'red'
                      }
                    >
                      {t(`${ServiceStatus[item?.status]}`)}
                    </Tag>
                  )}
                </div>
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab={t('offer_services')} key='2'>
          <List
            bordered={false}
            header={
              <Row justify='space-between'>
                <Col>
                  <AddBtn
                    title={t('add_offer_service')}
                    onClick={() => {
                      // Show Modal
                      setAddOfferServiceModalVisible(true)
                    }}
                  />
                </Col>
                <Col>
                  <Input
                    placeholder={t('search_hint')}
                    suffix={<SearchOutlined />}
                    allowClear
                    onChange={(event) => {
                      setSearchTerm(event.target.value)
                    }}
                  />
                </Col>
              </Row>
            }
            dataSource={
              offerServices?.filter((service) =>
                service.name.toLowerCase().includes(searchTerm.toLowerCase())
              ) ?? []
            }
            renderItem={(item) => (
              <List.Item
                actions={[
                  <DeleteBtn
                    onConfirm={() => {
                      deleteOfferService(item?.id)
                    }}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Image
                      src={
                        item?.status == ServiceStatus.ACTIVE
                          ? item?.activeImage?.url
                          : item?.inactiveImage?.url
                      }
                    />
                  }
                  title={`${item?.name} `}
                  description={
                    <>
                      <span style={{ display: 'block' }}>
                        {item?.additionalInformation}
                      </span>
                      <span>{`${t('price')}: ${item?.price}`}</span>
                    </>
                  }
                />
                <div>
                  {item?.status && (
                    <Tag
                      color={
                        item?.status == ServiceStatus.ACTIVE ? 'green' : 'red'
                      }
                    >
                      {t(`${ServiceStatus[item?.status]}`)}
                    </Tag>
                  )}
                </div>
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab={t('cities')} key='3'>
          <List
            bordered={false}
            header={
              <Row justify='space-between'>
                <Col>
                  <AddBtn
                    title={t('add_city')}
                    onClick={() => {
                      // Show Modal
                      setAddCityModalVisible(true)
                    }}
                  />
                </Col>
                <Col>
                  <Input
                    placeholder={t('search_hint')}
                    suffix={<SearchOutlined />}
                    allowClear
                    onChange={(event) => {
                      let searchKey = event.target.value
                      let filteredcities = subscription?.cities?.filter(
                        (subscription) =>
                          subscription?.name?.includes(searchKey)
                      )
                      setCities(filteredcities)
                      if (searchKey == '') {
                        setCities(subscription?.cities)
                      }
                    }}
                  />
                </Col>
              </Row>
            }
            dataSource={cities ?? []}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <DeleteBtn
                    onConfirm={() => {
                      deleteCity(item?.id)
                    }}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={<Image src={item?.plateImage?.url} />}
                  title={`${item?.name} (${item?.subscriptionCityPrice} ${t(
                    'aed'
                  )})`}
                  description={item?.note}
                />
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
      <FormModal
        title={t('add_service_to_subscription')}
        formId='add-subscription-service'
        hideModal={() => {
          setAddServiceModalVisible(false)
        }}
        visible={addServiceModalVisible}
        onSubmit={(data: {
          serviceId: number
          duration: number
          serviceAmount: number
        }) => {
          addService(data.serviceId, data.duration, data.serviceAmount)
        }}
        confirmLoading={false}
      >
        <AddSubscriptionServiceForm
          servicesIdsAlreadySelected={services ? services.map((s) => s.id) : []}
        />
      </FormModal>

      <FormModal
        title={t('add_offer_service_to_subscription')}
        formId='add-subscription-offer-service'
        hideModal={() => {
          setAddOfferServiceModalVisible(false)
        }}
        visible={addOfferServiceModalVisible}
        onSubmit={(data: { serviceId: number; price: number }) => {
          addOfferService(data.serviceId, data.price as number)
        }}
        confirmLoading={false}
      >
        <AddSubscriptionOfferServiceForm
          servicesIdsAlreadySelected={
            offerServices ? offerServices.map((s) => s.id) : []
          }
        />
      </FormModal>

      {/* Add City Modal */}
      <FormModal
        title={t('add_city_to_service')}
        formId='add-city-to-service'
        hideModal={() => {
          setAddCityModalVisible(false)
        }}
        visible={addCityModalVisible}
        onSubmit={(data: { cityId: number; price: number; note: string }) => {
          addCity(data.cityId, data.price, data.note)
        }}
        confirmLoading={false}
      >
        <AddSubscriptionCityForm
          citiesIdsAlreadySelected={cities ? cities.map((s) => s.id) : []}
        />
      </FormModal>
    </div>
  )
}

export default SubscriptionForm
