import { useTranslation } from "react-i18next";
import StaffSelectOptionsContextProvider from "../../../../contexts/select-options/staff-select-options/provider";
import FormModal from "../../../form-components/form-modal";
import TemplateExportExcel from "../../../general/expor-excel";
import {
  IExportOrderExcel,
  colomnsExportOrderExcel,
} from "../../../../models/order/request";

interface IProps {
  loading: boolean;
  visable: boolean;
  width?: string;
  hideModal: () => void;
  onSubmit: (data: any) => void;
}

const ExcelOrderFormModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <StaffSelectOptionsContextProvider>
      <FormModal
        defaultValues={
          {
            requiredDate: false,
            requiredTime: false,
            isFlexi: false,
            mainValue: false,
            totalValue: false,
            estimatedTime: false,
            clientPhone: false,
            clientName: false,
            city: false,
            promoCode: false,
            clientAddress: false,
            permanentLocation: false,
            staff: false,
            userType: false,
            paymentMethod: false,
            status: false,
            token: false,
            flexiPercentage: false,
            commission: false,
            tax: false,
          } as IExportOrderExcel
        }
        title={t("export_to_excel")}
        formId="excel_order"
        style={{ marginTop: "-3rem" }}
        width={props?.width ?? "60vw"}
        onSubmit={props.onSubmit}
        hideModal={props.hideModal}
        confirmLoading={props.loading}
        visible={props.visable}
      >
        <TemplateExportExcel names={colomnsExportOrderExcel} />
      </FormModal>
    </StaffSelectOptionsContextProvider>
  );
};

export default ExcelOrderFormModal;
