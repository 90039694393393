import axios from "axios";
import { IAttendanceQuery } from "../../models/attendance/query";
import {
  ICreateAttendance,
  IUpdateAttendance,
} from "../../models/attendance/request";
import { IAttendance } from "../../models/attendance/response";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

class AttendanceService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllAttendances = (query: IAttendanceQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IAttendance>>({
      path: `/dashboard/attendance/index`,
      method: "POST",
      body: query,
      ...params,
    });

  createAttendance = (data: ICreateAttendance, params?: RequestParams) =>
    this.http.request<IAttendance>({
      path: "/dashboard/attendance",
      method: "POST",
      body: data,
      ...params,
    });

  updateAttendance = (
    id: number,
    data: IUpdateAttendance,
    params?: RequestParams
  ) =>
    this.http.request<IAttendance>({
      path: `/dashboard/attendance/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteAttendance = (id: number, params?: RequestParams) =>
    this.http.request<IAttendance>({
      path: `/dashboard/attendance/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: any, query: IAttendanceQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/dashboard/attendance/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default AttendanceService;
