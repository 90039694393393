import { createContext } from 'react'
import { ISubscriptionQuery } from '../../models/subscription/query'
import {
  ICreateSubscription,
  IUpdateSubscription,
} from '../../models/subscription/request'
import {
  ISubscription,
  ISubscriptionDetails,
} from '../../models/subscription/response'

import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type SubscriptionLoading =
  | 'list'
  | 'details'
  | 'create'
  | 'update'
  | 'delete'

export interface IInternalState {
  loading: SubscriptionLoading[]

  list?: IBaseListingResponse<ISubscription>
  query: ISubscriptionQuery

  details?: ISubscriptionDetails
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: ISubscriptionDetails) => void

    createSubscription: (request: ICreateSubscription) => void
    updateSubscription: (id: number, request: IUpdateSubscription) => void
    deleteSubscription: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: ISubscriptionQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createSubscription: DEFAULT_FUNCTION,
    updateSubscription: DEFAULT_FUNCTION,
    deleteSubscription: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const SubscriptionContext = createContext(externalState)

export default SubscriptionContext
