interface IProps {
  color: string | undefined
}
const ColorCircle: React.FC<IProps> = (props) => {
  return (
    <div
      style={{
        backgroundColor: `${props.color ?? '#fff'}`,
        borderRadius: '50%',
        border: '2px solid rgb(232,232,232)',
        width: '25px',
        height: '25px',
        display: 'inline-block',
      }}
    ></div>
  )
}

export default ColorCircle
