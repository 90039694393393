import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

interface Props {}
const LoadingPage: React.FC<Props> = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div>
        <Spin tip={t('loading')} />
      </div>
    </div>
  )
}

export default LoadingPage
