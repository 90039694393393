import { Descriptions as AntdDescriptions, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  CategoryStatus,
  getCategoryStatusColor,
} from '../../../models/category/enum'
import { ICategory } from '../../../models/category/response'
import Descriptions from '../../general/antd/descriptions'
import Image from '../../general/antd/image/index'

interface Props {
  category?: ICategory
}

const CategoryDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.category?.id}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('name')}>
        {props.category?.name}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('status')}>
        {props?.category?.status && (
          <Tag color={getCategoryStatusColor(props?.category?.status)}>
            {t(`${CategoryStatus[props?.category?.status]}`)}
          </Tag>
        )}
      </AntdDescriptions.Item>

      <AntdDescriptions.Item label={t('active_image')}>
        <Image src={props.category?.activeImage?.url} />
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('inactive_image')}>
        <Image src={props.category?.inactiveImage?.url} />
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default CategoryDetails
