import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import eventManager, { EVENT_SUCCESS } from '../../utils/events'
import PromoCodeContextProvider from '../../contexts/promo-code/provider'
import PageHeader from '../../components/general/antd/page-header'
import PromoCodeContext from '../../contexts/promo-code/context'
import CreateBtn from '../../components/table-components/actions/create-btn'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import PromoCodeTable from '../../components/promo-code/promo-code-table'
import PromoCodeDetails from '../../components/promo-code/promo-code-details'
import PromoCodeFormDrawer from '../../components/promo-code/promo-code-form-drawer'
import InfoDrawer from '../../components/general/info-drawer'
import PromoCodeFilters from '../../components/promo-code/promo-code-filters'

interface Props {
  route: IRoute
}

const PromoCodes: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(PromoCodeContext)

  const onSubmit = async (data: any) => {
    details
      ? await actions.updatePromoCode(details.id, data)
      : await actions.createPromoCode(data)
    eventManager.emit(EVENT_SUCCESS)
  }

  const [formDrawerVisable, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <CreateBtn
              onClick={() => {
                actions.setDetails(undefined)
                setFormDrawerVisable(true)
              }}
            />
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />

      <PromoCodeFilters />

      <PromoCodeTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <PromoCodeFormDrawer
        formId={'promoCode-form'}
        visable={formDrawerVisable}
        defaultValues={
          details && {
            id: details?.id,
            code: details?.code,
            discountPercentage: details?.discountPercentage,
            amount: details?.amount,
            status: details?.status,
          }
        }
        details={details}
        loading={loading.includes('create') || loading.includes('update')}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      />

      <InfoDrawer
        title={t('promoCode_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <PromoCodeDetails promoCode={details} />
      </InfoDrawer>
    </>
  )
}

const PromoCodesPage: React.FC<Props> = (props) => {
  return (
    <PromoCodeContextProvider>
      <PromoCodes {...props} />
    </PromoCodeContextProvider>
  )
}
export default PromoCodesPage
