import axios from "axios";
import { IOrderQuery } from "../../models/order/query";
import { IApproveOrder, IRejectOrder } from "../../models/order/request";
import { IOrder } from "../../models/order/response";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

class OrderService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllOrders = (query: IOrderQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IOrder>>({
      path: `/dashboard/orders/index`,
      method: "POST",
      body: query,
      ...params,
    });

  approveOrder = (id: number, data: IApproveOrder, params?: RequestParams) =>
    this.http.request<IOrder>({
      path: `/dashboard/orders/${id}/approve-order`,
      method: "PUT",
      body: data,
      ...params,
    });

  rejectOrder = (id: number, data: IRejectOrder, params?: RequestParams) =>
    this.http.request<IOrder>({
      path: `/dashboard/orders/${id}/reject-order`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteOrder = (id: number, params?: RequestParams) =>
    this.http.request<IOrder>({
      path: `/dashboard/orders/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: any, query: IOrderQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/dashboard/orders/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default OrderService;
