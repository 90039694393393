export enum TransactionStaffToAdminStatus {
  REQUESTED = 1,
  CONFIRMED = 2,
}

// Service Status Colors
export const getTransactionStaffToAdminStatusColor = (
  status: TransactionStaffToAdminStatus
) => {
  switch (status) {
    case TransactionStaffToAdminStatus.CONFIRMED:
      return 'green'
    case TransactionStaffToAdminStatus.REQUESTED:
      return 'gray'
    default:
      return 'gray'
  }
}
