import { Descriptions as AntdDescriptions, Tag } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AttendanceStatus } from "../../../models/attendance/enum";
import { IAttendance } from "../../../models/attendance/response";
import { durationBetweenDates } from "../../../utils/helpers/functions";
import Descriptions from "../../general/antd/descriptions";
import Image from "../../general/antd/image/index";
import MapBox from "../../general/mapbox";

interface Props {
  attendance?: IAttendance;
}

const AttendanceDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Descriptions size="middle" column={1} bordered>
      {/* ID */}
      <AntdDescriptions.Item label={t("id")}>
        {props.attendance?.id}
      </AntdDescriptions.Item>

      {/* Staff */}
      <AntdDescriptions.Item label={t("staff")}>
        {props.attendance?.createdBy &&
          `${props.attendance?.createdBy?.firstName} ${props.attendance?.createdBy?.lastName}`}
      </AntdDescriptions.Item>

      {/* Attendance Date */}
      <AntdDescriptions.Item label={t("date")}>
        {props.attendance?.attendanceDate}
      </AntdDescriptions.Item>

      {/* Working hours */}
      {props.attendance?.checkInTime?.substring(0, 5) !== "00:00" &&
        props.attendance?.checkOutTime?.substring(0, 5) !== "00:00" && (
          <AntdDescriptions.Item label={t("working_hours")}>
            {durationBetweenDates(
              moment(props.attendance?.checkInTime, "HH:mm"),
              moment(props.attendance?.checkOutTime, "HH:mm")
            )}
          </AntdDescriptions.Item>
        )}

      {/* Check In Time */}
      <AntdDescriptions.Item label={t("check_in_time")}>
        {props.attendance?.checkInTime.substring(0, 5)}
      </AntdDescriptions.Item>

      {/* Check In Status */}
      <AntdDescriptions.Item label={t("check_in_status")}>
        <Tag color="grey">
          {props?.attendance?.checkInStatus &&
            t(`${AttendanceStatus[props?.attendance?.checkInStatus]}`)}
        </Tag>
      </AntdDescriptions.Item>

      {/* Check Out Time */}
      <AntdDescriptions.Item label={t("check_out_time")}>
        {props.attendance?.checkOutTime?.substring(0, 5)}
      </AntdDescriptions.Item>

      {/* Check Out Status */}
      <AntdDescriptions.Item label={t("check_out_status")}>
        <Tag color={"grey"}>
          {props?.attendance?.checkOutStatus &&
            t(`${AttendanceStatus[props?.attendance?.checkOutStatus]}`)}
        </Tag>
      </AntdDescriptions.Item>

      {/* Check In Location */}
      <AntdDescriptions.Item label={t("check_in_location")}>
        {props.attendance?.attendanceDate && (
          <div style={{ height: "20rem", minWidth: "20rem" }}>
            <MapBox
              coordinates={[
                props?.attendance?.checkInLng,
                props?.attendance?.checkInLat,
              ]}
            />
          </div>
        )}
      </AntdDescriptions.Item>

      {/* Check Out Location */}
      {props.attendance?.checkOutLng && (
        <AntdDescriptions.Item label={t("check_out_location")}>
          {props.attendance?.attendanceDate && (
            <div style={{ height: "20rem", minWidth: "20rem" }}>
              <MapBox
                coordinates={[
                  props?.attendance?.checkOutLng,
                  props?.attendance?.checkOutLat,
                ]}
              />
            </div>
          )}
        </AntdDescriptions.Item>
      )}

      {/* Check In Image */}
      <AntdDescriptions.Item label={t("check_in_image")}>
        <Image preview src={props.attendance?.checkInImage?.url} />
      </AntdDescriptions.Item>

      {/* Check Out Image */}
      <AntdDescriptions.Item label={t("check_out_image")}>
        <Image preview src={props.attendance?.checkOutImage?.url} />
      </AntdDescriptions.Item>

      {/* Note */}
      <AntdDescriptions.Item label={t("note")}>
        {props.attendance?.note}
      </AntdDescriptions.Item>
    </Descriptions>
  );
};

export default AttendanceDetails;
