export interface ICreateAttendance {}

export interface IUpdateAttendance {}
export interface IExportAttedanceExcel {
  checkInTime: boolean;
  checkOutTime: boolean;
  attendanceDate: boolean;
  note: boolean;
  checkInStatus: boolean;
  checkOutStatus: boolean;
  checkInLat: boolean;
  checkInLng: boolean;
  checkOutLat: boolean;
  checkOutLng: boolean;
  staff: boolean;
}
export const colomnsExportAttedanceExcel = [
  "checkInTime",
  "checkOutTime",
  "attendanceDate",
  "note",
  "checkInStatus",
  "checkOutStatus",
  "checkInLat",
  "checkInLng",
  "checkOutLat",
  "checkOutLng",
  "staff",
];
