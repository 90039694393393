import React, { useEffect, useState } from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { languages } from '../../../utils/helpers/constants'
import { Tabs, Divider, Form } from 'antd'
import TranslationsCard from '../../form-components/translations-card'
import ImageUploader from '../../form-components/image-uploader'
import Controller from '../../form-components/controller'
import { MediumFor } from '../../../models/medium/enum'
import { ICategory } from '../../../models/category/response'
import OldImage from '../../form-components/old-image'
import { CategoryStatus } from '../../../models/category/enum'

const { TabPane } = Tabs

interface IProps {
  category?: ICategory
}

const CategoryForm: React.FC<IProps> = ({ category }) => {
  const { t } = useTranslation()

  // Old Active Image
  const [activeImage, setActiveImage] = useState(category?.activeImage?.url)

  // Old Inactive Image
  const [inactiveImage, setInactiveImage] = useState(
    category?.inactiveImage?.url
  )

  useEffect(() => {
    category?.activeImage?.url && setActiveImage(category?.activeImage?.url)
    category?.inactiveImage?.url &&
      setInactiveImage(category?.inactiveImage?.url)
  }, [category])

  return (
    <div>
      <TranslationsCard>
        <Tabs type='line'>
          {languages?.map((lang, index) => {
            return (
              <TabPane tab={lang.name} key={index}>
                <FieldBuilder
                  key={index}
                  name={`name[${lang?.code}]`}
                  label={`${t('name')} (${lang?.name})`}
                  input={{ type: 'text' }}
                  rules={{ required: true }}
                />
              </TabPane>
            )
          })}
        </Tabs>
      </TranslationsCard>

      <Divider />

      {/* Status */}
      <FieldBuilder
        name='status'
        label={t('status')}
        rules={{ required: true }}
        input={{
          type: 'select',
          options: [
            {
              value: CategoryStatus.ACTIVE,
              label: t(`${CategoryStatus[CategoryStatus.ACTIVE]}`),
            },
            {
              value: CategoryStatus.INACTIVE,
              label: t(`${CategoryStatus[CategoryStatus.INACTIVE]}`),
            },
          ],
        }}
      />

      {/* Upload Active Image */}
      <div>
        <Form.Item label={t('active_image')} required>
          <Controller
            name='activeImageId'
            rules={{
              required: {
                value: true,
                message: `${t('field_is_required_message')}`,
              },
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <ImageUploader
                  {...field}
                  onChange={(response) => {
                    setActiveImage(undefined)
                    field.onChange(response)
                  }}
                  imageFor={MediumFor.CATEGORY_ACTIVE_IMAGE}
                />
              )
            }}
          />
        </Form.Item>
      </div>

      {/* Old Active Image */}
      {activeImage && <OldImage src={activeImage} />}

      {/* Upload Inactive Image */}
      <div>
        <Form.Item label={t('inactive_image')} required>
          <Controller
            name='inactiveImageId'
            rules={{
              required: {
                value: true,
                message: `${t('field_is_required_message')}`,
              },
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <ImageUploader
                  {...field}
                  onChange={(response) => {
                    setInactiveImage(undefined)
                    field.onChange(response)
                  }}
                  imageFor={MediumFor.CATEGORY_INACTIVE_IMAGE}
                />
              )
            }}
          />
        </Form.Item>
      </div>

      {/* Old Inactive Image */}
      {inactiveImage && <OldImage src={inactiveImage} />}
    </div>
  )
}

export default CategoryForm
