import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import FormDrawer from '../../form-components/form-drawer/index'
import AttendanceForm from '../attendance-form'
import { IAttendance } from '../../../models/attendance/response'

interface Props<DataType> {
  formId: string
  loading: boolean
  visable: boolean
  hideDrawer: () => void
  defaultValues?: DataType
  details?: IAttendance
  onSubmit: (data: DataType) => void
}

const AttendanceFormDrawer = function <DataType>(
  props: PropsWithChildren<Props<DataType>>
) {
  const { t } = useTranslation()

  return (
    <FormDrawer<DataType>
      defaultValues={props.defaultValues}
      title={props.details ? t('update_attendance') : t('create_attendance')}
      onSubmit={props.onSubmit}
      hideDrawer={props.hideDrawer}
      visible={props.visable}
      formId={props.formId}
      confirmLoading={props.loading}
      children={<AttendanceForm />}
    />
  )
}

export default AttendanceFormDrawer
