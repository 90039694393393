import { Descriptions as AntdDescriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { ITag } from '../../../models/tag/response'
import Descriptions from '../../general/antd/descriptions'

interface Props {
  tag?: ITag
}

const TagDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.tag?.id}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('name')}>
        {props.tag?.name}
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default TagDetails
