import { Image as AntdImage, ImageProps as AntdImageProps } from 'antd'
import './style.css'

export interface ImageProps extends AntdImageProps {
  preview?: boolean
}

const Image: React.FC<ImageProps> = (props) => {
  return (
    <AntdImage
      preview={props.preview ? true : false}
      style={{ width: '4rem' }}
      {...props}
    />
  )
}

export default Image
