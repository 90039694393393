import React, { useContext } from 'react'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import FieldBuilder from '../../form-components/field-builder'
import FilterCard from '../../general/filter-card'
import Form from '../../form-components/from'
import { INITIAL_PAGE } from '../../../utils/helpers/constants'

import styles from './style.module.scss'
import PlateCityContext from '../../../contexts/plate-cities/context'

interface IProps {}

const PlateCityFilters: React.FC<IProps> = () => {
  const { t } = useTranslation()
  const { actions, loading, query } = useContext(PlateCityContext)

  return (
    <div className={styles.formContainer}>
      <Form
        formId='plate-city-filter'
        onSubmit={(data) => {
          const filter: any = {
            page: INITIAL_PAGE,
            perPage: query?.perPage,
            keyword: data?.keyword,
          }
          actions.setQuery(filter)
        }}
      >
        <FilterCard
          formId='plate-city-filter'
          applyLoading={loading.includes('list')}
          resetLoading={loading.includes('list')}
          onReset={() => {
            actions.setQuery({
              page: query?.page,
              perPage: query?.perPage,
            })
          }}
        >
          <Row justify={'start'} gutter={[10, 0]} className={styles.filters}>
            <Col span={8}>
              <FieldBuilder
                name='keyword'
                label={t('search')}
                input={{ type: 'text' }}
              />
            </Col>
          </Row>
        </FilterCard>
      </Form>
    </div>
  )
}

export default PlateCityFilters
