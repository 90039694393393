import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import CarContextProvider from '../../contexts/car/provider'
import PageHeader from '../../components/general/antd/page-header'
import CarContext from '../../contexts/car/context'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import CarTable from '../../components/car/car-table'
import CarDetails from '../../components/car/car-details'
import InfoDrawer from '../../components/general/info-drawer'
import CarFilters from '../../components/car/car-filters'
import BrandSelectOptionsContextProvider from '../../contexts/select-options/brand-select-options/provider'
import CitySelectOptionsContextProvider from '../../contexts/select-options/city-select-options/provider'

interface Props {
  route: IRoute
}

const Cars: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(CarContext)

  // const onSubmit = async (data: any) => {
  //   details
  //     ? await actions.updateCar(details.id, data)
  //     : await actions.createCar(data)
  //   eventManager.emit(EVENT_SUCCESS)
  // }

  const [, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            {/* <CreateBtn
              onClick={() => {
                actions.setDetails(undefined)
                setFormDrawerVisable(true)
              }}
            /> */}
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />
      <BrandSelectOptionsContextProvider>
      <CitySelectOptionsContextProvider>
      <CarFilters />
      </CitySelectOptionsContextProvider>
      </BrandSelectOptionsContextProvider>


      <CarTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      {/* <CarFormDrawer
        formId={'car-form'}
        visable={formDrawerVisable}
        defaultValues={
          details && {
            id: details?.id,
            carImageId: details?.carImage?.id,
          }
        }
        details={details}
        loading={loading.includes('create') || loading.includes('update')}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      /> */}

      <InfoDrawer
        title={t('car_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <CarDetails car={details} />
      </InfoDrawer>
    </>
  )
}

const CarsPage: React.FC<Props> = (props) => {
  return (
    <CarContextProvider>
      <Cars {...props} />
    </CarContextProvider>
  )
}
export default CarsPage
