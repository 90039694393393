import React, { useEffect, useState } from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { languages } from '../../../utils/helpers/constants'
import { Tabs, Divider, Form } from 'antd'
import TranslationsCard from '../../form-components/translations-card'
import ImageUploader from '../../form-components/image-uploader'
import Controller from '../../form-components/controller'
import { MediumFor } from '../../../models/medium/enum'
import { IProduct } from '../../../models/product/response'
import OldImage from '../../form-components/old-image'

const { TabPane } = Tabs

interface IProps {
  product?: IProduct
}

const ProductForm: React.FC<IProps> = ({ product }) => {
  const { t } = useTranslation()

  // Old Image
  const [image, setImage] = useState(product?.image?.url)

  useEffect(() => {
    product?.image?.url && setImage(product?.image?.url)
  }, [product?.image?.url])

  return (
    <div>
      <TranslationsCard>
        <Tabs type='line'>
          {languages?.map((lang, index) => {
            return (
              <TabPane tab={lang.name} key={index}>
                <FieldBuilder
                  key={index}
                  name={`name[${lang?.code}]`}
                  label={`${t('name')} (${lang?.name})`}
                  input={{ type: 'text' }}
                  rules={{ required: true }}
                />

                <FieldBuilder
                  key={index}
                  name={`description[${lang?.code}]`}
                  label={`${t('description')} (${lang?.name})`}
                  input={{ type: 'text-area' }}
                  rules={{ required: true }}
                />
              </TabPane>
            )
          })}
        </Tabs>
      </TranslationsCard>

      <Divider />

      <FieldBuilder
        name={'price'}
        label={t('price')}
        input={{ type: 'number' }}
      />

      {/* Upload Image */}
      <div>
        <Form.Item label={t('image')} required>
          <Controller
            name='imageId'
            rules={{
              required: {
                value: true,
                message: `${t('field_is_required_message')}`,
              },
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <ImageUploader
                  {...field}
                  onChange={(response) => {
                    setImage(undefined)
                    field.onChange(response)
                  }}
                  imageFor={MediumFor.BANNER_IMAGE}
                />
              )
            }}
          />
        </Form.Item>
      </div>

      {/* Old  Image */}
      {image && <OldImage src={image} />}
    </div>
  )
}

export default ProductForm
