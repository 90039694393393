import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Button, { ButtonProps } from '../../../general/antd/button'

interface Props extends ButtonProps {
  label?: string
}

const CreateBtn: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Button
      icon={<PlusOutlined />}
      type='primary'
      title={t('create')}
      {...props}
    >
      {props.label ? t(props.label) : t('create')}
    </Button>
  )
}

export default CreateBtn
