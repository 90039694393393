import { Path } from './paths'

export type MenuItem =
  | { path: Path }
  | { labelKey: string; subMenus: MenuItem[]; icon?: JSX.Element }

export const menuItems: MenuItem[] = [
  { path: '/' },
  { path: '/banners' },

  { path: '/locations' },
  { path: '/cars' },
  { path: '/manage-staff' },
  { path: '/products-manage' },
  { path: '/general-info' },
  { path: '/features' },
  { path: '/clients_management' },
  { path: '/countries' },

  { path: '/cities' },
  { path: '/permanent-locations' },
  { path: '/addresses' },
  { path: '/attendances' },
  { path: '/categories' },

  { path: '/staff-types' },
  { path: '/products' },
  { path: '/promo-codes' },
  { path: '/tags' },
  { path: '/faqs' },
  { path: '/clients' },

  { path: '/staff' },

  { path: '/orders' },
  { path: '/requests' },

  { path: '/subscriptions' },
  { path: '/services' },
  { path: '/notifications' },
  { path: '/configs' },
  { path: '/client-subscriptions' },
  { path: '/plate-cities' },

  // { path: '/users' },
]
