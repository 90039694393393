import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import eventManager, { EVENT_ERORR, EVENT_SUCCESS } from '../../utils/events'
import PageHeader from '../../components/general/antd/page-header'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import InfoDrawer from '../../components/general/info-drawer'
import PlateCityContext from '../../contexts/plate-cities/context'
import PlateCityContextProvider from '../../contexts/plate-cities/provider'
import PlateCityFilters from '../../components/plate-cities/plate-city-filters'
import PlateCityTable from '../../components/plate-cities/plate-city-table'
import PlateCityFormDrawer from '../../components/plate-cities/plate-city-form-drawer'
import PlateCityDetails from '../../components/plate-cities/plate-city-details'
import CreateBtn from '../../components/table-components/actions/create-btn'
import { getTranslations } from '../../utils/helpers/translations'
import { checkTranslations } from '../../utils/helpers/constants'

interface Props {
  route: IRoute
}

const PlateCities: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(PlateCityContext)

  const onSubmit = async (data: any) => {
    if (checkTranslations(data.name) === true) {
      details
        ? await actions.updatePlateCity(details.id, data)
        : await actions.createPlateCity(data)
      eventManager.emit(EVENT_SUCCESS)
    } else {
      eventManager.emit(EVENT_ERORR, t('translations_required_message'))
      throw 'tranlations required'
    }
  }

  const [formDrawerVisable, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
            <CreateBtn
              onClick={() => {
                actions.setDetails(undefined)
                setFormDrawerVisable(true)
              }}
            />
          </Space>
        }
      />

      <PlateCityFilters />

      <PlateCityTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <PlateCityFormDrawer
        formId={'config-form'}
        visable={formDrawerVisable}
        defaultValues={
          details && {
            id: details?.id,
            name: getTranslations(details?.translations?.name),
            imageId: details?.image?.id,
          }
        }
        details={details}
        loading={loading.includes('create') || loading.includes('update')}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      />

      <InfoDrawer
        title={t('plate_city_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <PlateCityDetails plateCity={details} />
      </InfoDrawer>
    </>
  )
}

const PlateCityPage: React.FC<Props> = (props) => {
  return (
    <PlateCityContextProvider>
      <PlateCities {...props} />
    </PlateCityContextProvider>
  )
}
export default PlateCityPage
