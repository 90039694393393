import { ReactNode, useContext } from 'react'
import { Menu as AntdMenu, Tooltip } from 'antd'
import { dashboardRoutes } from '../../../routes/routes'
import AuthContext from '../../../contexts/auth/context'
import { useTranslation } from 'react-i18next'
import { check } from '../../../utils/rbac/can'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import AppContext from '../../../contexts/app/context'
import { MenuItem, menuItems } from '../../../pages/menus'

const { SubMenu } = AntdMenu

interface Props {}

const Menu: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { direction } = useContext(AppContext)
  const { userPermissions } = useContext(AuthContext)

  const location = useLocation()

  const getOpenKeys = (menuItems: MenuItem[], path: string) => {
    return menuItems.reduce((keys, m) => {
      if ('labelKey' in m) {
        const openKeys = getOpenKeys(m.subMenus, path)
        if (
          openKeys.length > 0 ||
          m.subMenus.find((sm) => 'path' in sm && sm.path === path)
        ) {
          keys.push(m.labelKey)
        }
        keys.push(...openKeys)
      }
      return keys
    }, [] as string[])
  }

  const renderMenu = (menuItems: MenuItem[]): ReactNode[] => {
    return menuItems.map((m) => {
      if ('subMenus' in m) {
        return (
          <SubMenu key={m.labelKey} icon={m.icon} title={t(m.labelKey)}>
            {renderMenu(m.subMenus)}
          </SubMenu>
        )
      }

      const r = dashboardRoutes.find((r) => r.path === m.path)
      if (r?.hasSubMenus) {
        return (
          <SubMenu
            title={
              <Tooltip
                title={t(r.labelKey)}
                placement={direction === 'rtl' ? 'right' : 'left'}
              >
                {t(r.labelKey)}
              </Tooltip>
            }
            icon={r.icon}
          >
            {r.subMenus
              ?.filter(
                (route) =>
                  route && check(userPermissions, route.permissions ?? [])
              )
              ?.map((route) => (
                <AntdMenu.Item key={route.path} icon={route.icon}>
                  <Link to={{ pathname: route.path }}>
                    <Tooltip
                      title={t(route.labelKey)}
                      placement={direction === 'rtl' ? 'right' : 'left'}
                    >
                      <Link to={{ pathname: route.path }}>
                        {t(route.labelKey)}
                      </Link>
                    </Tooltip>
                  </Link>
                </AntdMenu.Item>
              ))}
          </SubMenu>
        )
      } else {
        return r && check(userPermissions, r.permissions ?? []) ? (
          <AntdMenu.Item key={r.path} icon={r.icon}>
            <Link to={{ pathname: r.path }}>{t(r.labelKey)}</Link>
          </AntdMenu.Item>
        ) : null
      }
    })
  }

  return (
    <AntdMenu
      mode='inline'
      style={{ paddingBottom: ' 8rem' }}
      direction={direction}
      defaultSelectedKeys={[location.pathname]}
      defaultOpenKeys={getOpenKeys(menuItems, location.pathname)}
    >
      {renderMenu(menuItems)}
    </AntdMenu>
  )
}

export default Menu
