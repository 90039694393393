import { ICategoryQuery } from '../../models/category/query'
import { ICreateCategory, IUpdateCategory } from '../../models/category/request'
import { ICategory } from '../../models/category/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class CategoryService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllCategories = (query: ICategoryQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICategory>>({
      path: `/dashboard/categories/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createCategory = (data: ICreateCategory, params?: RequestParams) =>
    this.http.request<ICategory>({
      path: '/dashboard/categories',
      method: 'POST',
      body: data,
      ...params,
    })

  updateCategory = (
    id: number,
    data: IUpdateCategory,
    params?: RequestParams
  ) =>
    this.http.request<ICategory>({
      path: `/dashboard/categories/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteCategory = (id: number, params?: RequestParams) =>
    this.http.request<ICategory>({
      path: `/dashboard/categories/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default CategoryService
