import React from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'
import { ClientStatus } from '../../../models/client/enum'

interface IProps {}

const ClientForm: React.FC<IProps> = () => {
  const { t } = useTranslation()

  return (
    <div>
      {/* Status */}
      <FieldBuilder
        name='status'
        label={t('status')}
        rules={{ required: true }}
        input={{
          type: 'select',
          options: [
            {
              value: ClientStatus.ACTIVE,
              label: t(`${ClientStatus[ClientStatus.ACTIVE]}`),
            },
            {
              value: ClientStatus.INACTIVE,
              label: t(`${ClientStatus[ClientStatus.INACTIVE]}`),
            },
          ],
        }}
      />
      <FieldBuilder
        name='balance'
        label={t('balance')}
        input={{ type: 'number' }}
        rules={{ required: false }}
      />
    </div>
  )
}

export default ClientForm
