import { ICountryQuery } from '../../models/country/query'
import { ICreateCountry, IUpdateCountry } from '../../models/country/request'
import { ICountry } from '../../models/country/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class CountryService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllCountries = (query: ICountryQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICountry>>({
      path: `/dashboard/countries/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createCountry = (data: ICreateCountry, params?: RequestParams) =>
    this.http.request<ICountry>({
      path: '/dashboard/countries',
      method: 'POST',
      body: data,
      ...params,
    })

  updateCountry = (id: number, data: IUpdateCountry, params?: RequestParams) =>
    this.http.request<ICountry>({
      path: `/dashboard/countries/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteCountry = (id: number, params?: RequestParams) =>
    this.http.request<ICountry>({
      path: `/dashboard/countries/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default CountryService
