import { IBannerQuery } from '../../models/banner/query'
import { ICreateBanner, IUpdateBanner } from '../../models/banner/request'
import { IBanner } from '../../models/banner/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { HttpClient, RequestParams } from '../../utils/api/http-client'

class BannerService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  getAllBanners = (query: IBannerQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IBanner>>({
      path: `/dashboard/banners/index`,
      method: 'POST',
      body: query,
      ...params,
    })

  createBanner = (data: ICreateBanner, params?: RequestParams) =>
    this.http.request<IBanner>({
      path: '/dashboard/banners',
      method: 'POST',
      body: data,
      ...params,
    })

  updateBanner = (id: number, data: IUpdateBanner, params?: RequestParams) =>
    this.http.request<IBanner>({
      path: `/dashboard/banners/${id}`,
      method: 'PUT',
      body: data,
      ...params,
    })

  deleteBanner = (id: number, params?: RequestParams) =>
    this.http.request<IBanner>({
      path: `/dashboard/banners/${id}`,
      method: 'DELETE',
      ...params,
    })
}

export default BannerService
