import { createContext } from 'react'
import { IClientQuery } from '../../models/client/query'
import { IUpdateClient } from '../../models/client/request'
import { IClient } from '../../models/client/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type ClientLoading = 'list' | 'details' | 'create' | 'update' | 'delete'

export interface IInternalState {
  loading: ClientLoading[]

  list?: IBaseListingResponse<IClient>
  query: IClientQuery

  details?: IClient
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IClient) => void

    updateClient: (id: number, request: IUpdateClient) => void
    deleteClient: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IClientQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    updateClient: DEFAULT_FUNCTION,
    deleteClient: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const ClientContext = createContext(externalState)

export default ClientContext
