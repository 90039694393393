import React from 'react'
import FieldBuilder from '../../form-components/field-builder'
import { useTranslation } from 'react-i18next'

interface IProps {}

const RequestApproveForm: React.FC<IProps> = () => {
  const { t } = useTranslation()
  return (
    <div>
      <FieldBuilder
        name='note'
        label={t('note')}
        input={{ type: 'text-area' }}
        rules={{ required: true }}
      />
    </div>
  )
}

export default RequestApproveForm
