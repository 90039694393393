import { createContext } from 'react'
import { IPlateCityQuery } from '../../models/plate-cities/query'
import {
  ICreatePlateCity,
  IUpdatePlateCity,
} from '../../models/plate-cities/request'
import {
  IPlateCity,
  IPlateCityDetails,
} from '../../models/plate-cities/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type PlateCityLoading =
  | 'list'
  | 'details'
  | 'create'
  | 'update'
  | 'delete'

export interface IInternalState {
  loading: PlateCityLoading[]

  list?: IBaseListingResponse<IPlateCity>
  query: IPlateCityQuery

  details?: IPlateCityDetails
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IPlateCity) => void

    createPlateCity: (request: ICreatePlateCity) => void
    updatePlateCity: (id: number, request: IUpdatePlateCity) => void
    deletePlateCity: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IPlateCityQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createPlateCity: DEFAULT_FUNCTION,
    updatePlateCity: DEFAULT_FUNCTION,
    deletePlateCity: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const PlateCityContext = createContext(externalState)

export default PlateCityContext
