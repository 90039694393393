import { createContext } from 'react'
import { IRequestQuery } from '../../models/request/query'
import { IApproveRequest, IRejectRequest } from '../../models/request/request'
import { IRequest } from '../../models/request/response'
import IBaseListingResponse from '../../utils/api/base-listing-response'
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from '../../utils/helpers/constants'

export type RequestLoading = 'list' | 'details' | 'create' | 'update' | 'delete'

export interface IInternalState {
  loading: RequestLoading[]

  list?: IBaseListingResponse<IRequest>
  query: IRequestQuery

  details?: IRequest
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
}

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void
    getDetails: (id: number) => void
    setDetails: (data?: IRequest) => void

    approveRequest: (id: number, request: IApproveRequest) => void
    rejectRequest: (id: number, request: IRejectRequest) => void
    deleteRequest: (id: number) => void

    setSearch: (search?: string) => void
    setQuery: (query: IRequestQuery) => void
  }
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    approveRequest: DEFAULT_FUNCTION,
    rejectRequest: DEFAULT_FUNCTION,
    deleteRequest: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
}

const RequestContext = createContext(externalState)

export default RequestContext
