import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import FormDrawer from '../../form-components/form-drawer/index'
import PermanentLocationForm from '../permanent-location-form'
import { IPermanentLocation } from '../../../models/permanent-location/response'
import CitySelectOptionsContextProvider from '../../../contexts/select-options/city-select-options/provider'
import ServiceSelectOptionsContextProvider from '../../../contexts/select-options/service-select-options/provider'

interface Props<DataType> {
  formId: string
  loading: boolean
  visable: boolean
  hideDrawer: () => void
  defaultValues?: DataType
  details?: IPermanentLocation
  onSubmit: (data: DataType) => void
}

const PermanentLocationFormDrawer = function <DataType>(
  props: PropsWithChildren<Props<DataType>>
) {
  const { t } = useTranslation()

  return (
    <CitySelectOptionsContextProvider>
      <ServiceSelectOptionsContextProvider>
        <FormDrawer<DataType>
          defaultValues={props.defaultValues}
          title={
            props.details
              ? t('update_permanent_location')
              : t('create_permanent_location')
          }
          onSubmit={props.onSubmit}
          hideDrawer={props.hideDrawer}
          visible={props.visable}
          formId={props.formId}
          confirmLoading={props.loading}
          children={
            <PermanentLocationForm permanentLocation={props?.details} />
          }
        />
      </ServiceSelectOptionsContextProvider>
    </CitySelectOptionsContextProvider>
  )
}

export default PermanentLocationFormDrawer
