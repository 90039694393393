import { Descriptions as AntdDescriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { IFAQ } from '../../../models/faq/response'
import Descriptions from '../../general/antd/descriptions'

interface Props {
  faq?: IFAQ
}

const FAQDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Descriptions size='middle' column={1} bordered>
      <AntdDescriptions.Item label={t('id')}>
        {props.faq?.id}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('question')}>
        {props.faq?.question}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label={t('answer')}>
        {props.faq?.answer}
      </AntdDescriptions.Item>
    </Descriptions>
  )
}

export default FAQDetails
