import { useTranslation } from 'react-i18next'
import { IRoute } from '../../routes/routes'
import { useContext, useState } from 'react'
import eventManager, { EVENT_ERORR, EVENT_SUCCESS } from '../../utils/events'
import TagContextProvider from '../../contexts/tag/provider'
import PageHeader from '../../components/general/antd/page-header'
import TagContext from '../../contexts/tag/context'
import CreateBtn from '../../components/table-components/actions/create-btn'
import RefreshBtn from '../../components/table-components/actions/refresh-btn'
import Space from '../../components/general/antd/space'
import TagTable from '../../components/tag/tag-table'
import TagDetails from '../../components/tag/tag-details'
import TagFormDrawer from '../../components/tag/tag-form-drawer'
import InfoDrawer from '../../components/general/info-drawer'
import { getTranslations } from '../../utils/helpers/translations'
import TagFilters from '../../components/tag/tag-filters'
import { checkTranslations } from '../../utils/helpers/constants'

interface Props {
  route: IRoute
}

const Tags: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { details, actions, loading } = useContext(TagContext)

  const onSubmit = async (data: any) => {
    if (checkTranslations(data.name) === true) {
      details
        ? await actions.updateTag(details.id, data)
        : await actions.createTag(data)
      eventManager.emit(EVENT_SUCCESS)
    } else {
      eventManager.emit(EVENT_ERORR, t('translations_required_message'))
      throw 'tranlations required'
    }
  }

  const [formDrawerVisable, setFormDrawerVisable] = useState(false)

  const [detailsDrawerVisable, setDetailsDrawerVisable] = useState(false)

  return (
    <>
      <PageHeader
        title={t(props.route.labelKey)}
        subTitle={t('manageRecource', { resource: t(props.route.labelKey) })}
        avatar={{
          icon: props.route.icon,
        }}
        extra={
          <Space size={0}>
            <CreateBtn
              onClick={() => {
                actions.setDetails(undefined)
                setFormDrawerVisable(true)
              }}
            />
            <RefreshBtn
              loading={loading.includes('list')}
              onClick={() => actions.getData()}
            >
              {t('refresh')}
            </RefreshBtn>
          </Space>
        }
      />

      <TagFilters />

      <TagTable
        showFormDrawer={() => setFormDrawerVisable(true)}
        showDetailsDrawer={() => setDetailsDrawerVisable(true)}
      />

      <TagFormDrawer
        formId={'tag-form'}
        visable={formDrawerVisable}
        defaultValues={
          details && {
            id: details?.id,
            name: getTranslations(details?.translations?.name),
          }
        }
        details={details}
        loading={loading.includes('create') || loading.includes('update')}
        hideDrawer={() => setFormDrawerVisable(false)}
        onSubmit={onSubmit}
      />

      <InfoDrawer
        title={t('tag_details')}
        visible={detailsDrawerVisable}
        toggleVisible={setDetailsDrawerVisable}
        loading={loading.includes('details')}
      >
        <TagDetails tag={details} />
      </InfoDrawer>
    </>
  )
}

const TagsPage: React.FC<Props> = (props) => {
  return (
    <TagContextProvider>
      <Tags {...props} />
    </TagContextProvider>
  )
}
export default TagsPage
