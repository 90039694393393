/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useReducer } from 'react'
import reducer from './reducer'
import { execute } from '../../utils/api/api-execute'
import EndPoints from '../../services/end-points'
import AuthContext from '../auth/context'
import PlateCityContext, { internalState } from './context'
import { IPlateCityDetails } from '../../models/plate-cities/response'
import {
  ICreatePlateCity,
  IUpdatePlateCity,
} from '../../models/plate-cities/request'
import { IPlateCityQuery } from '../../models/plate-cities/query'

const PlateCityContextProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState)

  const { isAuthenticated } = useContext(AuthContext)
  /**
   * Get data when query changed
   */
  useEffect(() => {
    isAuthenticated && getData()
  }, [state.query, isAuthenticated])

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'list' } })

        const { data } = await EndPoints.plateCity.getAllPlateCities(
          state.query
        )

        dispatch({ type: 'SET_LIST', payload: { list: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'list' } })
      },
      throwException: false,
    })
  }

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })

        const data = state.list?.data?.find((u) => u?.id === id)

        dispatch({ type: 'SET_DETAILS', payload: { details: data } })
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: 'LOADING', payload: { loading: 'details' } })
      },
      throwException: false,
    })
  }

  const setDetails = async (data?: IPlateCityDetails) => {
    dispatch({ type: 'SET_DETAILS', payload: { details: data } })
  }

  const createPlateCity = async (request: ICreatePlateCity) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'create' },
        })

        await EndPoints.plateCity.createPlateCity(request)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'create' },
        })
      },
      throwException: true,
    })
  }

  const updatePlateCity = async (id: number, request: IUpdatePlateCity) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'update' },
        })
        await EndPoints.plateCity.updatePlateCity(id, request)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'update' },
        })
      },
      throwException: true,
    })
  }

  const deletePlateCity = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete' },
        })

        await EndPoints.plateCity.deletePlateCity(id)

        getData()
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: 'LOADING',
          payload: { loading: 'delete' },
        })
      },
      throwException: true,
    })
  }

  const setSearch = (search?: string) => {
    dispatch({ type: 'SET_SEARCH', payload: { search } })
  }

  const setQuery = (query: IPlateCityQuery) => {
    dispatch({ type: 'SET_QUERY', payload: { query } })
  }
  return (
    <PlateCityContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createPlateCity,
          updatePlateCity,
          deletePlateCity,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </PlateCityContext.Provider>
  )
}

export default PlateCityContextProvider
