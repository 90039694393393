import { Result } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Button from '../../components/general/antd/button'
import ErrorLayout from '../../components/layouts/error-layout'

interface Props {}

const Error403: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const history = useHistory()

  return (
    <ErrorLayout>
      <Result
        status='403'
        title='403'
        subTitle={t('403')}
        extra={
          <Button
            type='primary'
            onClick={() => {
              history.goBack()
            }}
          >
            {t('back')}
          </Button>
        }
      />
    </ErrorLayout>
  )
}

export default Error403
